<template>
  <div ref="container" style="height:100%;position: relative;overflow: hidden">
    <div style="background-color: #fff;">
      <div class="tableHeaderContainer" >
        <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
          功能操作
          <!-- 搜索抽屉框(局部弹窗需要外层div Css position: relative;overflow: hidden ) start-->
          <a-drawer
              title="条件筛选"
              :mask="true"
              placement="top"
              v-model:visible="searchDrawerVisible"
              :get-container="()=>{return $refs.container}"
              :style="{ position: 'absolute' }"
              :height="200"
          >
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item label="组织名称" name="orgName">
                <a-input v-model:value="formSearchState.orgName" size="default" />
              </a-form-item>
              <a-form-item label="租户名" name="loginName">
                <a-input v-model:value="formSearchState.loginName" size="default" />
              </a-form-item>
              <a-form-item label="租户状态" name="status">
                <a-select ref="select" v-model:value="formSearchState.status" style="width: 144px" size="default">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="1">同意</a-select-option>
                  <a-select-option value="0">拒绝</a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
            <template #footer>
              <div style="text-align: right">
                <a-button style="margin-right: 8px" @click="()=>{searchDrawerVisible = false;}"><template #icon><CloseOutlined /></template>关闭</a-button>
                <a-button type="primary" @click="onSearch()"><template #icon><SearchOutlined /></template>搜索</a-button>
              </div>
            </template>
          </a-drawer>
          <!-- 搜索抽屉框 end -->
        </div>
        <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
          <a-button style="margin-left: 5px;" type="primary" size="small" @click="()=>{searchDrawerVisible = true;}"><template #icon><SearchOutlined /></template>条件筛选</a-button>
        </div>
      </div>
      <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - tableHeaderContainer 40 - 表头高度40 - 分页高度52 -->
      <a-table
          class="ant-table-striped"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :bordered="false"
          size="small"
          :loading="loading"
          :scroll="{ y: 'calc(100vh - 227px)' }"
      >
        <template #bodyCell="{ column,record }">
          <template v-if="column.dataIndex === 'operation'">
            <div style="display: flex;justify-content: left;">
              <a-button :disabled="record.status==1 ? true : false" style="margin: 0px 4px;" type="primary" size="small" ghost @click="agreeRequest(record)"><template #icon><check-outlined /></template>同意</a-button>
              <a-button :disabled="record.status==0 ? true : false" style="margin: 0px 4px;" type="primary" size="small" ghost @click="refuseRequest(record)"><template #icon><stop-outlined /></template>拒绝</a-button>
              <a-button style="margin: 0px 4px;" type="primary" size="small" danger ghost @click="deleteItem(record)"><template #icon><DeleteOutlined /></template>删除</a-button>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'status'">
            <a-tag v-if="record.status === 0" color="error">已拒绝</a-tag>
            <a-tag v-else-if="record.status === 1" color="success">已同意</a-tag>
            <a-tag v-else-if="record.status === -1" color="default">无状态</a-tag>
            <a-tag v-else color="default">未知</a-tag>
          </template>
        </template>
      </a-table>

      <div style="padding:10px;text-align: center">
        <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                      @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                      :page-size-options="['10','20','30','40','50']" show-size-changer>
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import {onMounted, ref, createVNode, reactive} from "vue";
import api_tenant from "@/api/tenant";
import {message,Modal} from "ant-design-vue";
import clone from "clone";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {
  SearchOutlined,
  DeleteOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
    CheckOutlined,
    StopOutlined
} from "@ant-design/icons-vue";

dayjs.locale('zh-cn');
export default {
  components: {
    SearchOutlined,
    DeleteOutlined,
    CloseOutlined,
    // eslint-disable-next-line vue/no-unused-components
    ExclamationCircleOutlined,
    CheckOutlined,
    StopOutlined
  },
  setup(){
    const loading = ref(false);
    const formSearchRef = ref();
    const searchDrawerVisible = ref(false);
    const formSearchState = reactive({
      orgName: "",
      loginName: "",
      status: ""
    });
    let dataSource = ref([]);
    let columns= [
      {
        title: '序号',
        dataIndex: 'index',
        width:100,
        customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
      },{
        title: '组织名称',
        dataIndex: 'orgName',
      },{
        title: '租户名',
        dataIndex: 'loginName',
      },{
        title: '租户状态',
        dataIndex: 'status',
      },{
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width:300
      }
    ];

    let pagination = ref({
      current: 1,     //当前页
      pageSize: 10,  //每页条数
      total: 1,     //数据总数
      pageSizeOptions:['2','4','6'],
      showSizeChanger:true
    });

    /**
     * 查询所有用户
     * @param page 页数
     */
    const findAllItem = function (page){
      loading.value = true;
      let params = clone(formSearchState);  //克隆新的数据以免干扰formSearchState
      params.page = page;
      params.rows = pagination.value.pageSize;
      //console.log("findAllItem searchData----",formSearchState);
      api_tenant.showAllTenantRequest(params).then(res=>{
        //console.log("res----",res);
        if(res?.data?.code === 0){
          dataSource.value = [];
          const dataList = res.data.data.list;
          const _total = res.data.data.total;
          pagination.value.total = _total;

          dataList.forEach((tenant)=>{
            //console.log("user-->",user);
            dataSource.value.push({id:tenant.id,orgName:tenant.orgName,loginName:tenant.loginName,status:tenant.status})
          })
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }
    /**
     * 分页响应事件
     * @param page {@link pagination}
     */
    let onPageChange = function (page){
      findAllItem(page);
    }
    /**
     * 改变pageSize分页条数
     * @param current 当前页
     * @param size  分页条数
     */
    const onShowSizeChanger = function (current, size){
      pagination.value.current = current;
      pagination.value.pageSize = size;
    }
    /**
     * 跳到第一页
     */
    const toHomePage = ()=>{
      findAllItem(1);
      pagination.value.current = 1;
    }
    /**
     * 跳到最后页
     */
    const toEndPage = ()=>{
      findAllItem(getTotalPage());
      pagination.value.current = getTotalPage();
    }
    /**
     * 刷新当前页
     */
    const toCurrentPage = ()=>{
      findAllItem(pagination.value.current);
    }

    /**
     * 获取当前总页数
     */
    const getTotalPage =()=>{
      if (pagination.value.total === 0){
        return 1;
      }
      return pagination.value.total%pagination.value.pageSize === 0
          ? pagination.value.total/pagination.value.pageSize
          : parseInt(pagination.value.total/pagination.value.pageSize)+1;
    }

    onMounted(()=>{
      findAllItem(1);

    })

    /**
     * 同意
     * @param record 实体数据
     */
    const agreeRequest = function (record){
      //console.log(record);
      Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定要同意此租户的申请?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
            api_tenant.agreeRequest(record.id).then(res=>{
              if(res?.data?.code === 0){
                message.success("操作成功",3);
                resolve();
                toCurrentPage();
              }else{
                if (res?.data?.msg){
                  message.error(res?.data?.msg+" "+res?.data?.data,5);
                }
                reject();
              }
            });
          });
        },
        onCancel() {
        }
      });
    }
    /**
     * 拒绝
     * @param record 实体数据
     */
    const refuseRequest = function (record){
      //console.log(record);
      Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定要拒绝此租户的申请?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
            api_tenant.refuseRequest(record.id).then(res=>{
              if(res?.data?.code === 0){
                message.success("操作成功",3);
                resolve();
                toCurrentPage();
              }else{
                if (res?.data?.msg){
                  message.error(res?.data?.msg+" "+res?.data?.data,5);
                }
                reject();
              }
            });
          });
        },
        onCancel() {
        }
      });
    }
    /**
     * 删除一条记录
     * @param record 实体数据
     */
    const deleteItem = function (record){
      //console.log(record);
      Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '是否要删除此租户的申请?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
            api_tenant.deleteTenantRequest(record.id).then(res=>{
              if(res?.data?.code === 0){
                message.success("操作成功",3);
                resolve();
                toCurrentPage();
              }else{
                if (res?.data?.msg){
                  message.error(res?.data?.msg+" "+res?.data?.data,5);
                }
                reject();
              }
            });
          });
        },
        onCancel() {
        }
      });
    }
    /**
     * 搜索
     */
    const onSearch = ()=>{
      formSearchRef.value.validateFields().then((values)=>{
        searchDrawerVisible.value = false;
        console.log("onSearch  values ---",values);

        toHomePage();
      }).catch(()=>{
        //console.log("onSearch  info ---",info);
      });
    }



    return{
      columns,
      dataSource,
      pagination,
      onPageChange,
      onShowSizeChanger,
      formSearchRef,
      formSearchState,
      searchDrawerVisible,
      agreeRequest,
      refuseRequest,
      deleteItem,
      onSearch,
      toEndPage,
      locale,
      loading
    }
  }

}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.tableHeaderContainer{
  display: flex;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #fafafa;
  background-color: #ffffff;
}


</style>