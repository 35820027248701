import {useUserStore} from "@/framework/store/UserStore";

/**
 * 自定义权限指令
 * <br/>用法: <input type="button" v-permission="{code:'user_addUser',mode:'remove'}"  />
 * <br/>     <input type="button" v-permission="'user_addUser'"  />
 * <br/>参数:code 权限编码，mode:权限不足时此标签显示方式，可选 disabled(灰色显示不使能) | hide(display:none) | remove(移除标签),默认remove
 */
const permission = {
    install(Vue){
        Vue.directive('permission', {
            mounted(el,binding) {
                try {
                    let isHave = false;
                    let code = "";
                    let mode = "";
                    if (typeof binding.value == 'string'){  //typeof返回的类型有六种: ‘number’ , ‘string’ , ‘boolean’ , ‘object’ 以及 ‘function’ 和 ‘undefined’.
                        code = binding.value;
                        if (!code){throw new Error("[permission] v-permission - code属性不能为空");}
                    }else if(binding.value instanceof Object){
                        code = binding.value.code;
                        mode = binding.value.mode;
                        if (!code){throw new Error("[permission] v-permission - code属性不能为空");}
                        if (!mode){throw new Error("[permission] v-permission - mode属性不能为空");}
                    }

                    const userStore = useUserStore();
                    userStore.roleList.forEach((role)=>{
                        //console.log("userStore.role",role.name);
                        role.operationList.forEach((operation)=>{
                            //console.log("userStore.resource.code",operation.code);
                            if (code === operation.code){
                                isHave = true;
                                return false;   //return false -> break；return true -> continue
                            }
                        });
                    })

                    if (!isHave){
                        if (mode === "disabled"){console.log(el)
                            el.disabled = true;
                            el.title = "权限不足";
                        }else if(mode === "hide"){
                            el.style.display = "none";
                        }else if(mode === "remove"){
                            el.parentElement && el.parentElement.removeChild(el);
                        }else{
                            el.parentElement && el.parentElement.removeChild(el);
                        }
                    }
                }catch (err){
                    console.log("[permission] install - error",err);
                    throw new Error("[permission] install - error");
                }

            }
        })
    }
}

export default permission;