import {axios} from "@/framework/utils/request";
import qs from "qs";    //npm install qs

const api_workOrder = {
    findAllMyWorkOrder:function (data){
        return axios({
            url:"/order/showMyWorkOrder",
            method:"post",
            data:qs.stringify(data)
        })
    },
    findAllWorkOrderOfMyAll:function (data){
        return axios({
            url:"/order/findAllWorkOrderOfMyAll",
            method:"post",
            data:qs.stringify(data)
        })
    },
    create:function (data){
        return axios({
            url:"/order/create",
            method:"post",
            /*headers: { 'content-type': 'application/x-www-form-urlencoded' },*/
            data:data
        })
    },
    getWorkOrder:(workOrderId)=>{
        return axios({
            url:"/order/getWorkOrder",
            method:"get",
            params:{
                workOrderId:workOrderId
            }
        })
    },
    getMyTopWorkOrder:()=>{
        return axios({
            url:"/order/getMyTopWorkOrder",
            method:"get",
            params:{

            }
        })
    },
    assign:(data)=>{
        return axios({
            url:"/order/assign",
            method:"post",
            data:qs.stringify(data)
        })
    },
    reject:(data)=>{
        return axios({
            url:"/order/reject",
            method:"post",
            data:qs.stringify(data)
        })
    },
    receive:(data)=>{
        return axios({
            url:"/order/receive",
            method:"post",
            data:qs.stringify(data)
        })
    },
    delay:(data)=>{
        return axios({
            url:"/order/delay",
            method:"post",
            data:qs.stringify(data)
        })
    },
    transfer:(data)=>{
        return axios({
            url:"/order/transfer",
            method:"post",
            data:qs.stringify(data)
        })
    },
    arrived:(data)=>{
        return axios({
            url:"/order/arrived",
            method:"post",
            data:qs.stringify(data)
        })
    },
    finish:(data)=>{
        return axios({
            url:"/order/finish",
            method:"post",
            data:data
        })
    },
    comment:(data)=>{
        return axios({
            url:"/order/comment",
            method:"post",
            data:qs.stringify(data)
        })
    },
    cancel:(workOrderId)=>{
        return axios({
            url:"/order/cancel",
            method:"get",
            params:{
                workOrderId:workOrderId
            }
        })
    },
    delete:(workOrderId)=>{
        return axios({
            url:"/order/delete",
            method:"get",
            params:{
                workOrderId:workOrderId
            }
        })
    },
    exportToExcelOfWorkOrderList:function (data){
        return axios({
            url:"/order/exportToExcelOfWorkOrderList",
            method:"post",
            responseType:'blob',
            data:qs.stringify(data)
        })
    },
    getWorkOrderStateSum:(type)=>{
        return axios({
            url:"/order/getWorkOrderStateSum",
            method:"get",
            params:{
                type:type
            }
        })
    },

}

export default api_workOrder;
