import {axios} from "@/framework/utils/request";
import qs from "qs";    //npm install qs

const api_auth = {
    findAllRole:function (data){
        return axios({
            url:"/auth/findAllRole",
            method:"post",
            data:qs.stringify(data)
        })
    },
    saveRole:function (data){
        return axios({
            url:"/auth/saveRole",
            method:"post",
            data:qs.stringify(data)
        })
    },
    getRole:function (id){
        return axios({
            url:"/auth/getRole",
            method:"get",
            params:{
                id:id
            }
        });
    },
    updateRole:function (data){
        return axios({
            url:"/auth/updateRole",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteRole:function (id){
        return axios({
            url:"/auth/deleteRole",
            method:"get",
            params:{
                id:id
            }
        });
    },
    showAllotOperation:function (roleId){
        return axios({
            url:"/auth/showAllotOperation",
            method:"get",
            params:{
                roleId:roleId
            }
        });
    },
    doAllotRoleOperation:function (data){
        return axios({
            url:"/auth/doAllotRoleOperation",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
    showAllotRole:function (userId){
        return axios({
            url:"/auth/showAllotRole",
            method:"get",
            params:{
                userId:userId
            }
        });
    },
    doAllotUserRole:function (data){
        return axios({
            url:"/auth/doAllotUserRole",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
    findAllOperation:function (data){
        return axios({
            url:"/auth/findAllOperation",
            method:"post",
            data:qs.stringify(data)
        })
    },
    saveOperation:function (data){
        return axios({
            url:"/auth/saveOperation",
            method:"post",
            data:qs.stringify(data)
        })
    },
    getOperation:function (id){
        return axios({
            url:"/auth/getOperation",
            method:"get",
            params:{
                id:id
            }
        });
    },
    updateOperation:function (data){
        return axios({
            url:"/auth/updateOperation",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteOperation:function (id){
        return axios({
            url:"/auth/deleteOperation",
            method:"get",
            params:{
                id:id
            }
        });
    },
    showAllotVisitorRole:function (){
        return axios({
            url:"/auth/showAllotVisitorRole",
            method:"get",
            params:{
            }
        });
    },
    doAllotVisitorRole:function (data){
        return axios({
            url:"/auth/doAllotVisitorRole",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
}

export default api_auth;