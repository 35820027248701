<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">

      <div class="block" style="" >
        <div style="display: flex;box-sizing:border-box;height: 60px;" >
          <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item name="assetName">
                <a-input v-model:value="formSearchState.assetName" size="default" allowClear placeholder="资产名称" style="width: 150px;"/>
              </a-form-item>
              <a-form-item name="operateUserId">
                <CustomInputDeptUser size="default" placeholder="操作人" @callbackSelVal="callback_selOperateUserDeptUser" style="width: 150px;"/>
              </a-form-item>
              <a-form-item name="preUserId">
                <CustomInputDeptUser size="default" placeholder="变更前使用人" @callbackSelVal="callback_selPreUserDeptUser" style="width: 150px;"/>
              </a-form-item>
              <a-form-item name="nowUserId">
                <CustomInputDeptUser size="default" placeholder="变更后使用人" @callbackSelVal="callback_selNowUserDeptUser" style="width: 150px;"/>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 0px;">
            <a-button v-permission="'assetChangeLog_delete'" :disabled="selectedRowKeys.length <= 0" style="margin-left: 5px;" type="primary" danger size="default" @click="deleteAssetChange(null)"><template #icon><DeleteOutlined /></template>删除</a-button>
          </div>
        </div>
      </div>

      <div class="block" style="" >
        <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - search 60 - 表头高度40 - 分页高度52 -->
        <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :bordered="true"
            size="small"
            :loading="loading_list"
            :row-selection="rowSelection"
            :scroll="{ x: 1200, y: 'calc(100vh - 247px)' }"
        >
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'operateUser'">
              <span>{{record?.operateUser?.name}}</span>
            </template>
            <template v-if="column.dataIndex === 'changeInfo'">
              <template v-if="record?.preUser?.name">
                <span>{{record?.preUser.name}} -> {{record?.nowUser?.name}}</span>
              </template>
              <template v-else>{{record?.nowUser?.name}} 领取了资产</template>
            </template>
          </template>
        </a-table>
        <div style="padding:10px;text-align: center">
          <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                        @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                        :page-size-options="['10','20','30','40','50']" show-size-changer>
            <template #buildOptionText="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import {computed, createVNode, defineAsyncComponent, onMounted, ref, unref} from "vue";
import clone from "clone";
import {message, Modal} from "ant-design-vue";
import {SearchOutlined, DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons-vue";
import api_asset from "@/api/asset";

const CustomInputDeptUser = defineAsyncComponent(() =>{
  return import('@/components/_customInputDeptUser.vue')
})

let selectedRowKeys = ref([]);
const formSearchRef = ref();
const formSearchState = ref({
  assetId: '',
  assetName: '',
  operateUserId: null,
  preUserId: null,
  nowUserId: null
})
const loading_list = ref(false);
let dataSource = ref([]);

let columns= [{
    title: '资产名称',
    dataIndex: 'assetName',
  },{
    title: '变更时间',
    dataIndex: 'operateDate',
  },{
    title: '操作人',
    dataIndex: 'operateUser',
  },{
    title: '变更信息',
    dataIndex: 'changeInfo',
  }
];

let pagination = ref({
  current: 1,     //当前页
  pageSize: 15,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});
/**
 * 分页响应事件
 * @param page {@link pagination}
 */
let onPageChange = function (page){
  findAllItem(page);
}
/**
 * 改变pageSize分页条数
 * @param current 当前页
 * @param size  分页条数
 */
const onShowSizeChanger = function (current, size){
  pagination.value.current = current;
  pagination.value.pageSize = size;
}
/**
 * 跳到第一页
 */
const toHomePage = ()=>{
  findAllItem(1);
  pagination.value.current = 1;
}
/**
 * 刷新当前页
 */
const toCurrentPage = ()=>{
  findAllItem(pagination.value.current);
}

onMounted(()=>{
  findAllItem(1);


})

const findAllItem = (page)=>{
  loading_list.value = true;
  let params = clone(formSearchState.value);  //克隆新的数据以免干扰formSearchState
  params.page = page;
  params.rows = pagination.value.pageSize;

  api_asset.showAllAssetChange(params).then(res=>{
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const list = res.data.data.list;
      if (list){
        const _total = res.data.data.total;
        pagination.value.total = _total;

        list.forEach((item)=>{
          item['key'] = item.id;  //加key用于列表前的复选框可以多选
          //console.log(item)
          const ii = {key:item.id,id:item.id,operateDate:item.operateDate,operateUser:item.operateUser,preUser:item.preUser,nowUser:item.nowUser,
            assetId:item.assetId,assetName:item.assetName}
          dataSource.value.push(ii);
        });
      }
    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading_list.value = false;
  });
}
/**
 * 批量删除message
 */
const deleteAssetChange = (id)=> {
  Modal.confirm({
    title: '系统提示',
    icon: createVNode(ExclamationCircleOutlined),
    content: '确定要删除吗?',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      return new Promise((resolve, reject) => {  //使用异步处理同时按钮显示加载动画
        const params = {ids: id ? id : selectedRowKeys.value};
        api_asset.deleteAssetChange(params).then(res => {
          if (res?.data?.code === 0) {
            message.success("操作成功", 3);
            resolve();
            selectedRowKeys.value = [];
            toCurrentPage();
          } else {
            if (res?.data?.msg) {
              message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
            reject();
          }
        });
      });
    },
    onCancel() {
    }
  });
}
/**
 * 操作人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selOperateUserDeptUser=(val)=>{
  formSearchState.value.operateUserId = val;
}
/**
 * 变更前使用人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selPreUserDeptUser=(val)=>{
  formSearchState.value.preUserId = val;
}
/**
 * 变更后使用人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selNowUserDeptUser=(val)=>{
  formSearchState.value.nowUserId = val;
}

const onSearch = ()=>{
  formSearchRef.value.validateFields().then(()=>{
    toHomePage();
  }).catch(()=>{
    //console.log("onSearch  info ---",info);
  });
}

/**
 * table 复选框操作
 * @type {ComputedRef<{onChange: function(*): void, selectedRowKeys: Array<UnwrapRefSimple<*>>}>}
 */
const rowSelection = computed(() => {
  return {
    selectedRowKeys: unref(selectedRowKeys),
    onChange: changableRowKeys => {
      selectedRowKeys.value = changableRowKeys;
    }
  };
});

</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
