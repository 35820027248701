import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_visitor = {
    getVisitorSet:()=>{
        return axios({
            url:"/visitor/getVisitorSet",
            method:"get",
            params:{
            }
        })
    },
    editVisitorSet:function (data){
        return axios({
            url:"/visitor/editVisitorSet",
            method:"post",
            data:qs.stringify(data)
        })
    },
}

export default api_visitor;