<template>
  <div ref="container" style="height:100%;position: relative;overflow: hidden">
    <div style="padding: 10px;height: 100%">

      <a-row :gutter="20">
        <a-col :span="7">
          <div class="myInfo">
            <div class="item">
              <div style="text-align: center;padding: 10px 0px;">
                <div><a-avatar :size="100" style="background-color: #87d068">{{userStore.name}}</a-avatar></div>
                <div style="padding: 10px 0px;"><a-typography-title :level="5">{{userStore.name}}</a-typography-title></div>
              </div>
              <div class="item">部门：{{userStore?.dept?.name}}</div>
              <div class="item">手机：{{userStore.phone}}</div>
              <div class="item">角色：
                <template v-for="(item,index) in userStore.roleList" :key="index">
                  {{item.name}}<span style="padding-right: 5px;"></span>
                </template>
              </div>
              <div class="item" v-if="userStore.type !== Constant.USER_TYPE_VISITOR">工单接收人：{{ userStore.isReceiver === Constant.USER_PROPERTY_ISRECEIVER ? '是' : '否' }}</div>
              <div class="item" v-if="userStore.type !== Constant.USER_TYPE_VISITOR">工单指派人：{{ userStore.isAssign === Constant.USER_PROPERTY_ISASSIGN_YES ? '是' : '否' }}</div>
              <div class="item">上一次登录时间：{{userStore.lastLoginTime}}</div>
            </div>
          </div>
        </a-col>
        <a-col :span="17">
          <div style="background-color: #ffffff;padding: 0px;">

            <div style="padding-top: 20px;padding-left: 20px;padding-right: 20px;">
              <a-tabs v-model:activeKey="tab_activeKey" @change="changeTabPage" >
                <a-tab-pane key="baseInfo" tab="个人基本信息"></a-tab-pane>
                <a-tab-pane key="myCalendar" tab="我的事件日历"></a-tab-pane>
              </a-tabs>
            </div>

            <div style="overflow-y: auto;height: calc(100vh - 200px);" >
              <transition mode="out-in" leave-active-class="cmp-out" enter-active-class="cmp-in">
                <Component :is="cmpObj"/>
              </transition>
            </div>

          </div>
        </a-col>
      </a-row>



    </div>
  </div>
</template>

<script setup>
import {defineAsyncComponent, ref, shallowRef} from "vue";
import {useUserStore} from "@/framework/store/UserStore";
import {Constant} from "@/js/common";
const userStore = useUserStore();

const myCalendar = defineAsyncComponent(() =>{
  return import('@/components/_myInfoMyCalendar.vue')
})
const baseInfo = defineAsyncComponent(() =>{
  return import('@/components/_myInfoBaseInfo.vue')
})

const tab_activeKey = ref("baseInfo");
const cmpObj = shallowRef(baseInfo);

const changeTabPage = ()=>{
  if (tab_activeKey.value === "baseInfo"){
    cmpObj.value = baseInfo;
  }else if (tab_activeKey.value === "myCalendar"){
    cmpObj.value = myCalendar;
  }

}

</script>

<style scoped>
.myInfo{
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #ffffff;
}
.myInfo .item{
  padding: 5px;
}
.cmp-in {
  animation: fadeIn;
  animation-duration: 0.2s; /* don't forget to set a duration! */
}
.cmp-out{
  animation: fadeOut;
  animation-duration: 0.2s;
}
</style>
