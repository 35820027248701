import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_kbsType = {
    findAllKbsType:function (data){
        return axios({
            url:"/kbsType/findAllKbsType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    saveKbsType:(data)=>{
        return axios({
            url:"/kbsType/saveKbsType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteKbsType:(id)=>{
        return axios({
            url:"/kbsType/deleteKbsType",
            method:"get",
            params:{
                id:id
            }
        })
    },
    updateKbsType:(data)=>{
        return axios({
            url:"/kbsType/updateKbsType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    getKbsType:(id)=>{
        return axios({
            url:"/kbsType/getKbsType",
            method:"get",
            params:{
                id:id
            }
        })
    },
}
export default api_kbsType;
