import {axios} from "@/framework/utils/request";
import qs from "qs";    //npm install qs

const api_alarmCenter = {
    findAllAlarm:function (data){
        return axios({
            url:"/alarm/findAllAlarm",
            method:"post",
            data:qs.stringify(data)
        })
    },


}

export default api_alarmCenter;