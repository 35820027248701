import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_transferOrderType = {
    findAllTransferType:function (data){
        return axios({
            url:"/base/findAllTransferType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    findAllSysAndTenantTransferOrderType:function (){
        return axios({
            url:"/base/findAllSysAndTenantTransferOrderType",
            method:"get",
        })
    },
    addTransferType:(data)=>{
        return axios({
            url:"/base/addTransferType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteTransferType:(data)=>{
        return axios({
            url:"/base/deleteTransferType",
            method:"post",
            data:qs.stringify(data, {indices: false})   //indices: false 传递数组
        })
    },
    getTransferOrderType:(id)=>{
        return axios({
            url:"/base/getTransferOrderType",
            method:"get",
            params:{
                id:id
            }
        })
    },
    updateTransferType:(data)=>{
        return axios({
            url:"/base/updateTransferType",
            method:"post",
            data:qs.stringify(data)
        })
    },
}
export default api_transferOrderType;
