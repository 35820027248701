<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div class="block" style="" >
        <div style="display: flex;box-sizing:border-box;height: 60px;" >
          <div style="flex: 3;display: flex;align-items: center;padding-left: 10px;">
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item name="assetTypeId">
                <a-select v-model:value="formSearchState.assetTypeId" :options="optionAssetType" @focus="showAllAssetType" style="width: 120px" size="default" allowClear placeholder="设备类型">
                  <template v-if="!optionAssetType.length" #notFoundContent>
                    <a-spin size="small" style="display: flex;justify-content: center;align-items: center"/>
                  </template>
                </a-select>
              </a-form-item>
              <a-form-item name="assetBrandId">
                <a-select v-model:value="formSearchState.assetBrandId" :options="optionAssetBrand" @focus="showAllAssetBrand" style="width: 120px" size="default" allowClear placeholder="设备品牌">
                  <template v-if="!optionAssetBrand.length" #notFoundContent>
                    <a-spin size="small" style="display: flex;justify-content: center;align-items: center"/>
                  </template>
                </a-select>
              </a-form-item>
              <a-form-item name="assetModel">
                <a-input v-model:value="formSearchState.assetModel" size="default" allowClear placeholder="设备型号" style="width: 120px;"/>
              </a-form-item>
              <a-form-item name="assetName">
                <a-input v-model:value="formSearchState.assetName" size="default" allowClear placeholder="设备名称" style="width: 120px;"/>
              </a-form-item>
              <a-form-item name="userId">
                <CustomInputDeptUser :defultValue="formSearchState.userId" size="default" placeholder="使用人" @callbackSelVal="callback_selDeptUser" style="width: 150px;"/>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div style="flex: 1;display: flex;justify-content: flex-end;align-items: center;padding-right: 0px;">
            <a-button v-permission="'assetManage_change'" :disabled="selectedRowKeys.length <= 0" style="margin-left: 5px;" type="primary" size="default" @click="()=>{changeItem_dialog_visible = true;}"><template #icon><interaction-outlined /></template>变更</a-button>
            <a-button v-permission="'assetManage_cancel'" :disabled="selectedRowKeys.length <= 0" style="margin-left: 5px;" type="primary" size="default" @click="cancelAsset"><template #icon><tag-outlined /></template>核销</a-button>
            <a-button v-permission="'assetManage_delete'" :disabled="selectedRowKeys.length <= 0" style="margin-left: 5px;" type="primary" danger size="default" @click="deleteAsset(null)"><template #icon><DeleteOutlined /></template>删除</a-button>
            <a-button v-permission="'assetManage_add'" style="margin-left: 5px;" type="primary" size="default" @click="showAddItemDialog"><template #icon><PlusOutlined /></template>添加</a-button>
          </div>
        </div>
        <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - x - 表头高度40 - 分页高度52 -->
        <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :bordered="true"
            size="small"
            :loading="loading_list"
            :scroll="{ x: 1000, y: 'calc(100vh - 247px)' }"
            :row-selection="rowSelection"
        >
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'operation'">
              <div style="display: flex;justify-content: left;">
                <a-button v-permission="'assetManage_view'" style="margin: 0px 4px;" type="link" size="small" @click="showViewAsset(record.id)"><template #icon><eye-outlined /></template><span style="margin-left:3px;">查看</span></a-button>
                <a-button v-permission="'assetManage_createReapir'" style="margin: 0px 4px;" type="link" size="small" @click="repair(record.id)"><template #icon><exclamation-circle-outlined /></template><span style="margin-left:3px;">报修</span></a-button>
              </div>
            </template>
            <template v-if="column.dataIndex === 'status'">
              <a-badge status="processing" v-if="record.assetRepairingCount" text='维修中'/>
              <a-tag color="error" v-else-if="record.status === Constant.ASSET_STATUS_CANCELED">已核销</a-tag>
              <a-tag color="green" v-else-if="record.status === Constant.ASSET_STATUS_UNUSED">未使用</a-tag>
              <a-tag color="warning" v-else-if="record.status === Constant.ASSET_STATUS_USED">已使用</a-tag>
            </template>
            <template v-if="column.dataIndex === 'assetType'">
              <span>{{record?.assetType?.name}}</span>
            </template>
            <template v-if="column.dataIndex === 'assetBrand'">
              <span>{{record?.assetBrand?.name}}</span>
            </template>
            <template v-if="column.dataIndex === 'user'">
              <span>{{record?.user?.name}}</span>
            </template>
          </template>
        </a-table>
        <div style="padding:10px;text-align: center">
          <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                        @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                        :page-size-options="['10','20','30','40','50']" show-size-changer>
            <template #buildOptionText="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>

      <!--    变更资产弹窗     -->
      <a-modal
          v-model:visible="changeItem_dialog_visible"
          title="编辑部门"
          ok-text="确认"
          cancel-text="取消"
          @ok="onChangeItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading_change">
          <a-form ref="formChangeRef" :model="formChangeState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" autocomplete="off">
            <a-form-item
                label="变更人"
                name="nowUserId"
                :rules="[{ required: true, message: '请选择变更人' ,trigger:'blur'}]">
              <CustomInputDeptUser size="default" @callbackSelVal="callback_selChangeDeptUser" style="width: 100%;"/>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!-- add -->
      <CreateAsset ref="createAssetRef" @callbackAddAsset="callbackAddAsset"></CreateAsset>
      <!-- 显示信息 -->
      <ViewAsset ref="viewAssetRef" :assetId="viewaAsetId" @callback="callback_viewAsset" v-if="isShowViewAsset"/>
      <!--    工单申请弹窗组件     -->
      <CreateWorkOrder ref="createWorkOrder" @setCallback="callback_CreateWorkOrder"></CreateWorkOrder>

    </div>
  </div>
</template>

<script setup>
import {defineAsyncComponent, onMounted, ref, unref, computed, createVNode} from "vue";
import {SearchOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined,PlusOutlined,InteractionOutlined,TagOutlined} from "@ant-design/icons-vue";
import {message, Modal} from "ant-design-vue";
import clone from "clone";
import api_asset from "@/api/asset";
import api_assetBase from "@/api/assetBase";
import {Constant} from "@/js/common";

/** 异步加载组件 **/
const CreateAsset = defineAsyncComponent(() =>{
  return import('@/components/_createAsset.vue')
})
const ViewAsset = defineAsyncComponent(() =>{
  return import('@/components/_viewAsset.vue')
})
const CustomInputDeptUser = defineAsyncComponent(() =>{
  return import('@/components/_customInputDeptUser.vue')
})
const CreateWorkOrder = defineAsyncComponent(() =>{
  return import('@/components/_createWorkOrder')
})

const viewaAsetId = ref(null);    //要查看的id
const loading_list = ref(false);
const loading_change = ref(false);
const changeItem_dialog_visible = ref(false);
const isShowViewAsset = ref(false);
const createAssetRef = ref();
const viewAssetRef = ref();
const createWorkOrder = ref();
let selectedRowKeys = ref([]);
const formSearchRef = ref();
const formChangeRef = ref();
const optionAssetType = ref([]);
const optionAssetBrand = ref([]);
const formSearchState = ref({
  assetTypeId: null,
  assetBrandId: null,
  assetModel: null,
  assetName: null,
  userId: null,

});
const formChangeState = ref({
  nowUserId: null
})
let dataSource = ref([]);
let columns= [{
    title: '编号',
    dataIndex: 'assetNo',
  }, {
    title: '名称',
    dataIndex: 'assetName',
  }, {
    title: '类型',
    dataIndex: 'assetType',
  }, {
    title: '品牌',
    dataIndex: 'assetBrand',
  }, {
    title: '型号',
    dataIndex: 'assetModel',
  }, {
    title: '购买时间',
    dataIndex: 'buyTime',
    width:150,
  }, {
    title: '使用人',
    dataIndex: 'user',
  }, {
    title: '领取时间',
    dataIndex: 'receiveTime',
    width:150,
  }, {
    title: '状态',
    dataIndex: 'status',
    width:80,
  },{
    title: '操作',
    dataIndex: 'operation',
    fixed: 'right',
    width:160
  }
];

let pagination = ref({
  current: 1,     //当前页
  pageSize: 20,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});
/**
 * 分页响应事件
 * @param page {@link pagination}
 */
let onPageChange = function (page){
  findAllItem(page);
}
/**
 * 改变pageSize分页条数
 * @param current 当前页
 * @param size  分页条数
 */
const onShowSizeChanger = function (current, size){
  pagination.value.current = current;
  pagination.value.pageSize = size;
}
/**
 * 跳到第一页
 */
const toHomePage = ()=>{
  findAllItem(1);
  pagination.value.current = 1;
}
/**
 * 刷新当前页
 */
const toCurrentPage = ()=>{
  findAllItem(pagination.value.current);
}

onMounted(()=>{
  findAllItem(1);


})

const findAllItem = (page)=>{
  loading_list.value = true;
  let params = clone(formSearchState.value);  //克隆新的数据以免干扰formSearchState
  params.page = page;
  params.rows = pagination.value.pageSize;

  api_asset.showAllAsset(params).then(res=>{
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const list = res.data.data.list;
      if (list){
        const _total = res.data.data.total;
        pagination.value.total = _total;

        list.forEach((item)=>{
          item['key'] = item.id;  //加key用于列表前的复选框可以多选
          //console.log(item)
          const ii = {key:item.id,id:item.id,assetNo:item.assetNo,assetName:item.assetName,assetType:item.assetType,assetBrand:item.assetBrand,
            assetModel:item.assetModel,buyTime:item.buyTime,user:item.user,receiveTime:item.receiveTime,status:item.status,assetRepairingCount:item.assetRepairingCount}
          dataSource.value.push(ii);
        });
      }
    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading_list.value = false;
  });
}

const onSearch = ()=>{
  formSearchRef.value.validateFields().then(()=>{
    toHomePage();
  }).catch(()=>{
    //console.log("onSearch  info ---",info);
  });
}

/**
 * table 复选框操作
 * @type {ComputedRef<{onChange: function(*): void, selectedRowKeys: Array<UnwrapRefSimple<*>>}>}
 */
const rowSelection = computed(() => {
  return {
    selectedRowKeys: unref(selectedRowKeys),
    onChange: changableRowKeys => {
      selectedRowKeys.value = changableRowKeys;
    }
  };
});
/**
 * 显示添加资产
 */
const showAddItemDialog = ()=>{
  createAssetRef.value.show();
}
/**
 * 显示资产详情(调用ViewMessage子组件)
 * @param id
 */
const showViewAsset = (id)=>{
  viewaAsetId.value = id;
  isShowViewAsset.value = true;
}

/**
 * 批量设置变更
 */
const onChangeItemOK = ()=>{
  formChangeRef.value.validateFields().then(values=>{
    loading_change.value = true;
    values.ids = selectedRowKeys.value;
    api_asset.changeAsset(values).then(res=>{
      if (res?.data?.code === 0) {
        changeItem_dialog_visible.value = false;
        selectedRowKeys.value = [];
        toCurrentPage();
        message.success("操作成功", 3);
      }else {
        if (res?.data?.msg) {
          message.error(res?.data?.msg + " " + res?.data?.data, 5);
        }
      }
      loading_change.value = false;
    });
  }).catch(()=>{
    //console.log("onSaveItemOK  info ---",info);
  });

}
/**
 * 批量核销
 */
const cancelAsset = ()=> {
  Modal.confirm({
    title: '系统提示',
    icon: createVNode(ExclamationCircleOutlined),
    content: '确定要核销资产吗?',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      return new Promise((resolve, reject) => {  //使用异步处理同时按钮显示加载动画
        const params = {ids: selectedRowKeys.value};
        api_asset.cancelAsset(params).then(res => {
          if (res?.data?.code === 0) {
            message.success("操作成功", 3);
            resolve();
            selectedRowKeys.value = [];
            toCurrentPage();
          } else {
            if (res?.data?.msg) {
              message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
            reject();
          }
        });
      });
    },
    onCancel() {
    }
  });
}
/**
 * 批量删除message
 */
const deleteAsset = (id)=> {
  Modal.confirm({
    title: '系统提示',
    icon: createVNode(ExclamationCircleOutlined),
    content: '确定要删除吗?',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      return new Promise((resolve, reject) => {  //使用异步处理同时按钮显示加载动画
        const params = {ids: id ? id : selectedRowKeys.value};
        api_asset.deleteAsset(params).then(res => {
          if (res?.data?.code === 0) {
            message.success("操作成功", 3);
            resolve();
            selectedRowKeys.value = [];
            toCurrentPage();
          } else {
            if (res?.data?.msg) {
              message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
            reject();
          }
        });
      });
    },
    onCancel() {
    }
  });
}
/**
 *点击报修按钮
 * @param id assetId
 */
const repair = (id)=>{
  createWorkOrder.value.showAddItemDialog(id);  //父调子方法
}
/**
 * 异步加载下拉选择资产类型
 */
const showAllAssetType = ()=>{
  optionAssetType.value = [];
  api_assetBase.showAllAssetType({}).then(res=> {
    //console.log("res----",res);
    if (res?.data?.code === 0) {
      const list = res.data.data.list;console.log(list)
      if (list && list.length > 0) {
        for (let item of list) {
          optionAssetType.value.push({value:item.id,label:item.name})
        }
      }
    } else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
/**
 * 异步加载下拉选择资产品牌
 */
const showAllAssetBrand = ()=>{
  optionAssetBrand.value = [];
  api_assetBase.showAllAssetBrand({}).then(res=> {
    //console.log("res----",res);
    if (res?.data?.code === 0) {
      const list = res.data.data.list;console.log(list)
      if (list && list.length > 0) {
        for (let item of list) {
          optionAssetBrand.value.push({value:item.id,label:item.name})
        }
      }
    } else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
/**
 * 保障人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selDeptUser=(val)=>{
  formSearchState.value.userId = val;
}
/**
 * 变更人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selChangeDeptUser=(val)=>{
  formChangeState.value.nowUserId = val;
}
/**
 * 添加消息页面回调
 */
const callbackAddAsset = ()=>{
  toCurrentPage();
}
/**
 * 创建工单组件子调父回调
 * @param flg 创建工单成功or失败
 */
const callback_CreateWorkOrder = (flg)=>{
  //if (flg){
    console.log(flg)
  //}
}
/**
 * 查看窗口关闭回调
 */
const callback_viewAsset = ()=>{
  viewaAsetId.value = null;
  isShowViewAsset.value = false;
  toCurrentPage();
}


</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
