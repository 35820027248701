import {Constant} from "@/js/common";

/**
 * WebSocket封装类
 * @author MengLu
 * 使用方式：var ws = new MyWebSocket("ws路径");
            ws.start();
            ws.onRelink = function () {
			    console.log("---------onRelink");
		    };
            ws.onReceive = function (data) {
			    console.log("---------onReceive ",data)
		    };
 */
var MyWebSocket = function (ws) {
    //var heartTimeout = 30;             //心跳超时时间,秒
    var checkHeartTime = 5000;         //定时检测心跳任务时间，毫秒
    var resetStartTime = 5000;          //连接失败重试时间，毫秒

    var _this = this;
    var isRun = false;
    var resetCount = 0;
    var websocket = null;
    var isReset = false;	//是否经历过重置过连接
    //var heartDate = new Date();	//心跳时间
    //var _callback;
    this.onReceive = {};    //回调外部定义的方法
    this.onRelink = {};     //回调reset 重新open
    this.onConnected = {};     //回调连接成功
    this.onExpired = {};    //过期回调，redis user is null

    this.start = function () {
        if (websocket){
            websocket.close();
            websocket = null;
        }

        //判断当前浏览器是否支持WebSocket
        if ('WebSocket' in window) {
            websocket = new WebSocket(ws);
        }else {
            alert('当前浏览器不支持websocket将无法接收通知,建议使用非IE目前最新的浏览器!')
        }
        //连接发生错误的回调方法
        websocket.onerror = function (e) {
            console.log("ws: onerror",e.toString());
        };
        //连接成功建立的回调方法
        websocket.onopen = function () {
            isRun = true;
            //heartDate = new Date();	//心跳时间
            if (isReset === true){ try {_this.onRelink();}catch (e){console.log(e)}} 	//当reset后立即请求同步数据库防止在网络异常这段时间发生过告警信息
            console.log("WebSocket连接成功");
            try {_this.onConnected();}catch (e){console.log(e)}
        };
        //接收到消息的回调方法
        websocket.onmessage = function (event) {
            //_callback(event.data);
            var data = JSON.parse(event.data);//console.log(data)
            if (data?.code === Constant.WEBSOCKET_CODE_HEART){     //接收到心跳指令
                //heartDate = new Date();
                if (websocket){
                    //接收到心跳同时也向服务器发送心跳
                    window.setTimeout(()=>{
                        var msg = {code:0};
                        try {
                            websocket.send(JSON.stringify(msg));
                            //console.log("ws:to server send heart")
                        }catch (e) {console.log(e);}
                    }, checkHeartTime);
                }
            }else if(data?.code === Constant.WEBSOCKET_CODE_EXPIRED){   //接收到过期指令,redis user is null
                _this.onExpired();
            }else{
                try { _this.onReceive(data.data);}catch (e){console.log(e)}
            }
        };

        //连接关闭的回调方法
        websocket.onclose = function () {
            console.log("ws onclosed");
            if (isRun){
                reset();
            }
        };

        //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
        window.onbeforeunload = function () {
            if(websocket){websocket.close();}
        };
    };
    /**
     * 关闭连接及相关定时器任务
     */
    this.close = function (){
        isRun = false;
        if(websocket){websocket.close();websocket = null;}
    }
    /*var checkHeart = function(){
        var nowDate = new Date();
        var diffVal = (nowDate-heartDate)/1000;		//相差多少秒
        console.log("checkServer: ",diffVal)
        if(diffVal > heartTimeout){
            reset();
        }
    };*/


    /**
     * 当发生错误或者客户端网络端口情况下尝试重新连接
     */
    var reset = function(){
        ++resetCount;
        console.log("ws重新连接..."+( resetCount ));
        isReset = true;
        window.setTimeout(_this.start, resetStartTime);
    };
    // this.onReceive = function (callback) {
    //     _callback = callback;
    // };

};
export default MyWebSocket;


