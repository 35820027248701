import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_inform = {
    showAllMessage:function (data){
        return axios({
            url:"/message/showAllMessage",
            method:"post",
            data:qs.stringify(data)
        })
    },
    viewMessage:(id)=>{
        return axios({
            url:"/message/viewMessage",
            method:"get",
            params:{
                id:id
            }
        })
    },
    updateMessageIsRead:function (data){
        return axios({
            url:"/message/updateMessageIsRead",
            method:"post",
            data:qs.stringify(data, {indices: false})   //indices: false 传递数组
        })
    },
    deleteMessage:function (data){
        return axios({
            url:"/message/deleteMessage",
            method:"post",
            data:qs.stringify(data, {indices: false})   //indices: false 传递数组
        })
    },

    getInformPlatformWeCom:()=>{
        return axios({
            url:"/inform/getInformPlatformWeCom",
            method:"get",
            params:{
            }
        })
    },
    getInformPlatformMail:()=>{
        return axios({
            url:"/inform/getInformPlatformMail",
            method:"get",
            params:{
            }
        })
    },
    getInformWoSet:()=>{
        return axios({
            url:"/inform/getInformWoSet",
            method:"get",
            params:{
            }
        })
    },
    updatePlatformWeCom:function (data){
        return axios({
            url:"/inform/updatePlatformWeCom",
            method:"post",
            data:qs.stringify(data)
        })
    },
    updatePlatformMail:function (data){
        return axios({
            url:"/inform/updatePlatformMail",
            method:"post",
            data:qs.stringify(data)
        })
    },
    updateInformWoSet:function (data){
        return axios({
            url:"/inform/updateInformWoSet",
            method:"post",
            data:qs.stringify(data)
        })
    },
    linkPlatformWeCom:()=>{
        return axios({
            url:"/inform/linkPlatformWeCom",
            method:"get",
            params:{
            }
        })
    },
    linkPlatformMail:(data)=>{
        return axios({
            url:"/inform/linkPlatformMail",
            method:"post",
            data:qs.stringify(data)
        })
    },
    sysWeComIdToDb:()=>{
        return axios({
            url:"/inform/sysWeComIdToDb",
            method:"get",
            params:{
            }
        })
    },

}
export default api_inform;
