<template>
  <div ref="container" style="height:100%;position: relative;overflow: hidden">
    <div style="background-color: #fff;">
      <div class="tableHeaderContainer" >
        <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
          功能操作
          <!-- 搜索抽屉框(局部弹窗需要外层div Css position: relative;overflow: hidden ) start-->
          <a-drawer
              title="条件筛选"
              :mask="true"
              placement="top"
              v-model:visible="searchDrawerVisible"
              :get-container="()=>{return $refs.container}"
              :style="{ position: 'absolute' }"
              :height="200"
          >
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item label="项目名称" name="projectName">
                <a-input v-model:value="formSearchState.projectName" size="default" />
              </a-form-item>
            </a-form>
            <template #footer>
              <div style="text-align: right">
                <a-button style="margin-right: 8px" @click="()=>{searchDrawerVisible = false;}"><template #icon><CloseOutlined /></template>关闭</a-button>
                <a-button type="primary" @click="onSearch()"><template #icon><SearchOutlined /></template>搜索</a-button>
              </div>
            </template>
          </a-drawer>
          <!-- 搜索抽屉框 end -->
        </div>
        <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
          <a-button style="margin-left: 5px;" type="primary" size="small" @click="()=>{searchDrawerVisible = true;}"><template #icon><SearchOutlined /></template>条件筛选</a-button>
          <a-button v-permission="'project_add'" style="margin-left: 5px;" type="primary" size="small" @click="showAddItemDialog()"><template #icon><PlusCircleOutlined /></template>添加项目</a-button>
        </div>
      </div>
      <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - tableHeaderContainer 40 - 表头高度40 - 分页高度52 -->
      <a-table
          class="ant-table-striped"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :bordered="true"
          size="small"
          :loading="loading"
          :scroll="{ x:1200,y: 'calc(100vh - 227px)' }"
      >
        <template #bodyCell="{ column,record }">
          <template v-if="column.dataIndex === 'operation'">
            <div style="display: flex;justify-content: left;">
              <a-button v-permission="'project_update'" style="margin: 0px 4px;" type="link" size="small" @click="showEditItemDialog(record)"><template #icon><EditOutlined /></template><span style="margin-left:3px;">编辑</span></a-button>
              <a-button v-permission="'project_delete'" style="margin: 0px 4px;" type="link" size="small" danger @click="deleteItem(record)"><template #icon><DeleteOutlined /></template><span style="margin-left:3px;">删除</span></a-button>
              <a-button v-permission="'project_active'" style="margin: 0px 4px;" type="link" size="small" @click="setActived(record.id)"><template #icon><tag-outlined /></template><span style="margin-left:3px;">设为活动</span></a-button>
            </div>
          </template>
          <template v-if="column.dataIndex === 'projectName'">
            <a-tooltip :title="record.projectName" placement="topLeft">{{record.projectName}}</a-tooltip>
          </template>
          <template v-if="column.dataIndex === 'remark'">
            <a-tooltip :title="record.remark" placement="topLeft">{{record.remark}}</a-tooltip>
          </template>
          <template v-else-if="column.dataIndex === 'isActive'">
            <a-tag v-if="record.isActive === 1" color="success">活动的</a-tag>
            <template v-else>{{}}</template>
          </template>
        </template>
      </a-table>

      <div style="padding:10px;text-align: center">
        <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                      @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                      :page-size-options="['10','20','30','40','50']" show-size-changer>
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>



      <!--    编辑用户弹窗     -->
      <a-modal
          v-model:visible="editItem_dialog_visible"
          title="编辑项目"
          ok-text="确认"
          cancel-text="取消"
          @ok="onUpdateItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formEditRef" :model="formEditState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" name="edit">
            <a-form-item
                label="项目名称"
                name="projectName"
                :rules="[{ required: true, message: '请输入项目名称',trigger:'blur' },{pattern:/^[\u4e00-\u9fa5\w]{1,100}$/,message:'项目名称只能包含中文、英文、数字及下划线最大长度100',trigger:'blur'}]"
            >
              <a-input v-model:value="formEditState.projectName" size="default" :maxlength="100"/>
            </a-form-item>
            <a-form-item
                label="开始日期"
                name="startTime"
                :rules="[{ required: true, message: '请输入有效日期' }]"
            >
              <a-date-picker v-model:value="formEditState.startTime" format="YYYY-MM-DD" :locale="locale" size="default"/>
            </a-form-item>
            <a-form-item
                label="结束日期"
                name="endTime"
                :rules="[{ required: true, message: '请输入有效日期' }]"
            >
              <a-date-picker v-model:value="formEditState.endTime" format="YYYY-MM-DD" :locale="locale" size="default"/>
            </a-form-item>
            <a-form-item label="备注" name="remark" :rules="[{ max:200, message: '备注长度不能超过200个字符',trigger:'blur' }]">
              <a-textarea v-model:value="formEditState.remark" size="default" show-count :maxlength="200" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    添加用户弹窗     -->
      <a-modal
          v-model:visible="addItem_dialog_visible"
          title="添加项目"
          ok-text="确认"
          cancel-text="取消"
          @ok="onSaveItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formAddRef" :model="formAddState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" name="add">
            <a-form-item
                label="项目名称"
                name="projectName"
                :rules="[{ required: true, message: '请输入项目名称',trigger:'blur' },{pattern:/^[\u4e00-\u9fa5\w]{1,100}$/,message:'项目名称只能包含中文、英文、数字及下划线最大长度100',trigger:'blur'}]"
            >
              <a-input v-model:value="formAddState.projectName" size="default" :maxlength="100"/>
            </a-form-item>
            <a-form-item
                label="开始日期"
                name="startTime"
                :rules="[{ required: true, message: '请输入有效日期' }]"
            >
              <a-date-picker v-model:value="formAddState.startTime" format="YYYY-MM-DD" :locale="locale" size="default"/>
            </a-form-item>
            <a-form-item
                label="结束日期"
                name="endTime"
                :rules="[{ required: true, message: '请输入有效日期' }]"
            >
              <a-date-picker v-model:value="formAddState.endTime" format="YYYY-MM-DD" :locale="locale" size="default"/>
            </a-form-item>
            <a-form-item label="备注" name="remark" :rules="[{ max:200, message: '备注长度不能超过200个字符',trigger:'blur' }]">
              <a-textarea v-model:value="formAddState.remark" size="default" show-count :maxlength="200" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

    </div>
  </div>
</template>

<script>
import {onMounted, ref, createVNode, reactive, inject} from "vue";
import api_project from "@/api/project";
import {message,Modal} from "ant-design-vue";
import clone from "clone";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import {
  SearchOutlined,
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
    TagOutlined
} from "@ant-design/icons-vue";

dayjs.locale('zh-cn');
export default {
  components: {
    SearchOutlined,
    PlusCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
    // eslint-disable-next-line vue/no-unused-components
    ExclamationCircleOutlined,
    TagOutlined
  },
  setup(){
    const notify_project_active = inject("notify_project_active");
    const loading = ref(false);
    const formAddRef = ref();
    const formEditRef = ref();
    const formSearchRef = ref();
    const searchDrawerVisible = ref(false);
    const editItem_dialog_visible = ref(false);
    const addItem_dialog_visible = ref(false);
    const allot_dialog_visible = ref(false);
    const showActiveId = ref();

    const formAddState = reactive({
      projectName: "",
      startTime: "",
      endTime: "",
      remark: ""
    });
    const formEditState = reactive({
      id:"",
      projectName: "",
      startTime: "",
      endTime: "",
      remark: ""
    });
    const formSearchState = reactive({
      projectName: ""
    });
    let dataSource = ref([]);
    let columns= [
      {
        title: '序号',
        dataIndex: 'index',
        width:100,
        customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
      },{
        title: '项目名称',
        dataIndex: 'projectName',
        ellipsis: true,
      },{
        title: '项目编号',
        dataIndex: 'projectNo',
      },{
        title: '开始日期',
        dataIndex: 'startTime',
      },{
        title: '结束日期',
        dataIndex: 'endTime',
      },{
        title: '创建时间',
        dataIndex: 'createTime',
      },{
        title: '状态',
        dataIndex: 'isActive',
      },{
        title: '备注',
        dataIndex: 'remark',
        ellipsis: true,
      },{
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width:300
      }
    ];

    let pagination = ref({
      current: 1,     //当前页
      pageSize: 10,  //每页条数
      total: 1,     //数据总数
      pageSizeOptions:['2','4','6'],
      showSizeChanger:true
    });
    /**
     * 清空searchData 数据
     */
    const clearSearchData = ()=>{
      formSearchState.projectName = "";
    }
    /**
     * 查询所有用户
     * @param page 页数
     */
    const findAllItem = function (page){
      loading.value = true;
      let params = clone(formSearchState);  //克隆新的数据以免干扰formSearchState
      params.page = page;
      params.rows = pagination.value.pageSize;

      //console.log("findAllItem searchData----",formSearchState);
      api_project.findAllProject(params).then(res=>{
        //console.log("res----",res);
        if(res?.data?.code === 0){
          dataSource.value = [];
          const dataList = res.data.data.list;
          if (!dataList){return;}
          const _total = res.data.data.total;
          pagination.value.total = _total;

          dataList.forEach((item)=>{
            dataSource.value.push({id:item.id,projectName:item.projectName,projectNo:item.projectNo,
              startTime:item.startTime,endTime:item.endTime,remark:item.remark,createTime:item.createTime,isActive:item.isActive})
          })
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }
    /**
     * 分页响应事件
     * @param page {@link pagination}
     */
    let onPageChange = function (page){
      findAllItem(page);
    }
    /**
     * 改变pageSize分页条数
     * @param current 当前页
     * @param size  分页条数
     */
    const onShowSizeChanger = function (current, size){
      pagination.value.current = current;
      pagination.value.pageSize = size;
    }
    /**
     * 跳到第一页
     */
    const toHomePage = ()=>{
      findAllItem(1);
      pagination.value.current = 1;
    }
    /**
     * 跳到最后页
     */
    const toEndPage = ()=>{
      findAllItem(getTotalPage());
      pagination.value.current = getTotalPage();
    }
    /**
     * 刷新当前页
     */
    const toCurrentPage = ()=>{
      findAllItem(pagination.value.current);
    }

    /**
     * 获取当前总页数
     */
    const getTotalPage =()=>{
      if (pagination.value.total === 0){
        return 1;
      }
      return pagination.value.total%pagination.value.pageSize === 0
          ? pagination.value.total/pagination.value.pageSize
          : parseInt(pagination.value.total/pagination.value.pageSize)+1;
    }

    onMounted(()=>{
      findAllItem(1);

    })

    /**
     * 显示修改记录弹窗
     * @param record 实体数据
     */
    const showEditItemDialog = record =>{
      editItem_dialog_visible.value = true;
      loading.value = true;
      api_project.getProject(record.id).then(res=>{
        if(res?.data?.code === 0){
          formEditState.id = res.data.data.id;
          formEditState.projectName = res.data.data.projectName;
          formEditState.remark = res.data.data.remark;
          formEditState.startTime = dayjs(res.data.data.startTime.toString(),"YYYY-MM-DD");
          formEditState.endTime = dayjs(res.data.data.endTime.toString(),"YYYY-MM-DD");
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });
    }
    /**
     * 点击修改记录弹窗->确认按钮
     */
    const onUpdateItemOK = function (){
      formEditRef.value.validateFields().then(values=>{
        loading.value = true;
        values.id = formEditState.id;
        values.startTime = values.startTime.format("YYYY-MM-DD");
        values.endTime = values.endTime.format("YYYY-MM-DD");
        //console.log("onUpdateItemOK  values ---",values);
        api_project.updateProject(values).then(res=>{
          if(res?.data?.code === 0){
            message.success("操作成功",3);
            editItem_dialog_visible.value = false;
            toCurrentPage();
          }else{
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }
          }
          loading.value = false;
        })
      }).catch(()=>{
        //console.log("onUpdateItemOK  info ---",info);
      });
    }
    /**
     * 显示添加用户弹窗
     */
    const showAddItemDialog = () =>{
      addItem_dialog_visible.value = true;
    }
    /**
     * 添加用户弹窗->确认
     */
    const onSaveItemOK = () =>{
      formAddRef.value.validateFields().then(values=>{
        loading.value = true;
        values.startTime = values.startTime.format("YYYY-MM-DD");
        values.endTime = values.endTime.format("YYYY-MM-DD");
        //console.log("onSaveItemOK  values ---",values);
        api_project.addProject(values).then(res=>{
          if(res?.data?.code === 0){
            message.success("操作成功",3);
            addItem_dialog_visible.value = false;
            clearSearchData();  //清空searchData,避免当前列表处于搜索后的数据显示，添加新的数据后不显示
            if (pagination.value.total <= 0){   //如果是第一次添加项目则去通知_projectSelect.vue 去获取
              notify_project_active();  //去通知_projectSelect.vue 数据变更了
            }
            toEndPage();
          }else{
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }
          }
          loading.value = false;
        })
      }).catch(()=>{
        //console.log("onSaveItemOK  info ---",info);
      });
    }

    /**
     * 删除一条记录
     * @param record 实体数据
     */
    const deleteItem = function (record){
      //console.log(record);
      Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '删除项目同时也会删除其下所有的关联，确定要删除此项目？',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
            api_project.deleteProject(record.id).then(res=>{
              if(res?.data?.code === 0){
                message.success("操作成功",3);
                resolve();
                toCurrentPage();
                notify_project_active();  //去通知_projectSelect.vue 数据变更了
              }else{
                if (res?.data?.msg){
                  message.error(res?.data?.msg+" "+res?.data?.data,5);
                }
                reject();
              }
            });
          });
        },
        onCancel() {
        }
      });
    }
    /**
     * 搜索
     */
    const onSearch = ()=>{
      formSearchRef.value.validateFields().then(()=>{
        searchDrawerVisible.value = false;
        //console.log("onSearch  values ---",values);

        toHomePage();
      }).catch(()=>{
        //console.log("onSearch  info ---",info);
      });
    }
    /**
     * 设置项目为活动的
     * @param record record
     */
    const setActived = (id)=>{
      api_project.active(id).then(res=>{
        if(res?.data?.code === 0){
          message.success("操作成功",3);
          toHomePage();

        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
      });
    }

    /**
     * 自定义table行
     * @param record record
     * @returns {{onMouseenter: onMouseenter, onMouseleave: onMouseleave}}
     */
    /*const customRow = (record)=>{
      console.log(record)
      return{
        /!** 鼠标移入显示活动按钮 **!/
        onMouseenter:()=>{
          //showActiveId.value = record.id;
        },
        /!** 鼠标移出显示非活动按钮 **!/
        onMouseleave:()=>{
          //showActiveId.value = "";
        }
      }
    }*/

    return{
      columns,
      dataSource,
      pagination,
      onPageChange,
      onShowSizeChanger,
      formAddRef,
      formEditRef,
      formSearchRef,
      formAddState,
      formEditState,
      formSearchState,
      searchDrawerVisible,
      editItem_dialog_visible,
      addItem_dialog_visible,
      showEditItemDialog,
      showAddItemDialog,
      onUpdateItemOK,
      onSaveItemOK,
      deleteItem,
      onSearch,
      toEndPage,
      allot_dialog_visible,
      showActiveId,
      setActived,
      locale,
      loading
    }
  }

}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.tableHeaderContainer{
  display: flex;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #fafafa;
  background-color: #ffffff;
}


</style>
