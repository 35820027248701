import axios from "axios";
import {useUserStore} from "@/framework/store/UserStore";
import {message,Modal} from "ant-design-vue";
import {createVNode,ref} from "vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {router} from "@/router";
import config from "@/js/config";
import {Constant} from "@/js/common";

let status = ref({
    loading: false
})

//自定义一个全局axios实例
const request = axios.create({
    baseURL:config.server_url,
    timeout:60000
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
    status.value.loading = true;
    config.headers.Device = Constant.LOGIN_DEVICE_TYPE_PC;  //附带的token设备类型信息
    /** 在请求前将登录后获取到的token附带上 **/
    const userStore = useUserStore();
    if (userStore && userStore.token){
        config.headers.Authorization = userStore.token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) { // 2xx 范围内的状态码都会触发该函数。
    //console.log("response.interceptors  response--- ",response);
    status.value.loading = false;
    if (response.status === 200){
        const code = response.data.code;
        if (code === 90000){
            message.error("系统异常",5);
            return false;
        }
    }
    return response;
}, function (error) {   // 超出 2xx 范围的状态码都会触发该函数
    //console.log("response.interceptors  err--- ",error);
    status.value.loading = false;
    /**
     * 响应返回的异常信息
     * 400 请求出错
     * 401 未授权：登录失败
     * 403 禁止：禁止执行访问
     * 404 找不到
     * 405 不允许此方法
     * 406 不可接受
     * 407 需要代理身份验证
     * @type {number | ((code: number) => any)}
     */
    if (error.code === "ERR_NETWORK"){
        message.error("网络连接异常！",5);
    }else {
        const errStatus = error.response?.status;
        let msg = error.response?.data?.msg;
        if(!msg){   //如果返回没有附带的参数数据则显示axios的错误信息
            msg = error.message;
        }
        if (errStatus === 401){ //未授权，token相关错误
            const userStore = useUserStore();
            if (userStore && !userStore.isPC){  //如果是是 /mobile/**** 连接的
                message.error("会话已失效！",20);
            }else{
                showLoginDialog(msg);
            }
        }else if(errStatus === 404){
            message.error("404，请求路径没有找到",5);
        }else if (errStatus === 0){
            message.error("网络连接异常！",5);
        }else{
            message.error(msg,5);
        }
    }

    return;
   // return Promise.reject(error);
});

const showLoginDialog = function (msg){
    Modal.destroyAll();
    Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: msg+',请重新登录系统！',
        okText: '确定',
        cancelText: '取消',

        onOk() {
            const userStore = useUserStore();
            userStore.logout().then(()=>{
                router.push("/login");
            }).catch(err=>{
                message.error(err.message,5);
            });
        },

        onCancel() {

        },

        class: 'test',
    });
}

export {
    request as axios,
    status
}
