<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div class="block" style="" >
        <div style="display: flex;box-sizing:border-box;height: 60px;" >
          <div style="flex: 3;display: flex;align-items: center;padding-left: 10px;">
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item name="type">
                <a-select v-model:value="formSearchState.type" style="width: 120px" size="default">
                  <a-select-option value="">全部类型</a-select-option>
                  <a-select-option :value="Constant.MESSAGE_TYPE_WORKORDER">工单</a-select-option>
                  <a-select-option :value="Constant.MESSAGE_TYPE_ASSET">资产</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item name="title">
                <a-input v-model:value="formSearchState.title" placeholder="标题" size="default" />
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div style="flex: 1;display: flex;align-items: center;">
            <template v-if="selectedRowKeys.length > 0">已选择<span style="font-weight: bold;margin: 0px 4px"> {{selectedRowKeys.length}} </span>条记录</template>
          </div>
          <div style="flex: 2;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
            <a-button v-permission="'message_updateMessageIsRead'" :disabled="selectedRowKeys.length <= 0" style="margin: 0px 0px;" type="primary" size="default" @click="updateMessageIsRead"><template #icon><eye-outlined /></template>标记已读</a-button>
            <a-button v-permission="'message_delete'" :disabled="selectedRowKeys.length <= 0" style="margin: 0px 5px;" type="primary" danger size="default" @click="deleteMessage(null)"><template #icon><DeleteOutlined /></template>批量删除</a-button>
          </div>
        </div>
        <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - search 50 - 表头高度40 - 分页高度52 -->
        <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :bordered="true"
            size="small"
            :loading="loading_list"
            :scroll="{ x: 1200, y: 'calc(100vh - 247px)' }"
            :row-selection="rowSelection"
        >
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'operation'">
              <div style="display: flex;justify-content: left;">
                <a-button v-permission="'message_delete'" style="margin: 0px 4px;" type="link" size="small" @click="showViewMessage(record.id)"><template #icon><eye-outlined /></template><span style="margin-left:3px;">查看</span></a-button>
                <a-button v-permission="'message_view'"  style="margin: 0px 4px;" type="link" danger size="small" @click="deleteMessage(record.id)"><template #icon><DeleteOutlined /></template><span style="margin-left:3px;">删除</span></a-button>
              </div>
            </template>
            <template v-if="column.dataIndex === 'type'">
              <span v-if="record.type === Constant.MESSAGE_TYPE_WORKORDER">工单</span>
              <span v-if="record.type === Constant.MESSAGE_TYPE_ASSET">资产</span>
            </template>
            <template v-if="column.dataIndex === 'status'">
              <a-tag color="error" v-if="record.status === 0">未读</a-tag>
              <a-tag color="green" v-else-if="record.status === 1">已读</a-tag>
            </template>
          </template>
        </a-table>
        <div style="padding:10px;text-align: center">
          <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                        @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                        :page-size-options="['10','20','30','40','50']" show-size-changer>
            <template #buildOptionText="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>

      <!-- 显示信息 -->
      <ViewMessage ref="viewMessageRef" @callbackMessage="callbackMessage"></ViewMessage>

    </div>
  </div>
</template>

<script setup>
import {defineAsyncComponent, onMounted, ref, unref, computed, createVNode} from "vue";
import {SearchOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {Constant} from "@/js/common";
import api_inform from "@/api/inform";
import {message, Modal} from "ant-design-vue";
import clone from "clone";

/** 异步加载组件 **/
const ViewMessage = defineAsyncComponent(() =>{
  return import('@/components/_viewMessage.vue')
})

const loading_list = ref(false);
const viewMessageRef = ref();
let selectedRowKeys = ref([]);
const formSearchRef = ref();
const formSearchState = ref({
  type: '',
  title: ''
})
let dataSource = ref([]);
let columns= [
  {
    title: '序号',
    dataIndex: 'index',
    width:80,
    customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
  }, {
    title: '类型',
    dataIndex: 'type',
    width:80,
  }, {
    title: '标题',
    dataIndex: 'title',
  }, {
    title: '状态',
    dataIndex: 'status',
    width:80,
  }, {
    title: '时间',
    dataIndex: 'createDate',
    width:180,
  },{
    title: '操作',
    dataIndex: 'operation',
    fixed: 'right',
    width:300
  }
];

let pagination = ref({
  current: 1,     //当前页
  pageSize: 20,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});
/**
 * 分页响应事件
 * @param page {@link pagination}
 */
let onPageChange = function (page){
  findAllItem(page);
}
/**
 * 改变pageSize分页条数
 * @param current 当前页
 * @param size  分页条数
 */
const onShowSizeChanger = function (current, size){
  pagination.value.current = current;
  pagination.value.pageSize = size;
}
/**
 * 跳到第一页
 */
const toHomePage = ()=>{
  findAllItem(1);
  pagination.value.current = 1;
}
/**
 * 刷新当前页
 */
const toCurrentPage = ()=>{
  findAllItem(pagination.value.current);
}



onMounted(()=>{
  findAllItem(1);


})

const findAllItem = (page)=>{
  loading_list.value = true;
  let params = clone(formSearchState.value);  //克隆新的数据以免干扰formSearchState
  params.page = page;
  params.rows = pagination.value.pageSize;

  api_inform.showAllMessage(params).then(res=>{
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const list = res.data.data.list;
      if (list){
        const _total = res.data.data.total;
        pagination.value.total = _total;

        list.forEach((item)=>{
          item['key'] = item.id;  //加key用于列表前的复选框可以多选
          //console.log(item.id);
          const ii = {key:item.id,id:item.id,type:item.type,title:item.title,status:item.status,createDate:item.createDate}
          dataSource.value.push(ii);
        });
      }
    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading_list.value = false;
  });
}

const onSearch = ()=>{
  formSearchRef.value.validateFields().then(()=>{
    toHomePage();
  }).catch(()=>{
    //console.log("onSearch  info ---",info);
  });
}

/**
 * table 复选框操作
 * @type {ComputedRef<{onChange: function(*): void, selectedRowKeys: Array<UnwrapRefSimple<*>>}>}
 */
const rowSelection = computed(() => {
  return {
    selectedRowKeys: unref(selectedRowKeys),
    onChange: changableRowKeys => {
      selectedRowKeys.value = changableRowKeys;
      //console.log('selectedRowKeys changed: ', selectedRowKeys.value);

    }
  };


});

/**
 * 显示信息(调用ViewMessage子组件)
 * @param id
 */
const showViewMessage = (id)=>{
  viewMessageRef.value.show(id);
}
/**
 * 查看消息页面回调
 */
const callbackMessage = ()=>{
  toCurrentPage();
}
/**
 * 批量设置message为已读
 */
const updateMessageIsRead = ()=>{
  const params = {ids:selectedRowKeys.value};
  api_inform.updateMessageIsRead(params).then(res=>{
    if (res?.data?.code === 0) {
      toCurrentPage();
    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
/**
 * 批量删除message
 */
const deleteMessage = (id)=>{
  Modal.confirm({
    title: '系统提示',
    icon: createVNode(ExclamationCircleOutlined),
    content: '确定要删除吗?',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
        const params = {ids:id ? id : selectedRowKeys.value};
        api_inform.deleteMessage(params).then(res=>{
          if (res?.data?.code === 0) {
            message.success("操作成功",3);
            resolve();
            selectedRowKeys.value = [];
            toCurrentPage();
          }else {
            if (res?.data?.msg) {
              message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
            reject();
          }
        });
      });
    },
    onCancel() {
    }
  });

}

</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
