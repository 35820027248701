import {axios} from "@/framework/utils/request";
import qs from "qs";


const api_statement = {
    findAllStatementOfWo:function (data){
        return axios({
            url:"/statement/findAllStatementOfWo",
            method:"post",
            data:qs.stringify(data)
        })
    },
    findAllStatementOfInspect:function (data){
        return axios({
            url:"/statement/findAllStatementOfInspect",
            method:"post",
            data:qs.stringify(data)
        })
    },
    exportToExcelOfWorkOrder:function (data){
        return axios({
            url:"/statement/exportToExcelOfWorkOrder",
            method:"post",
            responseType:'blob',
            data:qs.stringify(data)
        })
    },
    exportToExcelOfInspect:function (data){
        return axios({
            url:"/statement/exportToExcelOfInspect",
            method:"post",
            responseType:'blob',
            data:qs.stringify(data)
        })
    },
    findAllStatementOfDept:function (data){
        return axios({
            url:"/statement/findAllStatementOfDept",
            method:"post",
            data:qs.stringify(data)
        })
    },
    findAllStatementOfUser:function (data){
        return axios({
            url:"/statement/findAllStatementOfUser",
            method:"post",
            data:qs.stringify(data)
        })
    },
    findAllStatementOfFaultType:function (data){
        return axios({
            url:"/statement/findAllStatementOfFaultType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    exportToExcelOfDept:function (data){
        return axios({
            url:"/statement/exportToExcelOfDept",
            method:"post",
            responseType:'blob',
            data:qs.stringify(data)
        })
    },
    exportToExcelOfUser:function (data){
        return axios({
            url:"/statement/exportToExcelOfUser",
            method:"post",
            responseType:'blob',
            data:qs.stringify(data)
        })
    },
    exportToExcelOfAsset:function (data){
        return axios({
            url:"/statement/exportToExcelOfAsset",
            method:"post",
            responseType:'blob',
            data:qs.stringify(data)
        })
    },
    exportToExcelOfFaultType:function (data){
        return axios({
            url:"/statement/exportToExcelOfFaultType",
            method:"post",
            responseType:'blob',
            data:qs.stringify(data)
        })
    },
    findAllStatementOfAsset:(data)=>{
        return axios({
            url:"/statement/findAllStatementOfAsset",
            method:"post",
            data:qs.stringify(data)
        })
    },
    getUserWoStatement:()=>{
        return axios({
            url:"/statement/getUserWoStatement",
            method:"get",
            params:{}
        })
    },
    getUserAssetStatement:()=>{
        return axios({
            url:"/statement/getUserAssetStatement",
            method:"get",
            params:{}
        })
    },
    getUserCommentStatement:()=>{
        return axios({
            url:"/statement/getUserCommentStatement",
            method:"get",
            params:{}
        })
    },
    getUserInfoEventCaleandar:(type)=>{
        return axios({
            url:"/statement/getUserInfoEventCaleandar",
            method:"get",
            params:{
                type:type
            }
        })
    },
    findAllYwUserCommentStatement:function (){
        return axios({
            url:"/statement/findAllYwUserCommentStatement",
            method:"get",
        })
    },

}
export default api_statement;
