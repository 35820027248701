<template>
  <div ref="container" style="height:100%;position: relative;overflow: hidden">
    <div style="padding: 0px;height: 100%">

      <a-card size="small" title="概述" >
        <div style="display: flex;">
          <div style="flex: 1;align-self: center;">
            <div style="display: flex;align-items:center;">
              <div><a-avatar :size="64" style="background-color: #87d068">{{userStore.name}}</a-avatar></div>
              <div style="padding-left: 15px;">
                <div><a-typography-title :level="5">欢迎您，{{userStore.name}}</a-typography-title></div>
                <div style="color:rgba(0, 0, 0, 0.45);">
                    <a-tooltip title="所属企业部门"><idcard-two-tone style="padding-right: 5px;"/>{{userStore?.dept?.name}}</a-tooltip>
                    <a-divider type="vertical" style="height: 10px;"/>
                    <a-tooltip title="手机号"><phone-two-tone style="padding-right: 5px;"/>{{userStore.phone}}</a-tooltip>
                    <a-divider type="vertical" style="height: 12px;"/>
                    <a-tooltip title="上一次登录时间"><clock-circle-two-tone style="padding-right: 5px;"/>{{userStore.lastLoginTime ? userStore.lastLoginTime : '首次登录'}}</a-tooltip>
                    <a-divider type="vertical" style="height: 12px;"/>
                    <a-tooltip title="拥有的角色"><control-two-tone style="padding-right: 5px;"/>
                      <template v-for="(item,index) in userStore.roleList" :key="index">
                          {{item.name}}<span style="padding-right: 5px;"></span>
                      </template>
                    </a-tooltip>
                </div>
              </div>
            </div>

          </div>

          <div style="flex: 1;display: flex;justify-content: flex-end;">
              <div style="padding: 13px 15px"><a-button type="link" @click="showCreateWorkOrder"><template #icon><file-word-outlined /></template>工单申请</a-button></div>
              <div style="padding: 0px 15px"><a-divider type="vertical" style="height: 90%;"/></div>
              <div style="padding: 0px 15px"><a @click="()=>{router.push('/myWorkOrder/pending');}"><a-statistic title="待我处理工单" :value="woPendingSum" :value-style="{ color: '#1890ff' }" style="margin-right: 0px" /></a></div>
              <div style="padding: 0px 15px"><a-divider type="vertical" style="height: 90%;"/></div>
              <div style="padding: 0px 15px"><a @click="()=>{router.push('/assetCenter/assetManage')}"><a-statistic title="在维修资产" :value="myAssetState.doRepaired" :value-style="{ color: '#1890ff' }" style="margin-right: 0px" /></a></div>
          </div>
        </div>
      </a-card>

      <div style="height: 10px;"></div>

        <a-card size="small">
          <template #title>
            <div>{{myTopWorkOrderTitle}}<a-tooltip title="注意：项目管理中设定的活动项目下的最新处理工单"><ExclamationCircleOutlined style="color: #9a9999;margin-left: 5px"/></a-tooltip></div>
          </template>
            <template #extra><a @click="showViewWorkOrder(myTopWorkOrderState.id)">{{myWoCardMoreTitle}}</a></template>
            <a-spin :spinning="loading_myTopWorkOrder">
                <div style="display:flex;align-items:center;height: 100px;">
                    <div style="flex:1;">
                        <a-descriptions :column='3'>
                            <a-descriptions-item label="工单号">{{myTopWorkOrderState.orderNo}}</a-descriptions-item>
                            <a-descriptions-item label="创建时间">{{ myTopWorkOrderState.createTime }}</a-descriptions-item>
                            <a-descriptions-item label="等级">
                                <a-tag v-if="myTopWorkOrderState.importanceLevel === 0" color="error">紧急</a-tag>
                                <a-tag v-else-if="myTopWorkOrderState.importanceLevel === 1" color="warning">重要</a-tag>
                                <a-tag v-else-if="myTopWorkOrderState.importanceLevel === 2" color="processing">一般</a-tag>
                            </a-descriptions-item>
                            <a-descriptions-item label="故障描述">
                                <a-typography-text
                                        :style="{ width: '80%' }"
                                        :ellipsis="{ tooltip: myTopWorkOrderState.faultOverview }"
                                        :content="myTopWorkOrderState.faultOverview"
                                />
                            </a-descriptions-item>

                        </a-descriptions>
                    </div>
                    <div style="flex:1;">
                        <a-steps size="small">
                            <template v-for="(item,index1) in myTopWorkOrderState.workOrderFlows" :key="index1">
                                <a-step status="finish">
                                    <template #title>{{flowTypeObj[item.flowType]}}</template>
                                    <template #description>
                                        <div>操作人：{{ item.senderId }}</div>
                                        <div>时间：{{ item.operationTime }}</div>
                                    </template>
                                </a-step>
                            </template>
                        </a-steps>
                    </div>
                </div>
            </a-spin>
        </a-card>

        <div style="height: 10px;"></div>

      <a-row :gutter="10">
        <a-col :span="8">
          <a-card size="small">
            <template #title>
              <div>我的工单<a-tooltip title="注意：项目管理中设定的活动项目下的我的工单统计"><ExclamationCircleOutlined style="color: #9a9999;margin-left: 5px"/></a-tooltip></div>
            </template>
            <template #extra></template>
              <a-spin :spinning="loading_userWoStatement">
                <div style="display:flex;align-items:center;height: 130px;">
                  <div style="flex:1;">
                    <div style="line-height: 30px;">我创建的工单：<a @click="()=>{router.push('/myWorkOrder/created');}">{{ myWorkOrderState.created }}</a></div>
                    <div style="line-height: 30px;">我报障的工单：<a @click="()=>{router.push('/myWorkOrder/report');}">{{ myWorkOrderState.reported }}</a></div>
                    <div style="line-height: 30px;">我处理的工单：<a @click="()=>{router.push('/myWorkOrder/pending');}">{{ myWorkOrderState.solve }}</a></div>
                  </div>
                  <div style="flex:1;align-self: stretch;"><div id="myWoChart_id" style="height: 100%"></div></div>
                </div>
                <a-divider style="margin-top: 5px;margin-bottom: 5px;"/>
                <div style="display: flex;"><div style="flex: 1">工单总数</div><div style="flex: 0">{{myWorkOrderState.created + myWorkOrderState.reported + myWorkOrderState.solve}}</div></div>
              </a-spin>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card size="small" title="我的资产">
            <template #extra><a @click="()=>{router.push('/assetCenter/assetManage')}">更多</a></template>
              <a-spin :spinning="loading_userAssetStatement">
                <div style="display:flex;align-items:center;height: 130px;">
                  <div style="flex:1;">
                    <div style="line-height: 30px;">维修中的资产：{{ myAssetState.doRepaired }}</div>
                    <div style="line-height: 30px;">已核销的资产：{{ myAssetState.canceled }}</div>
                    <div style="line-height: 30px;">已维修的次数：{{ myAssetState.repaired }}</div>
                  </div>
                  <div style="flex:1;align-self: stretch;"><div id="myAssetChart_id" style="height: 100%"></div></div>
                </div>
                <a-divider style="margin-top: 5px;margin-bottom: 5px;"/>
                <div style="display: flex;"><div style="flex: 1">资产总数</div><div style="flex: 0">{{ myAssetState.total }}</div></div>
              </a-spin>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card size="small">
            <template #title>
              <div>我的评价<a-tooltip title="注意：项目管理中设定的活动项目下的我的评价统计"><ExclamationCircleOutlined style="color: #9a9999;margin-left: 5px"/></a-tooltip></div>
            </template>
            <template #extra></template>
              <a-spin :spinning="loading_userCommentStatement">
                <div style="display:flex;align-items:center;height: 130px;">
                  <div style="flex:1">
                    <div style="line-height: 30px;">满  意：{{ myCommentState.good }}</div>
                    <div style="line-height: 30px;">不满意：{{ myCommentState.bad }}</div>
                  </div>
                  <div style="flex:1;">
                    <div id="" >
                      <a-tooltip :title="`好评率(${myCommentState.goodRate}%)`">
                        <a-progress type="circle" :strokeWidth="15" :width="105" stroke-linecap="square" :percent="myCommentState.goodRate" >
                          <template #format="percent">
                            <div style="margin-bottom: 3px;">{{ percent }}%</div>
                            <div style="margin-top:3px;font-size: 14px;">好评率</div>
                          </template>
                        </a-progress>
                      </a-tooltip>
                    </div>
                  </div>
                </div>
                <a-divider style="margin-top: 5px;margin-bottom: 5px;"/>
                <div style="display: flex;"><div style="flex: 1">评价总数</div><div style="flex: 0">{{ myCommentState.good+myCommentState.bad }}</div></div>
              </a-spin>
          </a-card>
        </a-col>
      </a-row>

      <div style="height: 10px;"></div>

      <div style="height:calc(100vh - 657px);">
        <div style="display: grid;grid-template-columns: 50% 50%; height: 100%;">

          <div style="display:grid;grid-template-columns:100%;grid-template-rows:38px auto;background-color: #ffffff;margin-right: 5px;">
              <div style="display:grid;grid-template-columns:1fr auto;line-height: 38px;border-bottom: 1px solid #f0f0f0;">
                <div style="padding-left: 12px;">我的工单列表
                  <span><a-tooltip title="注意：项目管理中设定的活动项目下的我的工单列表"><ExclamationCircleOutlined style="color: #9a9999;margin-left: 5px"/></a-tooltip></span>
                </div>
                <div style="padding-right: 12px;"></div>
              </div>
              <div style="height:100%;padding:10px;">
                  <a-table
                          class="ant-table-striped"
                          :columns="columns_myWorkOrderList"
                          :data-source="dataSource_myWorkOrderList"
                          :pagination="false"
                          :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                          :bordered="true"
                          size="small"
                          :loading="loading_myWorkOrderList"
                          :scroll="{ y: 'calc(100vh - 759px)' }"
                          style="width: 100%;"
                          :custom-row="tableWoRowEvent"
                  >
                      <template #bodyCell="{ column,record }">
                          <template v-if="column.dataIndex === 'orderNo'">
                              {{record.orderNo}}
                          </template>
                          <template v-else-if="column.dataIndex === 'faultOverview'">
                              <a-tooltip :title="record.faultOverview" placement="topLeft">{{record.faultOverview}}</a-tooltip>
                          </template>
                          <template v-else-if="column.dataIndex === 'reportUser'">
                              {{record.reportUser?.name}}
                          </template>
                          <template v-else-if="column.dataIndex === 'statusMap'">

                              <a-badge status="success" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_FINISHED" text='已完成' />
                              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_REPORT" :text='flowTypeObj[record.statusMap.code]' />
                              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_DISPATCH" :text='flowTypeObj[record.statusMap.code]'/>
                              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_ASSIGN" :text='flowTypeObj[record.statusMap.code]'/>
                              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_REJECT" :text='flowTypeObj[record.statusMap.code]'/>
                              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_RECEIVE" :text='flowTypeObj[record.statusMap.code]'/>
                              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_ARRIVED" :text='flowTypeObj[record.statusMap.code]'/>
                              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_TRANSFER" :text='flowTypeObj[record.statusMap.code]'/>
                              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_DELAY" :text='flowTypeObj[record.statusMap.code]'/>
                              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_COOPERATE" :text='flowTypeObj[record.statusMap.code]'/>
                          </template>
                          <template v-else-if="column.dataIndex === 'commentMap'">
                              {{record.commentMap.value}}
                          </template>
                      </template>
                  </a-table>
              </div>
          </div>

          <div style="display:grid;grid-template-columns:100%;grid-template-rows:38px auto;background-color: #ffffff;margin-left: 5px;">
              <div style="display:grid;grid-template-columns:1fr auto;line-height: 38px;border-bottom: 1px solid #f0f0f0;">
                  <div style="padding-left: 12px;">我的消息列表</div>
                  <div style="padding-right: 12px;"><a @click="()=>{router.push('/inform/message')}">更多</a></div>
              </div>
              <div style="padding:10px;">
                  <a-table
                          class="ant-table-striped"
                          :columns="columns_message"
                          :data-source="dataSource_message"
                          :pagination="false"
                          :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                          :bordered="true"
                          size="small"
                          :loading="loading_message"
                          :scroll="{ x: 500, y: 'calc(100vh - 759px)' }"
                          :custom-row="tableInformRowEvent"
                  >
                      <template #bodyCell="{ column,record }">
                          <template v-if="column.dataIndex === 'type'">
                              <span v-if="record.type === Constant.MESSAGE_TYPE_WORKORDER">工单</span>
                              <span v-if="record.type === Constant.MESSAGE_TYPE_ASSET">资产</span>
                          </template>
                          <template v-if="column.dataIndex === 'status'">
                              <a-tag color="error" v-if="record.status === 0">未读</a-tag>
                              <a-tag color="green" v-else-if="record.status === 1">已读</a-tag>
                          </template>
                      </template>
                  </a-table>
              </div>
          </div>

        </div>
      </div>

        <!--    工单申请弹窗组件     -->
        <CreateWorkOrder ref="createWorkOrder" @setCallback="callback_CreateWorkOrder"></CreateWorkOrder>
        <!--    工单查看     -->
        <ViewWorkOrder :workOrderId="viewWorkOderId" v-if="isShowViewWorkOrder"></ViewWorkOrder>
        <!-- 点击未读消息item弹窗详细信息 -->
        <ViewMessageDialog ref="viewMessageDialogRef" @callback="callback_viewMessageDialog"/>

    </div>
  </div>
</template>

<script setup>
import {
  PhoneTwoTone,
  IdcardTwoTone,
  ControlTwoTone,
  ClockCircleTwoTone,
  ExclamationCircleOutlined, FileWordOutlined
} from "@ant-design/icons-vue";
import {computed, defineAsyncComponent, inject, onBeforeUnmount, onMounted, ref, watch} from "vue";
import * as echarts from "echarts";
import api_workOrder from "@/api/workOrder";
import {message, notification} from "ant-design-vue";
import {Constant, flowTypeObj} from "@/js/common";
import api_inform from "@/api/inform";
import {useUserStore} from "@/framework/store/UserStore";
import api_statement from "@/api/statement";
import {router} from "@/router";
import api_user from "@/framework/api/user";

/** 异步加载组件 **/
const CreateWorkOrder = defineAsyncComponent(() =>{
  return import('@/components/_createWorkOrder')
})
const ViewWorkOrder = defineAsyncComponent(() =>{
    return import('@/components/_viewWorkOrder')
})
const ViewMessageDialog = defineAsyncComponent(()=>{
    return import('@/components/_viewMessageDialog.vue');
})

const wdp = inject("watchDataPool");

const createWorkOrder = ref(null);
const viewWorkOderId = ref(null);       //要查看工单的id
const isShowViewWorkOrder = ref(false);
const viewMessageDialogRef = ref();
const userStore = useUserStore();
let heartTimer;
const myWoChart_id = "myWoChart_id";
const myAssetChart_id = "myAssetChart_id";
let myWoChart;
let myAssetChart;
const woPendingSum = ref("");
const loading_myTopWorkOrder = ref(false);
const loading_userWoStatement = ref(false);
const loading_userAssetStatement = ref(false);
const loading_userCommentStatement = ref(false);
/** 我的工单卡片数据 （我创建的数,我报障的数,我处理的工单数。待我处理的，我已处理完成的）**/
const myWorkOrderState = ref({
    rid:0,   //随机数用于更新监听更新图表数据
    created: 0,
    reported: 0,
    solve: 0,
    doFinished: 0,
    doUnFinished: 0,
});
/** 我的资产卡片数据（资产总数、已维修的、正在维修的、已核销的） **/
const myAssetState = ref({
    rid:0,  //随机数用于更新监听更新图表数据
    total:0,
    repaired:0,
    doRepaired:0,
    canceled:0
});
/** 我的评价卡片数据（满意数、不满意数） **/
const myCommentState = ref({
   rid:0,
   good:0,
   bad:0,
   goodRate:0
});
/** 我最新一条工单数据 **/
const myTopWorkOrderState = ref({
    id: '',
    orderNo: '',
    createTime:'',
    faultOverview:'',
    importanceLevel:'',
    workOrderFlows:'',
    status:null,
    comment:null,

    createUser: null,
    reportUser: null,
    pendingUser: null
})

const loading_myWorkOrderList = ref(false);
let dataSource_myWorkOrderList = ref([]);
let columns_myWorkOrderList= ref([
   {
        title: '工单号',
        dataIndex: 'orderNo',
        width: 180,
    },{
        title: '创建时间',
        dataIndex: 'createTime',
        width: 150,
    },{
        title: '责任人',
        dataIndex: 'reportUser',
        width: 100,
        ellipsis: true,
    },{
        title: '故障描述',
        dataIndex: 'faultOverview',
        ellipsis: true,

    },{
        title: '状态',
        dataIndex: 'statusMap',
        width: 100
    }
]);
const loading_message = ref(false);
let dataSource_message = ref([]);
let columns_message = [
    {
        title: '类型',
        dataIndex: 'type',
        width:80,
    }, {
        title: '标题',
        dataIndex: 'title',
    }, {
        title: '状态',
        dataIndex: 'status',
        width:80,
    }, {
        title: '时间',
        dataIndex: 'createDate',
        width:150,
    }
];
const loadAllApi = ()=>{
    getWorkOrderStateSum();
    getUserWoStatement();
    getUserAssetStatement();
    getUserCommentStatement();
    getMyTopWorkOrder();
    findAllWorkOrderOfMyAll();
    findAllMyMessage();
}
onMounted(()=>{
    loadMyWoChart();
    loadMyAssetChart();

    loadAllApi();

    heartTimer = setInterval(heart,600000); //定时请求后端方法，避免token过期,600000 = 10分钟
});

/**
 * 定时请求后端方法，避免token过期
 */
const heart = ()=>{
  api_user.heart().then(res=>{
    if(res?.data?.code === 0){
     //console.log(res.data);

    }else{
      if (res?.data?.msg){
        message.error(res?.data?.msg+" "+res?.data?.data,5);
      }
    }

  });
}

/** 使用onBeforeUnmount避免chart_id在移除之前销毁 **/
onBeforeUnmount(()=>{
  let woChartDom = echarts.getInstanceByDom(document.getElementById(myWoChart_id));
  if (woChartDom){
    woChartDom.clear();
    woChartDom.dispose();
  }
  let assetChartDom = echarts.getInstanceByDom(document.getElementById(myAssetChart_id));
  if (assetChartDom){
    assetChartDom.clear();
    assetChartDom.dispose();
  }
  if (heartTimer){
    clearInterval(heartTimer);
  }
})
/** 显示我最新一条工单卡片标题文字 **/
const myTopWorkOrderTitle = computed(()=>{
    if (userStore && userStore.id){
        if (userStore.id === myTopWorkOrderState.value.createUser?.id && userStore.id !== myTopWorkOrderState.value.reportUser?.id && userStore.id !== myTopWorkOrderState.value.pendingUser?.id){
            return "我最新创建的";
        }else if (userStore.id === myTopWorkOrderState.value.reportUser?.id || userStore.id === myTopWorkOrderState.value.createUser?.id && userStore.id !== myTopWorkOrderState.value.pendingUser?.id){
            return "我最新报障的";
        }else if (userStore.id === myTopWorkOrderState.value.pendingUser?.id){
            if (myTopWorkOrderState.value.status === Constant.WORK_ORDER_FLOW_TYPE_FINISHED){
                return "我最近完成的";
            }
            return "待我处理的";
        }
    }
    return "我最新一条工单";
})
/** 显示我最新一条工单卡片右侧连接文字 **/
const myWoCardMoreTitle = computed(()=>{
    if (userStore && userStore.id){
        if (userStore.id === myTopWorkOrderState.value.pendingUser?.id){
            //当前的工单的处理人是我并且状态是未完成的
            if (myTopWorkOrderState.value.status !== Constant.WORK_ORDER_FLOW_TYPE_FINISHED){
                return "去处理";
            }else if(myTopWorkOrderState.value.status === Constant.WORK_ORDER_FLOW_TYPE_FINISHED && myTopWorkOrderState.value.comment){
                return "已评价";
            }
        }else if (userStore.id === myTopWorkOrderState.value.reportUser?.id){
            //当前的工单的报障人是我自己并且状态是完成的且未评论
            if (myTopWorkOrderState.value.status === Constant.WORK_ORDER_FLOW_TYPE_FINISHED && !myTopWorkOrderState.value.comment){
                return "去评价";
            }
        }
    }
    return "查看";
});
/**
 * 监听我的工单数据设置图表数据
 */
watch(()=>{return myWorkOrderState},()=>{
    let chartDom = echarts.getInstanceByDom(document.getElementById(myWoChart_id));
    if (chartDom){
        chartDom.setOption( {
            series: [
                {
                    data: [
                        { value: myWorkOrderState.value.created, name: '我创建的工单' },
                        { value: myWorkOrderState.value.reported, name: '我报障的工单' },
                        { value: myWorkOrderState.value.solve, name: '我处理的工单' },
                    ]
                }
            ]
        });
    }
},{deep:true});
/**
 * 监听我的资产数据设置图表数据
 */
watch(()=>{return myAssetState},()=>{
    let chartDom = echarts.getInstanceByDom(document.getElementById(myAssetChart_id));
    if (chartDom){
        chartDom.setOption( {
            series: [
                {
                    data: [
                        { value: myAssetState.value.doRepaired, name: '维修中资产' },
                        { value: myAssetState.value.total-myAssetState.value.doRepaired, name: '使用中资产' },
                        { value: myAssetState.value.canceled, name: '已核销资产' },
                    ]
                }
            ]
        });
    }
},{deep:true});
/**
 * 统计当前用户关联工单某一状态下所有工单数量
 *
 * @param type  null：时判断当前用户如果时工单处理人则查询待我处理的否则都是待我评价的，
 *              2：Constant.MY_WORK_ORDER_PENDING 待我处理的 ，
 *              7：Constant.MY_WORK_ORDER_UNCOMMENT 待评价的
 * @return int
 */
const getWorkOrderStateSum = ()=>{
  api_workOrder.getWorkOrderStateSum(Constant.MY_WORK_ORDER_PENDING).then(res => {
    //console.log("res----",res);
    if (res?.data?.code === 0) {
      if (res.data.data && res.data.data > 0){
        woPendingSum.value = res.data.data;
      }
    } else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
/**
 * 我的工单卡片。统计我所有工单信息（我处理的工单数，我报障的数，我创建的数。待我处理的，我已处理完成的）
 */
const getUserWoStatement = ()=>{
    loading_userWoStatement.value = true;
    api_statement.getUserWoStatement().then(res => {
        //console.log("res----",res);
        if (res?.data?.code === 0) {

            const dataObj = res.data.data;
            if (dataObj){
                myWorkOrderState.value.created = dataObj.created;
                myWorkOrderState.value.solve = dataObj.solve;
                myWorkOrderState.value.reported = dataObj.reported;
                myWorkOrderState.value.doFinished = dataObj.doFinished;
                myWorkOrderState.value.doUnFinished = dataObj.doUnFinished;
            }

        } else {
            if (res?.data?.msg) {
                message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
        }
        loading_userWoStatement.value = false;
    });
}
/**
 * 我的资产卡片。统计某用户资产信息（总数，维修的，正在维修的次数总数等）
 */
const getUserAssetStatement = ()=>{
    loading_userAssetStatement.value = true;
    api_statement.getUserAssetStatement().then(res => {
        //console.log("res----",res);
        if (res?.data?.code === 0) {

            const dataObj = res.data.data;
            if (dataObj){
                myAssetState.value.total = dataObj.total;
                myAssetState.value.repaired = dataObj.repaired;
                myAssetState.value.doRepaired = dataObj.doRepaired;
                myAssetState.value.canceled = dataObj.canceled;
            }

        } else {
            if (res?.data?.msg) {
                message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
        }
        loading_userAssetStatement.value = false;
    });
}
/**
 * 我的评价卡片。统计某用户的评价（满意数，不满意数,好评率）
 */
const getUserCommentStatement = ()=>{
    loading_userCommentStatement.value = true;
    api_statement.getUserCommentStatement().then(res => {
        //console.log("res----",res);
        if (res?.data?.code === 0) {

            const dataObj = res.data.data;
            if (dataObj){
                myCommentState.value.good = dataObj.good;
                myCommentState.value.bad = dataObj.bad;
                myCommentState.value.goodRate = dataObj.goodRate;
            }

        } else {
            if (res?.data?.msg) {
                message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
        }
        loading_userCommentStatement.value = false;
    });
}

/**
 * 我的工单饼形图
 */
const loadMyWoChart = ()=>{
  myWoChart = echarts.getInstanceByDom(document.getElementById(myWoChart_id))
  if (!myWoChart){
    myWoChart = echarts.init(document.getElementById(myWoChart_id));
  }
  myWoChart.setOption( {
    tooltip: {
      trigger: 'item'
    },

    series: [
      {
        name: '我的工单',
        type: 'pie',
        radius: ['50%', '80%'],
        label: {
          show: true,
          position: 'inside'
        },
        data: [
            { value: myWorkOrderState.value.created, name: '我创建的工单' },
            { value: myWorkOrderState.value.reported, name: '我报障的工单' },
            { value: myWorkOrderState.value.solve, name: '我处理的工单' },
        ]
      }
    ]
  });
  myWorkOrderState.value.rid = 1;
}
/**
 * 我的资产饼形图
 */
const loadMyAssetChart = ()=>{
    myAssetChart = echarts.getInstanceByDom(document.getElementById(myAssetChart_id))
    if (!myAssetChart){
    myAssetChart = echarts.init(document.getElementById(myAssetChart_id));
    }
    myAssetChart.setOption( {
    tooltip: {
      trigger: 'item'
    },

    series: [
      {
        name: '我的资产',
        type: 'pie',
        radius: ['50%', '80%'],
        label: {
          show: true,
          position: 'inside'
        },
        data: [
            { value: myAssetState.value.doRepaired, name: '维修中资产' },
          { value: myAssetState.value.total-myAssetState.value.doRepaired, name: '使用中资产' },
            { value: myAssetState.value.canceled, name: '已核销资产' },
        ]
      }
    ]
    });
    myAssetState.value.rid = 1;
}

/**
 * 获取最新一条我相关的工单信息（我创建的、我报障的、我处理的） 所有状态
 */
const getMyTopWorkOrder = function () {
    loading_myTopWorkOrder.value = true;
    api_workOrder.getMyTopWorkOrder().then(res => {
        //console.log("res----",res);
        if (res?.data?.code === 0) {

            const dataObj = res.data.data;
            if (dataObj){
                myTopWorkOrderState.value.id = dataObj.id;
                myTopWorkOrderState.value.orderNo = dataObj.orderNo;
                myTopWorkOrderState.value.createTime = dataObj.createTime;
                myTopWorkOrderState.value.faultOverview = dataObj.faultOverview;
                myTopWorkOrderState.value.importanceLevel = dataObj.importanceLevel;
                myTopWorkOrderState.value.workOrderFlows = dataObj.workOrderFlows;
                myTopWorkOrderState.value.createUser = dataObj.createUser;
                myTopWorkOrderState.value.reportUser = dataObj.reportUser;
                myTopWorkOrderState.value.pendingUser = dataObj.pendingUser;
                myTopWorkOrderState.value.status = dataObj.status;
                myTopWorkOrderState.value.comment = dataObj.comment;
            }

        } else {
            if (res?.data?.msg) {
                message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
        }
        loading_myTopWorkOrder.value = false;
    });
}
/**
 * 查询所以和我相关的工单（我创建的、我报障的、我处理的） 所有状态
 */
const findAllWorkOrderOfMyAll = function () {
    loading_myWorkOrderList.value = true;
    let params = {}
    params.page = 1;
    params.rows = 20;
    //console.log("findAllItem searchData----",formSearchState);
    api_workOrder.findAllWorkOrderOfMyAll(params).then(res => {
        //console.log("res----",res);
        if (res?.data?.code === 0) {
            dataSource_myWorkOrderList.value = [];
            const dataList = res.data.data;
            //console.log("%%-->",1%5);
            dataList.forEach((item) => {
                //console.log("dataList-->",setFlowType(item.flowType));

                dataSource_myWorkOrderList.value.push(item);
            })
        } else {
            if (res?.data?.msg) {
                message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
        }
        loading_myWorkOrderList.value = false;
    });
}
const findAllMyMessage = ()=>{
    loading_message.value = true;
    let params = {};
    params.page = 1;
    params.rows = 20;

    api_inform.showAllMessage(params).then(res=>{
        if (res?.data?.code === 0) {
            dataSource_message.value = [];
            const list = res.data.data.list;
            if (list){
                list.forEach((item)=>{
                    const ii = {id:item.id,type:item.type,title:item.title,status:item.status,createDate:item.createDate}
                    dataSource_message.value.push(ii);
                });
            }
        }else {
            if (res?.data?.msg) {
                message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
        }
        loading_message.value = false;
    });
}
/**
 * 显示创建工单弹窗
 */
const showCreateWorkOrder = ()=>{
  createWorkOrder.value.showAddItemDialog();  //父调子方法
}
/**
 * 创建工单组件子调父回调
 * @param flg 创建工单成功or失败
 */
const callback_CreateWorkOrder = (flg)=>{
  if (flg){
    loadAllApi();
  }
}
/**
 * 查看工单
 * @param id 工单id
 */
const showViewWorkOrder = (id)=>{
    viewWorkOderId.value = id;
    isShowViewWorkOrder.value = true;
}

/**
 * 我的工单列表 - table行事件
 * @param record
 * @returns {{onClick: onClick}}
 */
const tableWoRowEvent = (record)=>{
    return{
        style:{
          'cursor':'pointer'    //设置鼠标显示样式
        },
        onClick:()=>{
            showViewWorkOrder(record.id);
        },
    }
}
/**
 * 我的消息列表 - table行事件
 * @param record
 * @returns {{onClick: onClick}}
 */
const tableInformRowEvent = (record)=>{
    return{
        style:{
            'cursor':'pointer'    //设置鼠标显示样式
        },
        onClick:()=>{
            viewMessageDialogRef.value.show(record.id);
        },
    }
}
/**
 * 弹窗查看消息后回调
 * @param msgId message id
 * @param flg 是否数据加载成功
 */
const callback_viewMessageDialog = (msgId,flg)=>{
    if (flg){
        notification.close(msgId);      //同时关闭该通知的弹窗
        //findAllMyMessage(); //websocket已有监听因此不需要了
    }else{
        message.error("消息查看出错了！",5);
    }
}
/**  监听websocket 的消息  **/
const injectWsData = inject("ws-data");
watch(()=>{return injectWsData},()=>{
    //console.log("========watch: ",injectWsData.value)
    if (injectWsData.value.code === Constant.WEBSOCKET_INFORM_CODE_WORKORDER_STATUS_CHANGEED){
        loadAllApi();
    }else if (injectWsData.value.code === Constant.WEBSOCKET_INFORM_CODE_ASSET_STATUS_CHANGE){
        loadAllApi();
    } else if (injectWsData.value.code === Constant.WEBSOCKET_INFORM_CODE_MESSAGE_STATUS_CHANGE){
        findAllMyMessage();
    }
},{deep:true});

/**
 * 监听FrameMain.vue 数据变化，刷新数据,_viewWorkOrder.vue 页面关闭如有操作则刷新此页面数据
 */
watch(()=>{return wdp.value.wake_homePage_tag},()=>{
  viewWorkOderId.value = null;
  isShowViewWorkOrder.value = false;
  if (wdp.value.data){
    loadAllApi();
  }
})

</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
:deep(.ant-steps-item-description) {
    max-width: none!important;
}
</style>
