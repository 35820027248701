<template>
  <div>
    <router-view v-if="isShow"></router-view>
  </div>
</template>
<script setup>
import {nextTick, onMounted, provide, ref} from 'vue';
import {Constant} from "@/js/common";
const isShow = ref(true);

onMounted(()=>{
  document.title = Constant.PRODUCT_NAME; //设置全局的title
})

/**
 * 刷新整体页面
 */
provide("reload_app",()=>{
  isShow.value = false;
  //dom显示完成后回调的此方法，避免dom还未创建去获取数据
  nextTick(()=>{
    isShow.value = true;
  });
});
</script>
<style>


</style>
