import {axios} from "@/framework/utils/request";
import qs from "qs";    //npm install qs

const api_tenant = {
    showAllTenant:function (data){
        return axios({
            url:"/tRequest/showAllTenant",
            method:"post",
            data:qs.stringify(data)
        })
    },
    showAllTenantRequest:function (data){
        return axios({
            url:"/tRequest/showAllTenantRequest",
            method:"post",
            data:qs.stringify(data)
        })
    },
    saveTenant:function (data){
        return axios({
            url:"/tRequest/saveTenant",
            method:"post",
            data:qs.stringify(data)
        })
    },
    getTenant:function (id){
        return axios({
            url:"/tRequest/getTenant",
            method:"get",
            params:{
                tenantId:id
            }
        });
    },
    updateTenant:function (data){
        return axios({
            url:"/tRequest/updateTenant",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteTenant:function (id){
        return axios({
            url:"/tRequest/deleteTenant",
            method:"get",
            params:{
                id:id
            }
        });
    },
    deleteTenantRequest:function (id){
        return axios({
            url:"/tRequest/deleteTenantRequest",
            method:"get",
            params:{
                id:id
            }
        });
    },
    agreeRequest:function (id){
        return axios({
            url:"/tRequest/agreeRequest",
            method:"get",
            params:{
                id:id
            }
        });
    },
    refuseRequest:function (id){
        return axios({
            url:"/tRequest/refuseRequest",
            method:"get",
            params:{
                id:id
            }
        });
    },
    getVerifyCode:function (){
        return axios({
            url:"/tRequest/getVerifyCode",
            method:"get",
            params:{

            }
        });
    },
    applyTenant:function (data){
        return axios({
            url:"/tRequest/applyTenant",
            method:"post",
            data:qs.stringify(data)
        })
    },

    fileUpload:function (data){
        return axios({
            url:"/file/upload",
            method:"post",
            /*headers: { 'content-type': 'application/x-www-form-urlencoded' },*/
            data:data
        })
    }

}

export default api_tenant;