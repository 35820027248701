import {axios} from "@/framework/utils/request";
import qs from "qs";        //npm install qs

const api_user = {
    /**
     * 登录校验
     * @param data:Object 传递的实体参数对象
     * @returns {AxiosPromise}
     */
    verifyLogin:function (data){
        return axios({
            url: "/login/verifyLogin",
            method: "post",
            data: qs.stringify(data)
        });
    },
    getUser:function (id){
        return axios({
            url:"/user/getUser",
            method:"get",
            params:{
                id:id
            }
        });
    },

    updateUser:function (data){
        return axios({
            url:"/user/updateUser",
            method:"post",
            data:qs.stringify(data)
        });
    },
    updateUserSelf:function (data){
        return axios({
            url:"/user/updateUserSelf",
            method:"post",
            data:qs.stringify(data)
        });
    },
    updateUserPwd:function (data){
        return axios({
            url:"/user/updateUserPwd",
            method:"post",
            data:qs.stringify(data)
        });
    },
    forceUpdateUserPwd:function (data){
        return axios({
            url:"/user/forceUpdateUserPwd",
            method:"post",
            data:qs.stringify(data)
        });
    },

    saveUser:function (data){
        return axios({
            url:"/user/saveUser",
            method:"post",
            data:qs.stringify(data)
        });
    },

    deleteUser:function (id){
        return axios({
            url:"/user/deleteUser",
            method:"get",
            params:{
                id:id
            }
        });
    },

    findAllUser:function (data){
        return axios({
            url:"/user/findAllUser",
            method:"post",
            data:qs.stringify(data)
        })
    },
    findAllVisitor:function (data){
        return axios({
            url:"/user/findAllVisitor",
            method:"post",
            data:qs.stringify(data)
        })
    },
    findAllUserByTenantId:function (tenantId){
        return axios({
            url:"/user/findAllUserByTenantId",
            method:"get",
            params:{
                tenantId:tenantId
            }
        });
    },
    findAllReceiverUser:function (){
        return axios({
            url:"/user/findAllReceiverUser",
            method:"get",
            params:{
            }
        });
    },
    findAllUserByDeptId:(deptId)=>{
        return axios({
            url:"/user/findAllUserByDeptId",
            method:"get",
            params:{
                deptId:deptId
            }
        });
    },
    findAllUserOfTenant:()=>{
        return axios({
            url:"/user/findAllUserOfTenant",
            method:"get",
            params:{

            }
        });
    },
    heart:()=>{
        return axios({
            url:"/user/heart",
            method:"get",
            params:{
            }
        });
    },
    getUserLngLat:(uid)=>{
        return axios({
            url:"/user/getUserLngLat",
            method:"get",
            params:{
                uid:uid
            }
        });
    },
}

export default api_user;
