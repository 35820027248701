import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_kbsItem = {
    listKbsItem:function (data){
        return axios({
            url:"/kbsItem/listKbsItem",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
    listApprovalPending:function (data){
        return axios({
            url:"/kbsItem/listApprovalPending",
            method:"post",
            data:qs.stringify(data)
        })
    },
    listKbsMyCreated:function (data){
        return axios({
            url:"/kbsItem/listKbsMyCreated",
            method:"post",
            data:qs.stringify(data)
        })
    },
    createKbsItem:function (data){
        return axios({
            url:"/kbsItem/createKbsItem",
            method:"post",
            data:data
        })
    },
    updateKbsItem:function (data){
        return axios({
            url:"/kbsItem/updateKbsItem",
            method:"post",
            data:data
        })
    },
    deleteKbsItem:(kbsRecordId)=>{
        return axios({
            url:"/kbsItem/deleteKbsItem",
            method:"get",
            params:{
                kbsRecordId:kbsRecordId
            }
        })
    },
    getKbsItem:(kbsRecordId)=>{
        return axios({
            url:"/kbsItem/getKbsItem",
            method:"get",
            params:{
                kbsRecordId:kbsRecordId
            }
        })
    },
    getKbsApprovalFlow:(id)=>{
        return axios({
            url:"/kbsItem/getKbsApprovalFlow",
            method:"get",
            params:{
                id:id
            }
        })
    },
    setKbsApprovalPassed:(kbsRecordId)=>{
        return axios({
            url:"/kbsItem/setKbsApprovalPassed",
            method:"get",
            params:{
                kbsRecordId:kbsRecordId
            }
        })
    },
    setKbsApprovalRejected:function (data){
        return axios({
            url:"/kbsItem/setKbsApprovalRejected",
            method:"post",
            data:qs.stringify(data)
        })
    },

}

export default api_kbsItem;