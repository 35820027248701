<template>
  <div style="display: flex;width: 100%">
    <div style="">
      <a-space :size="5" align="center">

        <!-- 面包屑导航 start -->
        <a-breadcrumb :routes="breadcrumbArr">
          <template #itemRender="{ route }">
            <template v-if="route.meta.menuKey != 0">
            <span v-if="breadcrumbArr.indexOf(route) === breadcrumbArr.length - 1">
              {{ route.meta.title }}
            </span>
            <span v-else-if="route?.children?.length > 0">
              {{ route.meta.title }}
            </span>
            <router-link v-else :to="route.path">
              {{ route.meta.title }}
            </router-link>
          </template>
          </template>
        </a-breadcrumb>
        <!-- 面包屑导航 end -->
      </a-space>
    </div>

    <div style="flex: 1;text-align: center;">

    </div>

    <div style="padding-right: 15px;">
      <a-space :size="20" align="center">
        <!-- 项目选择 -->
<!--        <ProjectSelect></ProjectSelect>-->
        <!-- 大屏展示 -->
        <a @click="()=>{router().push('/monitorPage')}">
          <a-tooltip title="大屏展示" :mouseEnterDelay="0.5">
            <fund-projection-screen-outlined style="font-size: 18px"/>
          </a-tooltip>
        </a>
        <!-- 告警按钮 -->
        <a-popover v-model:visible="visible_inform" placement="bottom" trigger="click" >
          <template #content>
            <a-spin :spinning="load_infom">
            <a-list item-layout="horizontal" size="small" :data-source="dataSourceMessage" style="width: 300px;">
              <template #renderItem="{ item }">
                <a @click="viewMessageInfo(item.id)">
                  <a-list-item>
                    <a-list-item-meta :description="item.title">
                      <template #title>
                        <a>
                          <template v-if="item.type === Constant.MESSAGE_TYPE_WORKORDER">工单消息</template>
                          <template v-else-if="item.type === Constant.MESSAGE_TYPE_ASSET">资产消息</template>
                        </a>
                        <span style="padding-left: 10px;font-weight: normal;color: rgba(0, 0, 0, 0.45);">{{item.createDate}}</span>
                      </template>
                      <template #avatar>
                        <a-avatar style="background-color: #87d068">
                          <template #icon><UserOutlined /></template>
                        </a-avatar>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </a>
              </template>
              <template #loadMore>
                <div :style="{ textAlign: 'center', marginTop: '0px', height: '25px', lineHeight: '25px' }">
                  <a-button v-if="unReadMessageCount > setShowMessagCount" @click="toMoreMessage" type="link">更多</a-button>
                </div>
              </template>
            </a-list>
            </a-spin>
          </template>
          <template #title>
            <div style="text-align: center;height: 20px;line-height: 20px;">未读消息</div>
          </template>
          <a @click="showInform">
            <a-badge :count="unReadMessageCount">
              <a><AlertOutlined style="font-size: 18px"/></a>
            </a-badge>
          </a>
        </a-popover>
        <!-- 刷新 -->
        <a @click="onReload">
          <a-tooltip title="刷新" :mouseEnterDelay="0.5">
            <reload-outlined :spin="isClickReload" style="font-size: 18px"/>
          </a-tooltip>
        </a>
        <!-- 全屏按钮 -->
        <a @click="toggleFullScreen">
            <a-tooltip v-if="isFullScreen" title="关闭全屏" :mouseEnterDelay="0.5">
              <fullscreen-exit-outlined style="font-size: 18px"/>
            </a-tooltip>
            <a-tooltip v-else title="全屏" :mouseEnterDelay="0.5">
              <FullscreenOutlined style="font-size: 18px"/>
            </a-tooltip>
        </a>
        <!-- 用户名-->
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            <a-avatar size="small" style="background-color: #87d068;margin-bottom: 4px;">
              {{userStore.name}}
            </a-avatar>
            {{ userStore.name !== null && userStore.name !== "" ? userStore.name : userStore.phone }}
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <template #icon>
                  <UserOutlined />
                </template>
                <a @click="()=>{router().push('/personal/myInfo')}">个人中心</a>
              </a-menu-item>
              <a-menu-item>
                <template #icon>
                  <LogoutOutlined />
                </template>
                <a href="javascript:;" @click="logout">退出登录</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-space>
    </div>

    <!-- 点击未读消息item弹窗详细信息 -->
    <ViewMessageDialog ref="viewMessageDialogRef" @callback="callback_viewMessageDialog"/>

  </div>
</template>

<script>
import {DownOutlined,UserOutlined,LogoutOutlined,AlertOutlined,FullscreenOutlined,FullscreenExitOutlined,ExclamationCircleOutlined,
  ReloadOutlined,FundProjectionScreenOutlined  } from '@ant-design/icons-vue';
import {onMounted, ref, watch, createVNode, defineAsyncComponent, inject, h} from 'vue';
import {router, routes} from '@/router';
import { useRoute } from 'vue-router';
import screenFull from 'screenfull';
import {Button, message, Modal, notification} from "ant-design-vue";
import {useUserStore} from "@/framework/store/UserStore";
import {useNavTagsStore} from "@/framework/store/NavTagsStore";
import api_inform from "@/api/inform";
import {Constant} from "@/js/common";

/** 异步加载组件 **/
/*const ProjectSelect = defineAsyncComponent(() =>{
  return import('@/components/_projectSelect')
})*/
const ViewMessageDialog = defineAsyncComponent(()=>{
  return import('@/components/_viewMessageDialog.vue');
})

export default {
  methods: {
    router() {
      return router
    }
  },
  components: {
    DownOutlined,
    UserOutlined,
    LogoutOutlined,
    AlertOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ReloadOutlined,
    FundProjectionScreenOutlined,
    ViewMessageDialog
  },
  setup() {
    //const reload_app = inject("reload_app");  //通过reload()去调用app.vue中provide("reload_app")方法刷新整体
    const route = useRoute();
    const breadcrumbArr = ref([]);
    const isFullScreen = ref(false);
    const userStore = useUserStore();
    const navTagsStore = useNavTagsStore();

    const isClickReload = ref(false);
    const load_infom = ref(false);
    const visible_inform = ref(false);
    const setShowMessagCount = 5;             //设置弹窗中可以显示的条数
    const unReadMessageCount = ref(0);  //未读消息总数
    const dataSourceMessage = ref([]);
    const viewMessageDialogRef = ref();

    onMounted(()=>{
      setBreadcrumbArr();
      showInform();
      /** 监听全屏改变事件 **/
      screenFull.onchange(function (){
        isFullScreen.value = screenFull.isFullscreen;
      })
    })

    /** 监听路由变化，显示当前位置指示 **/
    watch(()=>{return route.matched},()=>{
      //console.log("watch ---- in");
      //console.log("watch ---- ",route.matched);
      setBreadcrumbArr();
    })
    let setBreadcrumbArr = function (){
      breadcrumbArr.value = [];
      if (route.matched){
        route.matched.forEach((item,index)=>{
          //console.log("watch ---- item -- ",item,index);
          if (index !== 0){
            breadcrumbArr.value.push(item);
          }
        });
      }
    }
    /** 全屏开关动作 **/
    let toggleFullScreen = function (){
      if (screenFull.isEnabled){
        screenFull.toggle();
      }
    }
    /**
     * 刷新整体页面
     */
    const onReload = ()=>{
      isClickReload.value = true;
      window.location.reload(true);
      //reload_app();
    }

    /**
     * 获取通知信息数据
     */
    const showInform = ()=>{
      load_infom.value = true;
      const params = {status:Constant.MESSAGE_STATUS_UNREAD}
      params.page = 1;
      params.rows = setShowMessagCount;
      api_inform.showAllMessage(params).then(res=>{
        if (res?.data?.code === 0) {
          dataSourceMessage.value = [];
          const list = res.data.data.list;
          if (list){
            const _total = res.data.data.total;
            unReadMessageCount.value = _total;
            list.forEach((item)=>{
              const ent = {id:item.id,type:item.type,title:item.title,status:item.status,createDate:item.createDate}
              dataSourceMessage.value.push(ent);
            });
          }
        }else {
          if (res?.data?.msg) {
            message.error(res?.data?.msg + " " + res?.data?.data, 5);
          }
        }
        load_infom.value = false;
      });
    }
    /**
     * 点击未读消息item弹窗详细信息
     * @param id message id
     */
    const viewMessageInfo = (id)=>{
      viewMessageDialogRef.value.show(id);
    }
    /**
     * 点击更多消息后跳转
     */
    const toMoreMessage = ()=>{
      router.push("/inform/message");
      visible_inform.value = false;
    }
    /**
     * 弹窗查看消息后回调
     * @param msgId message id
     * @param flg 是否数据加载成功
     */
    const callback_viewMessageDialog = (msgId,flg)=>{
      if (flg){
        visible_inform.value = false;
        notification.close(msgId);      //同时关闭该通知的弹窗
        showInform();
      }else{
        message.error("消息查看出错了！",5);
      }
    }

    /** logout **/
    let logout = function (){
      Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '您确定要退出系统吗?',
        okText: '确定',
        cancelText: '取消',

        onOk() {
          /**
           * 登出处理
           * @returns {Promise<void>}
           */
          const doLogoutInfo = async ()=>{
            //清除历史的userStore信息
            await userStore.logout().then(()=>{
            }).catch(err=>{
              message.error(err.message,5);
            });
            //清除历史的navTagsStore信息
            await navTagsStore.clearAllDate().then(()=>{
            }).catch(err=>{
              message.error(err.message,5);
            });
            router.push("/login");
          }
          doLogoutInfo();
        },

        onCancel() {

        },

        class: 'test',
      });
    }

      /**  监听websocket 的消息  **/
    const injectWsData = inject("ws-data");
    watch(()=>{return injectWsData},()=>{
        //console.log("========watch: ",injectWsData.value)
        let isShowNotification = false;
        if (injectWsData.value.code === Constant.WEBSOCKET_INFORM_CODE_WORKORDER_STATUS_CHANGEED){
          isShowNotification = true;
          showInform();
        }else if (injectWsData.value.code === Constant.WEBSOCKET_INFORM_CODE_ASSET_STATUS_CHANGE){
          isShowNotification = true;
          showInform();
        } else if (injectWsData.value.code === Constant.WEBSOCKET_INFORM_CODE_MESSAGE_STATUS_CHANGE){
          showInform();
        }
        /** 是否右下角弹窗显示 **/
        if (isShowNotification){
          if (!injectWsData.value.data){return;}
          let title = "";
          if (injectWsData.value.data.type === Constant.MESSAGE_TYPE_WORKORDER){
            title = "工单消息";
          }else if (injectWsData.value.data.type === Constant.MESSAGE_TYPE_ASSET){
            title = "资产消息";
          }
          openNotifucation(injectWsData.value.data.id,title);
        }
      },{deep:true})

    const openNotifucation = (key,title)=>{
      notification.open({
        key:injectWsData.value.data.id,
        message: title,
        description: injectWsData.value.data.title,
        placement:'bottomRight',
        duration: 0,
        btn: () => h(Button, {
          type: 'primary',
          size: 'small',
          onClick: () => {
            viewMessageInfo(key);
          },
        }, {
          default: () => '查看',
        }),
      });
    }


    return {
      Constant,
      collapsed: ref(false),
      routes,
      userStore,
      navTagsStore,
      breadcrumbArr,
      isFullScreen,
      isClickReload,
      toggleFullScreen,
      unReadMessageCount,
      dataSourceMessage,
      viewMessageDialogRef,
      setShowMessagCount,
      toMoreMessage,
      visible_inform,
      load_infom,
      callback_viewMessageDialog,
      viewMessageInfo,
      showInform,
      onReload,
      logout,
    };
  },
}
</script>

<style scoped>
  a{
    color: #000000D9!important;
  }
  a:hover{
    color: #1890ff!important;
  }
</style>
