<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div class="block" style="" >
        <div style="display: flex;box-sizing:border-box;height: 60px;" >
          <div style="flex: 3;display: flex;align-items: center;padding-left: 10px;">
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item name="name">
                <a-input v-model:value="formSearchState.name" placeholder="用户名" size="default" />
              </a-form-item>
              <a-form-item name="phone">
                <a-input v-model:value="formSearchState.phone" placeholder="手机号" size="default" />
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div style="flex: 1;display: flex;align-items: center;">

          </div>
          <div style="flex: 2;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
            <a-space>
              <a-button  style="margin: 0px 0px;" type="primary" size="default" @click="showSetDialog"><template #icon><setting-outlined /></template>全局设置</a-button>
              <a-button v-permission="'visitor_roleSet'" style="margin: 0px 0px;" type="primary" size="default" @click="showAllotDialog"><template #icon><ProfileOutlined /></template>全局角色</a-button>
            </a-space>
          </div>
        </div>
        <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - search 50 - 表头高度40 - 分页高度52 -->
        <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :bordered="true"
            size="small"
            :loading="loading"
            :scroll="{ x: 1200, y: 'calc(100vh - 247px)' }"
        >
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'operation'">
              <div style="display: flex;justify-content: left;">
                <a-button v-permission="'visitor_delete'" v-if="record.type !== 1" style="margin: 0px 4px;" type="link" size="small" danger @click="deleteItem(record)"><template #icon><DeleteOutlined /></template><span style="margin-left:3px;">删除</span></a-button>
              </div>
            </template>
            <template v-else-if="column.dataIndex === 'property'">
              <a-tooltip title="工单处理人" v-if="record.isReceiver === Constant.USER_PROPERTY_ISRECEIVER"><a-tag  color="cyan">处理人</a-tag></a-tooltip>
              <a-tooltip title="工单指派人" v-if="record.isAssign === Constant.USER_PROPERTY_ISASSIGN_YES"><a-tag  color="cyan">指派人</a-tag></a-tooltip>
              <template v-else-if="record.isReceiver === 0">{{}}</template>
            </template>
          </template>
        </a-table>
        <div style="padding:10px;text-align: center">
          <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                        @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                        :page-size-options="['10','20','30','40','50']" show-size-changer>
            <template #buildOptionText="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>


      <!--    分配角色弹窗     -->
      <a-modal
          v-model:visible="allot_dialog_visible"
          ok-text="确认"
          cancel-text="取消"
          @ok="onCheckedItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <template #title>
          <div>访客全局角色分配</div>
          <div v-if="userRoleTreeData" style="color: #b6b6b6;font-size: 12px;margin-top: 5px;">给所有访客用户设置一个全局的角色</div>
        </template>

        <a-spin :spinning="loading_role">
          <a-alert v-if="!userRoleTreeData || userRoleTreeData.length <= 0" message="没有角色选择，请先到角色管理中创建角色。" banner />

          <a-tree
              v-model:expandedKeys="expandedKeys_role"
              v-model:selectedKeys="selectedKeys_role"
              v-model:checkedKeys="checkedKeys_role"
              checkable
              :tree-data="userRoleTreeData"
              :height="500"
              :auto-expand-parent="true"
          >
          </a-tree>
        </a-spin>
      </a-modal>

      <!--    设置弹窗     -->
      <a-modal
          v-model:visible="set_dialog_visible"
          ok-text="确认"
          cancel-text="取消"
          @ok="onSetItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <template #title>
          <div>访客全局设置</div>
          <div style="color: #b6b6b6;font-size: 12px;margin-top: 5px;">给所有访客用户设置一个全局的参数</div>
        </template>
        <a-spin :spinning="loading_set">
          <a-form ref="formSetRef" :model="formSetState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" layout="horizontal" name="set">
            <a-form-item label="默认用户名" name="defaultName" :rules="[{ required: false, message: '请输入默认用户名' ,trigger:'blur'},{max:50,message:'默认用户名字符长度超限',trigger:'blur'}]">
              <a-input v-model:value="formSetState.defaultName" size="default" :maxlength="50"/>
            </a-form-item>
            <a-form-item
                label="默认报障部门"
                name="reportDeptId"
                :rules="[{ required: false, message: '请选择默认报障部门' ,trigger:'blur'}]">
              <a-tree-select
                  v-model:value="formSetState.reportDeptId"
                  tree-data-simple-mode
                  tree-default-expandAll
                  show-search
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                  :tree-data="treeAllDeptData"
              >
              </a-tree-select>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>


    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, createVNode, reactive} from "vue";
import api_user from "@/framework/api/user";
import {message,Modal} from "ant-design-vue";
import {
  SearchOutlined, DeleteOutlined, ExclamationCircleOutlined, ProfileOutlined,SettingOutlined} from "@ant-design/icons-vue";
import {Constant} from "@/js/common";
import api_auth from "@/api/auth";
import api_dept from "@/api/dept";
import api_visitor from "@/api/visitor";

    const loading = ref(false);
    const loading_role = ref(false);
    const loading_set = ref(false);
    const allot_dialog_visible = ref(false);
    const set_dialog_visible = ref(false);
    const expandedKeys_role = ref([]);
    const selectedKeys_role = ref([]);
    const checkedKeys_role = ref([]);
    let userRoleTreeData = ref([]);
    let treeAllDeptData = ref([]);

    const formSearchRef = ref();
    const formSetRef = ref();

    const formSearchState = reactive({
      name: "",
      phone: "",
      isReceiver: '',
      deptId: ""
    });
    const formSetState = ref({
      defaultName:"",
      reportDeptId:""
    });
    let dataSource = ref([]);
    let columns= [
      {
        title: '序号',
        dataIndex: 'index',
        width:100,
        customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
      },{
        title: '用户名',
        dataIndex: 'name',
      },{
        title: '手机号',
        dataIndex: 'phone',
      },{
        title: '最近登录时间',
        dataIndex: 'nowLoginTime',
      },{
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width:300
      }
    ];


    let pagination = ref({
      current: 1,     //当前页
      pageSize: 15,  //每页条数
      total: 1,     //数据总数
      pageSizeOptions:['2','4','6'],
      showSizeChanger:true
    });
    /**
     * 清空searchData 数据
     */
    /*const clearSearchData = ()=>{
      formSearchState.name = "";
      formSearchState.phone = "";
      formSearchState.deptId = "";
    }*/
    /**
     * 查询所有用户
     * @param page 页数
     */
    const findAllUser = function (page){
      loading.value = true;
      formSearchState.page = page;
      formSearchState.rows = pagination.value.pageSize;
      //console.log("findAllUser searchData----",formSearchState);
      api_user.findAllVisitor(formSearchState).then(res=>{
        //console.log("res----",res);
        if(res?.data?.code === 0){
          dataSource.value = [];
          const userList = res.data.data.userList;
          const _total = res.data.data.total;
          pagination.value.total = _total;

          userList.forEach((user)=>{
            //console.log("user-->",user);
            dataSource.value.push({id:user.id,name:user.name,phone:user.phone,type:user.type,nowLoginTime:user.nowLoginTime})
          })
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }

    /**
     * 分页响应事件
     * @param page {@link pagination}
     */
    let onPageChange = function (page){
      findAllUser(page);
    }
    /**
     * 改变pageSize分页条数
     * @param current 当前页
     * @param size  分页条数
     */
    const onShowSizeChanger = function (current, size){
      pagination.value.current = current;
      pagination.value.pageSize = size;
    }
    /**
     * 跳到第一页
     */
    const toHomePage = ()=>{
      findAllUser(1);
      pagination.value.current = 1;
    }
    /**
     * 跳到最后页
     */
    /*const toEndPage = ()=>{
      findAllUser(getTotalPage());
      pagination.value.current = getTotalPage();
    }*/
    /**
     * 刷新当前页
     */
    const toCurrentPage = ()=>{
      findAllUser(pagination.value.current);
    }

    /**
     * 获取当前总页数
     */
    /*const getTotalPage =()=>{
      if (pagination.value.total === 0){
        return 1;
      }
      return pagination.value.total%pagination.value.pageSize === 0
          ? pagination.value.total/pagination.value.pageSize
          : parseInt(pagination.value.total/pagination.value.pageSize)+1;
    }*/

    onMounted(()=>{
      findAllUser(1);
    })


    /**
     * 删除一条记录
     * @param record 实体数据
     */
    const deleteItem = function (record){
      //console.log(record);
      Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
            api_user.deleteUser(record.id).then(res=>{
              if(res?.data?.code === 0){
                message.success("操作成功",3);
                resolve();
                toCurrentPage();
              }else{
                if (res?.data?.msg){
                  message.error(res?.data?.msg+" "+res?.data?.data,5);
                }
                reject();
              }
            });
          });
        },
        onCancel() {
        }
      });
    }
    /**
     * 搜索
     */
    const onSearch = ()=>{
      formSearchRef.value.validateFields().then(()=>{
        //console.log("onSearch  values ---",values);

        toHomePage();
      }).catch(()=>{
        //console.log("onSearch  info ---",info);
      });
    }

/**
 * 显示分配角色弹窗
 */
const showAllotDialog = ()=>{
  loading_role.value = true;
  allot_dialog_visible.value = true;

  expandedKeys_role.value = [];
  checkedKeys_role.value = [];
  selectedKeys_role.value = [];
  userRoleTreeData.value = [];
  api_auth.showAllotVisitorRole().then(res=>{
    if(res?.data?.code === 0){
      const dataList = res.data.data;
      if (dataList?.length > 0){
        dataList.forEach((role)=>{
          userRoleTreeData.value.push({title:role.name,key:role.id});
          if (role.checked === true){
            checkedKeys_role.value.push(role.id);
          }
        });
      }
    }else{
      if (res?.data?.msg){
        message.error(res?.data?.msg+" "+res?.data?.data,5);
      }
    }
    loading_role.value = false;
  });
}
/**
 * 处理选择角色的操作
 */
const onCheckedItemOK = ()=>{
  loading_role.value = true;
  let data = {roleIds:checkedKeys_role.value};
  api_auth.doAllotVisitorRole(data).then(res=>{
    if(res?.data?.code === 0){
      message.success("操作成功",3);
      allot_dialog_visible.value = false;
    }else{
      if (res?.data?.msg){
        message.error(res?.data?.msg+" "+res?.data?.data,5);
      }
    }
    loading_role.value = false;
  })
}
/**
 * 显示全局设置弹窗
 */
const showSetDialog = ()=>{
  set_dialog_visible.value = true;
  const asyncFun = async ()=>{
    loading_set.value = true;
    await api_visitor.getVisitorSet().then(res=>{
      if(res?.data?.code === 0){
        const item = res.data.data;
        if (item){
          formSetState.value.defaultName = item.defaultName;
          formSetState.value.reportDeptId = item.reportDeptId;
        }
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
    })
    await api_dept.findAllDept({}).then(res=> {
      //console.log("res----",res);
      treeAllDeptData.value = [];
      if (res?.data?.code === 0) {
        const list = res.data.data.list;
        if (list && !list.isEmpty) {
          for (let item of list) {
            treeAllDeptData.value.push({id: item.id, pId: item.parentId, value: item.id, title: item.name,})
          }
        }
      } else {
        if (res?.data?.msg) {
          message.error(res?.data?.msg + " " + res?.data?.data, 5);
        }
      }
    });
    loading_set.value = false;
  };
  asyncFun();
}
/**
 * 保存设置
 */
const onSetItemOK = ()=>{
  loading_set.value = true;
  formSetRef.value.validateFields().then(values=>{
    api_visitor.editVisitorSet(values).then(res=>{
      if(res?.data?.code === 0){
        message.success("设置成功",3);
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
      loading_set.value = false;
    })
  });

}

</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
