<template>
  <div class="loginDiv">
    <div style="color:#000000D9;font-size: 25px;font-weight: bold;margin-bottom: 25px;text-align: center;">账户登录</div>
    <a-form
        :model="formState"
        name="normal_login"
        class="login-form"
        @finish="onFinish"
    >
      <a-form-item
          name="phone"
          :rules="[{ required: true, message: '请输入手机号',trigger:'blur' },{pattern:/^(1[0-9]{10}|[root]{4})$/,message:'手机号不符合规则',trigger:'blur'}]"
      >
        <a-input v-model:value="formState.phone" size="large" ref="phoneDom" placeholder="手机号">
          <template #prefix>
            <UserOutlined class="site-form-item-icon" />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item
          name="pwd"
          :rules="[{ required: true, message: '请输入密码' },{pattern:/^(\w){6,11}$/,message:'密码只能以大小写字母、数字和下划线组合长度6~11位',trigger:'blur'}]"
      >
        <a-input-password v-model:value="formState.pwd" size="large" placeholder="密 码">
          <template #prefix>
            <LockOutlined class="site-form-item-icon" />
          </template>
        </a-input-password>
      </a-form-item>


      <a-form-item>
        <a-button type="primary" html-type="submit" class="login-form-button" size="large" :loading="iconLoading" block>
          登录
        </a-button>
      </a-form-item>

      <!--          <a-alert type="error" v-if="resErr!=''" :message="resErr" banner style="color: #ff4d4f" />-->
    </a-form>

    <div><a href="javascript:void(0)" @click="changeCmp()">平台申请</a></div>

  </div>
</template>

<script>
import {reactive, ref, onMounted} from 'vue';
import {LockOutlined, UserOutlined} from "@ant-design/icons-vue";
import {useUserStore} from "@/framework/store/UserStore";
import api_user from "@/framework/api/user";
import {router} from "@/router";
import {message} from "ant-design-vue";

export default {
  name: "_login",
  components: {
    UserOutlined,
    LockOutlined,
  },
  emits:['changeCmp'],
  setup(props,{emit}) {

    const iconLoading = ref(false); //登录按钮的加载动画图标是否显示
    const phoneDom = ref(null);     //ant input 获取焦点
    const userStore = useUserStore();

    const formState = reactive({
      phone: '',
      pwd: ''
    });
    /** 提交表单且数据验证成功后回调事件 **/
    const onFinish = values => {
      iconLoading.value = true;
      /** axios 登录请求 **/
      api_user.verifyLogin(values).then(res=>{
        //console.log('Success:', res);
        if(res?.data?.code === 0){
          /** 登录成功后同时将用户相关信息存储到store中 **/
          userStore.saveUser(res.data.data).then(()=>{
            router.push("/");
          }).catch(err=>{
            iconLoading.value = false;
            message.error(err.message,5);
          })

        }else{
          iconLoading.value = false;
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }

      });
    };

    const changeCmp = ()=>{
      emit("changeCmp","_register");
    }

    onMounted(()=>{
      phoneDom.value.focus();
    })

    return {
      formState,
      onFinish,
      changeCmp,
      phoneDom,
      iconLoading
    };
  },

  directives:{
    focus:{
      mounted(el) {
        el.focus();
      }
    }
  }

}
</script>

<style scoped>
  .loginDiv{
    width: 100%;
    min-width: 300px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 15px #d0cece;
    padding: 30px;
    z-index: 1;
    margin: auto;
  }
</style>