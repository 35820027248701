<template>
  <div ref="container" style="height:100%;position: relative;overflow: hidden">
    <div style="background-color: #fff;">
      <div class="tableHeaderContainer" >
        <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
          功能操作
        </div>
        <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
          <a-button v-permission="'auth_addOperation'" style="margin: 0px 0px;" type="primary" size="small" @click="showAddItemDialog()"><template #icon><PlusOutlined /></template>添加权限</a-button>
        </div>
      </div>
      <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - tableHeaderContainer 40 - 表头高度40 - 分页高度52 -->
      <a-table
          class="ant-table-striped"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :bordered="true"
          size="small"
          :loading="loading"
          :scroll="{ y: 'calc(100vh - 227px)' }"
      >
        <template #bodyCell="{ column,record }">
          <template v-if="column.dataIndex === 'operation'">
            <div style="display: flex;justify-content: left">
              <a-button v-permission="'auth_updateOperation'" style="margin-left: 5px;" type="link" size="small" @click="showEditItemDialog(record)"><template #icon><EditOutlined /></template><span style="margin-left:3px;">编辑</span></a-button>
              <a-button v-permission="'auth_deleteOperation'" style="margin-left: 5px;" type="link" size="small" danger @click="deleteItem(record)"><template #icon><DeleteOutlined /></template><span style="margin-left:3px;">删除</span></a-button>
            </div>
          </template>
        </template>
      </a-table>

      <div style="padding:10px;text-align: center">

      </div>



      <!--    编辑用户弹窗     -->
      <a-modal
          v-model:visible="editItem_dialog_visible"
          title="编辑权限"
          ok-text="确认"
          cancel-text="取消"
          @ok="onUpdateItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formEditRef" :model="formEditState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" autocomplete="off" name="add">
            <a-form-item
                label="所属节点"
                name="parentId"
                :rules="[{ required: true, message: '请选择所属节点' ,trigger:'blur'}]">
              <a-tree-select
                  v-model:value="formEditState.parentId"
                  show-search
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                  :tree-data="treeSelectDataSource"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item
                label="名称"
                name="name"
                :rules="[{ required: true, message: '请输入名称' ,trigger:'blur'},{max:50,message:'名称字数长度超限',trigger:'blur'}]"
            >
              <a-input v-model:value="formEditState.name" size="default" />
            </a-form-item>
            <a-form-item
                label="编码"
                name="code"
                :rules="[{ required: true, message: '请输入编码',trigger:'blur' },{pattern:/^(\w){1,50}$/,message:'编码只能以大小写字母、数字和下划线组合长度1~50位',trigger:'blur'}]"
            >
              <a-input v-model:value="formEditState.code" size="default" />
            </a-form-item>
            <a-form-item
                label="uri"
                name="uri"
                :rules="[{ required: true, message: '请输入uri',trigger:'blur' },{pattern:/^[\w/]{1,100}$/,message:'编码只能以大小写字母、数字、斜杠和下划线组合长度1~100位',trigger:'blur'}]"
            >
              <a-input v-model:value="formEditState.uri" size="default" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    添加弹窗     -->
      <a-modal
          v-model:visible="addItem_dialog_visible"
          title="添加权限"
          ok-text="确认"
          cancel-text="取消"
          @ok="onSaveItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formAddRef" :model="formAddState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" autocomplete="off" name="add">
            <a-form-item
                label="所属节点"
                name="parentId"
                :rules="[{ required: true, message: '请选择所属节点' ,trigger:'blur'}]">
              <a-tree-select
                  v-model:value="formAddState.parentId"
                  show-search
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                  :tree-data="treeSelectDataSource"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item
                label="名称"
                name="name"
                :rules="[{ required: true, message: '请输入名称' ,trigger:'blur'},{max:50,message:'名称字数长度超限',trigger:'blur'}]"
            >
              <a-input v-model:value="formAddState.name" size="default" />
            </a-form-item>
            <a-form-item
                label="编码"
                name="code"
                :rules="[{ required: true, message: '请输入编码',trigger:'blur' },{pattern:/^(\w){1,50}$/,message:'编码只能以大小写字母、数字和下划线组合长度1~50位',trigger:'blur'}]"
            >
              <a-input v-model:value="formAddState.code" size="default" />
            </a-form-item>
            <a-form-item
                label="uri"
                name="uri"
                :rules="[{ required: true, message: '请输入uri',trigger:'blur' },{pattern:/^[\w/]{1,100}$/,message:'编码只能以大小写字母、数字、斜杠和下划线组合长度1~100位',trigger:'blur'}]"
            >
              <a-input v-model:value="formAddState.uri" size="default" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

    </div>
  </div>
</template>

<script>
import {onMounted, ref, createVNode, reactive} from "vue";
import api_auth from "@/api/auth";
import {message,Modal} from "ant-design-vue";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";


export default {
  components: {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
  },
  setup(){
    const loading = ref(false);
    let formAddRef = ref();
    const formEditRef = ref();
    const editItem_dialog_visible = ref(false);
    const addItem_dialog_visible = ref(false);
    const allot_dialog_visible = ref(false);
    let isShowNullDataMsg = ref(false);

    const operationTreeData = ref([]);

    const formAddState = reactive({
      name: '',
      code: '',
      uri: '',
      parentId: ''
    });
    const formEditState = reactive({
      name: '',
      code: '',
      uri: '',
      parentId: ''
    });
    let dataSource = ref([]);
    let treeSelectDataSource = ref([{title:'根节点',value:0,children:[]}]);
    let columns= [
      {
        title: '名称',
        dataIndex: 'name',
      },{
        title: '编码',
        dataIndex: 'code',
      },{
        title: 'URI',
        dataIndex: 'uri',
      },{
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width:300
      }
    ];


    /**
     * 查询所有用户
     * @param page 开始的页数
     */
    const findAllItem = function (){
      loading.value = true;
      //console.log("findAllItem searchData----",formSearchState);
      api_auth.findAllOperation({}).then(res=>{
        //console.log("res----",res);
        if(res?.data?.code === 0){
          dataSource.value = [];
          const list = res.data.data.list;
          if (list){
            const newList = convert(list);
            dataSource.value = newList;
            treeSelectDataSource.value[0].children = newList;
          }
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }

    /**
     * 刷新当前页
     */
    const toCurrentPage = ()=>{
      findAllItem();
    }

    onMounted(()=>{
      findAllItem();

    })
    /**
     * 设置修改时选择的节点在树及子中禁用不可选
     * @param list 数组对象
     * @param val 要禁用的节点value值
     * @param flg boolean
     */
    const setTreeNodeDisable = (list,val,flg=false)=>{
      list.forEach((node)=>{
        let value = node.value;
        node.disabled = flg;
        let isDisabled = flg;
        if (value === val){
          isDisabled = true;
          node.disabled = isDisabled;
        }
        if (node?.children?.length > 0){
          setTreeNodeDisable(node.children,val,isDisabled);
        }
      });
    }
    /**
     * 显示修改记录弹窗
     * @param record 实体数据
     */
    const showEditItemDialog = record =>{
      editItem_dialog_visible.value = true;
      loading.value = true;
      api_auth.getOperation(record.id).then(res=>{
        if(res?.data?.code === 0){
          formEditState.id = res.data.data.id;
          formEditState.name = res.data.data.name;
          formEditState.code = res.data.data.code;
          formEditState.uri = res.data.data.uri;
          formEditState.parentId = res.data.data.parentId;

          setTreeNodeDisable(treeSelectDataSource.value,formEditState.id);
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });
    }
    /**
     * 点击修改记录弹窗->确认按钮
     */
    const onUpdateItemOK = function (){
      formEditRef.value.validateFields().then(values=>{
        loading.value = true;
        values.id = formEditState.id;
        api_auth.updateOperation(values).then(res=>{
          if(res?.data?.code === 0){
            message.success("操作成功",3);
            editItem_dialog_visible.value = false;
            toCurrentPage();
          }else{
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }
          }
          loading.value = false;
        })
      }).catch(()=>{
        //console.log("onUpdateItemOK  info ---",info);
      });
    }
    /**
     * 显示添加用户弹窗
     */
    const showAddItemDialog = () =>{
      addItem_dialog_visible.value = true;

    }
    /**
     * 添加弹窗->确认
     */
    const onSaveItemOK = () =>{
      formAddRef.value.validateFields().then(values=>{
        loading.value = true;
        //console.log("onSaveItemOK  values ---",values);
        api_auth.saveOperation(values).then(res=>{
          if(res?.data?.code === 0){
            message.success("操作成功",3);
            addItem_dialog_visible.value = false;
            toCurrentPage();
          }else{
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }
          }
          loading.value = false;
        })
      }).catch(()=>{
        //console.log("onSaveItemOK  info ---",info);
      });
    }

    /**
     * 删除一条记录
     * @param record 实体数据
     */
    const deleteItem = function (record){
      //console.log(record);
      Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '是否要删除此条记录?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
            api_auth.deleteOperation(record.id).then(res=>{
              if(res?.data?.code === 0){
                message.success("操作成功",3);
                resolve();
                toCurrentPage();
              }else{
                if (res?.data?.msg){
                  message.error(res?.data?.msg+" "+res?.data?.data,5);
                }
                reject();
              }
            });
          }).catch(() => {});
        },
        onCancel() {
        }
      });
    }


    /**
     * 转换成树型控件所需的data格式
     * @param rows 对象数组
     * @returns {*[]}
     */
    const convert = function(rows){
      function exists(rows, parentId){
        for(let i=0; i<rows.length; i++){
          if (rows[i].id == parentId) return true;
        }
        return false;
      }

      let nodes = [];
      // get the top level nodes
      for(let i=0; i<rows.length; i++){
        let row1 = rows[i];
        if (!exists(rows, row1.parentId)){
          nodes.push({
            key:row1.id,
            name:row1.name,
            code:row1.code,
            uri:row1.uri,
            id:row1.id,
            title:row1.name,  //转换成树型输入框控件所需的data格式所需的属性，使用在添加和编辑中选择所属节点下拉树
            value:row1.id,    //转换成树型输入框控件所需的data格式所需的属性
          });

        }
      }

      let toDo = [];
      for(let i=0; i<nodes.length; i++){
        toDo.push(nodes[i]);
      }

      while(toDo.length){
        let node = toDo.shift();    // the parent node
        // get the children nodes
        for(let i=0; i<rows.length; i++){
          let row = rows[i];
          if (row.parentId == node.key){
            let child = {key:row.id,name:row.name,code:row.code,uri:row.uri,id:row.id,title:row.name,value:row.id};
            if (node.children){
              node.children.push(child);
            } else {
              node.children = [child];
            }

            toDo.push(child);

          }
        }
      }
      return nodes;
    }

    return{
      columns,
      dataSource,
      treeSelectDataSource,
      formAddRef,
      formEditRef,
      formAddState,
      formEditState,
      editItem_dialog_visible,
      addItem_dialog_visible,
      showEditItemDialog,
      showAddItemDialog,
      onUpdateItemOK,
      onSaveItemOK,
      deleteItem,
      allot_dialog_visible,
      isShowNullDataMsg,
      operationTreeData,
      loading
    }
  }

}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.tableHeaderContainer{
  display: flex;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #fafafa;
  background-color: #ffffff;
}

</style>
