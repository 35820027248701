<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div  class="block" style="display: flex;padding: 15px 10px;" >
        <div style="flex: 1;">
          <a-space>
            统计范围：
            <a-date-picker v-model:value="statementState.startTime" placeholder="开始日期" format="YYYY-MM-DD HH:mm:ss" show-time :locale="locale" :disabled-date="startDisabledDate" size="default"/>
            <a-date-picker v-model:value="statementState.endTime" placeholder="结束日期" format="YYYY-MM-DD HH:mm:ss" show-time :locale="locale" :disabled-date="endDisabledDate" size="default"/>
            <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>查询</a-button>
          </a-space>
        </div>
        <div style="background-color: #ffe4b2">

        </div>
      </div>

      <a-divider style="height: 10px;margin: 0px 0;"/>

      <div class="block" style="display: flex;justify-content:center;padding: 10px 10px;" >

      </div>

      <div class="block" style="padding: 10px 10px;" >
        <div class="statement_chart_box">
          <div><a-typography-title :level="5"><fund-two-tone /><span style="margin-left: 5px;">资产趋势图表</span></a-typography-title></div>
          <div id="woChart_id" style="width: 100%;height: 500px;"></div>
        </div>
      </div>

      <div class="block" style="padding: 10px 10px;" >
          <div class="statement_chart_box">
              <div><a-typography-title :level="5"><fund-two-tone /><span style="margin-left: 5px;">资产故障类型趋势图表</span></a-typography-title></div>
              <div id="ftChart_id" style="width: 100%;height: 500px;"></div>
          </div>
      </div>

      <div class="block" style="padding: 10px 10px;" >
        <div class="statement_chart_box">
          <div style="display: flex;">
            <div style="flex: 2;"><a-typography-title :level="5"><profile-two-tone /><span style="margin-left: 5px;">资产趋势列表</span></a-typography-title></div>
            <div style="flex: 1;text-align: end"><a-button type="link" @click="exportToExcelOfWorkOrder"><file-excel-outlined />导出到Excel</a-button></div>
          </div>
          <div style="width: 100%;">
            <a-table
                class="ant-table-striped"
                :columns="columns"
                :data-source="dataSource"
                :pagination="false"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :bordered="true"
                size="small"
                :loading="loading_list"
                :scroll="{ x: 1200, y: 'calc(100vh - 227px)' }"
            >
              <template #bodyCell="{ column,record }">
                <template v-if="column.dataIndex === 'faultTypes'">
                  <template v-for="item in record.faultTypes" :key="item">
                    <a-tooltip :title="item.faultTypeName +' ('+item.count+'次)'" placement="topLeft"><a-badge status="default" />{{item.faultTypeName}} ({{item.count}}次)</a-tooltip> <br/>
                  </template>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script setup>

import {onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
import api_statement from "@/api/statement";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import {SearchOutlined,FundTwoTone,ProfileTwoTone,FileExcelOutlined} from "@ant-design/icons-vue";

import * as echarts from 'echarts';
import {message} from "ant-design-vue";
import clone from "clone";


const loading_list = ref(false);
const woChart_id = "woChart_id";
const ftChart_id = "ftChart_id";
let woChart;
let ftChart;
const statementData = ref([])

const statementState = ref({
  startTime: '',
  endTime: '',
});

let dataSource = ref([]);
let columns= [
  {
    title: '序号',
    dataIndex: 'index',
    width:80,
    customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
  },{
    title: '名称',
    dataIndex: 'assetName',

  },{
    title: '维修次数',
    dataIndex: 'repairedCount',

  },{
    title: '变更次数',
    dataIndex: 'changeCount',

  },{
    title: '故障类型',
    dataIndex: 'faultTypes',
    ellipsis: true,
    width:300
  }
];
let pagination = ref({
  current: 1,     //当前页
  pageSize: 10,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});


onMounted(()=>{
  loadWoChart();
  loadFtChart();

  window.addEventListener("resize",setResize);
})

onUnmounted(()=>{
  window.removeEventListener("resize",setResize)
})

const setResize = ()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    chartDom.resize();
  }
  let ftDom = echarts.getInstanceByDom(document.getElementById(ftChart_id));
  if (ftDom){
    ftDom.resize();
  }
}

/**
 * 搜索
 */
const onSearch = ()=>{
  statementData.value = [];
  doAllStatementOfWo();
}
/**
 * 导出到Excel
 */
const exportToExcelOfWorkOrder = ()=>{
  const etew = message.loading('正在导出到Excel...', 0);
  let params = doSearchFormData();
  api_statement.exportToExcelOfAsset(params).then(res=>{
    if (res?.data.size) {
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(res.data); // 创建下载的链接
      downloadElement.href = href;
      downloadElement.download = decodeURI("资产报表统计.xls"); // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放掉blob对象
      setTimeout(etew,100); //隐藏提示
    }else {
      setTimeout(etew,100); //隐藏提示
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
/**
 * 查询工单统计
 */
const doAllStatementOfWo = ()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    chartDom.showLoading({
      text: '正在努力的读取数据...',
      effect :'spin'
    });
  }
  let ftChartDom = echarts.getInstanceByDom(document.getElementById(ftChart_id));
  if (ftChartDom){
      ftChartDom.showLoading({
          text: '正在努力的读取数据...',
          effect :'spin'
      });
  }

  queryStatementList();

}

/**
 * 查询报表统计列表数据
 * @param page 当前页
 */
const queryStatementList = ()=>{
  loading_list.value = true;
  let params = doSearchFormData();

  api_statement.findAllStatementOfAsset(params).then(res=>{
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const object = res.data.data;

      if (object){
        console.log(object)
        statementData.value = object;
        setWoChartData();
        setFtChartData();
        setListData();
      }

    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading_list.value = false;
  });
}

const doSearchFormData = ()=>{
  let params = clone(statementState.value);
  if (statementState.value.startTime){
    params.startTime = statementState.value.startTime.format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
  }
  if (statementState.value.endTime){
    params.endTime = statementState.value.endTime.format("YYYY-MM-DD HH:mm:ss");
  }
  return params;
}


/**
 * 加载资产报表
 */
const loadWoChart = ()=>{
  woChart = echarts.getInstanceByDom(document.getElementById(woChart_id))
  if (!woChart){
    woChart = echarts.init(document.getElementById(woChart_id));
  }

  // 绘制图表
  woChart.setOption({
    tooltip: {
      trigger: 'axis'
    },
    legend: {

    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        start: 0,
        end: 100,
      },
      {
        type: 'slider',
      }
    ],
    grid: {
      left: '2%',
      right: '2%',
      bottom: '11%',
      containLabel: true
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        dataView: { readOnly: false },
        magicType: { type: ['line', 'bar'] },
        saveAsImage: {name:'资产报表'}
      }
    },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: '维修次数',
        type: 'bar',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: []
      },
      {
        name: '变更次数',
        type: 'bar',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: []
      }
    ]
  });
}
/**
 * 加载资产故障类型报表
 */
const loadFtChart = ()=>{
    ftChart = echarts.getInstanceByDom(document.getElementById(ftChart_id))
    if (!ftChart){
        ftChart = echarts.init(document.getElementById(ftChart_id));
    }

    // 绘制图表
    ftChart.setOption({
        tooltip: {
            trigger: 'axis'
        },
        legend: {

        },
        dataZoom: [
            {
                show: true,
                realtime: true,
                start: 0,
                end: 100,
            },
            {
                type: 'slider',
            }
        ],
        grid: {
            left: '2%',
            right: '2%',
            bottom: '11%',
            containLabel: true
        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                dataView: { readOnly: false },
                magicType: { type: ['line', 'bar'] },
                saveAsImage: {name:'资产故障类型报表'}
            }
        },
        xAxis: {
            type: 'category',
            data: []
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '次数',
                type: 'bar',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: []
            }
        ]
    });
}

const setWoChartData = ()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    const xAxisData = [];
    const seriesRepairedCount = [];
    const seriesChangeCount = [];

    for (let item of statementData.value.assetList){
      let assetObj = item;
      xAxisData.push(assetObj.assetName);
      seriesRepairedCount.push(assetObj.repairedCount);
      seriesChangeCount.push(assetObj.changeCount);
    }

    chartDom.hideLoading();
    chartDom.setOption({
      xAxis: {
        data: xAxisData
      },
      series:[
        {
          data: seriesRepairedCount
        },
        {
          data: seriesChangeCount
        }
      ]
    })
  }
}
const setFtChartData = ()=>{
    let chartDom = echarts.getInstanceByDom(document.getElementById(ftChart_id));
    if (chartDom){
        const xAxisData = [];
        const seriesFtCount = [];

        for (let key in statementData.value.assetFaultTypes){
            let item = statementData.value.assetFaultTypes[key]
            xAxisData.push(item.faultTypeName);
            seriesFtCount.push(item.count);
        }

        chartDom.hideLoading();
        chartDom.setOption({
            xAxis: {
                data: xAxisData
            },
            series:[
                {
                    data: seriesFtCount
                }
            ]
        })
    }
}
const setListData = ()=>{
  for (let item of statementData.value.assetList){
    //console.log("item----->",item)
    //let assetObj = statementData.value[item];
    let faultTypes = [];
    for (let ft in item.faultTypes){
      let ftObj = item.faultTypes[ft];
      faultTypes.push(ftObj);
    }

    const assetObj = {assetName:item.assetName,repairedCount:item.repairedCount,changeCount:item.changeCount,faultTypes:faultTypes};
    dataSource.value.push(assetObj);
  }
}
/**
 * 开始时间不能选择晚于的结束时间
 * @param current
 * @returns {boolean}
 */
const startDisabledDate = current => {
  return current && current >= dayjs(statementState.value.endTime,'YYYY-MM-DD HH:mm:ss');
};
/**
 * 结束时间不能选择早于的开始时间
 * @param current
 * @returns {boolean}
 */
const endDisabledDate = current => {
  return current && current <= dayjs(statementState.value.startTime,'YYYY-MM-DD HH:mm:ss');
};


/** 使用onBeforeUnmount避免chart_id在移除之前销毁 **/
onBeforeUnmount(()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    chartDom.clear();
    chartDom.dispose();
  }
  let ftChartDom = echarts.getInstanceByDom(document.getElementById(ftChart_id));
  if (ftChartDom){
    ftChartDom.clear();
    ftChartDom.dispose();
  }
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.statement_count_box{
  display: flex;
  height: 80px;
  box-sizing:border-box;
  border: 1px solid #ececec;
  padding: 10px;
  border-radius:5px;
  box-shadow: 0px 0px 8px #e3eeff inset;
  background-color: #f7fafd;
}
.statement_chart_box{
  width: 100%;
  box-sizing:border-box;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius:5px;
}
.block{
  background-color: #fff;
  box-sizing:border-box;
  border: 1px solid #ffffff;

}
:deep(.ant-badge-status-text){
  font-size: 10px!important;
}
</style>
