<template>
  <div ref="container" style="height:100%;position: relative;overflow: hidden">
    <div style="background-color: #fff;">
      <div class="tableHeaderContainer" >
        <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
          功能操作
          <!-- 搜索抽屉框(局部弹窗需要外层div Css position: relative;overflow: hidden ) start-->
          <a-drawer
              title="条件筛选"
              :mask="true"
              placement="top"
              v-model:visible="searchDrawerVisible"
              :get-container="()=>{return $refs.container}"
              :style="{ position: 'absolute' }"
              :height="200"
          >
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item label="告警源" name="alarmSource">
                <a-input v-model:value="formSearchState.alarmSource" size="default" />
              </a-form-item>
              <a-form-item label="告警级别" name="severity">
                <a-select ref="select" v-model:value="formSearchState.severity" style="width: 144px" size="default">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="1">紧急</a-select-option>
                  <a-select-option value="2">重要</a-select-option>
                  <a-select-option value="3">一般</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="开始日期" name="expiryDate" >
                <a-date-picker show-time v-model:value="startTime" format="YYYY-MM-DD HH:mm:ss" :locale="locale" size="default"/>
              </a-form-item>
              <a-form-item label="结束日期" name="expiryDate" >
                <a-date-picker show-time v-model:value="endTime" format="YYYY-MM-DD HH:mm:ss" :locale="locale" size="default"/>
              </a-form-item>
            </a-form>
            <template #footer>
              <div style="text-align: right">
                <a-button style="margin-right: 8px" @click="()=>{searchDrawerVisible = false;}"><template #icon><CloseOutlined /></template>关闭</a-button>
                <a-button type="primary" @click="onSearch()"><template #icon><SearchOutlined /></template>搜索</a-button>
              </div>
            </template>
          </a-drawer>
          <!-- 搜索抽屉框 end -->
        </div>
        <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
          <a-button style="margin-left: 5px;" type="primary" size="small" @click="()=>{searchDrawerVisible = true;}"><template #icon><SearchOutlined /></template>条件筛选</a-button>
        </div>
      </div>
      <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - tableHeaderContainer 40 - 表头高度40 - 分页高度52 -->
      <a-table
          class="ant-table-striped"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :bordered="false"
          size="small"
          :loading="loading"
          :scroll="{ x: 1500, y: 'calc(100vh - 227px)' }"
      >
        <template #bodyCell="{ column,record }">
          <template v-if="column.dataIndex === 'operation'">
            <div style="display: flex;justify-content: left;">
              <a-button v-if="!record.workOrderId" style="margin: 0px 4px;" type="primary" size="small" ghost @click="showCreateWorkOrder(record)"><template #icon><file-word-outlined /></template>申请工单</a-button>
              <a-button v-if="record.workOrderId && record.flowType === 6" style="margin: 0px 4px;" type="primary" size="small" ><template #icon><eye-outlined /></template>工单查看</a-button>
              <a-button v-if="record.workOrderId && record.flowType !== 6" style="margin: 0px 4px;" size="small" ><template #icon><loading-outlined /></template>工单进行中</a-button>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'severity'">
            <a-tag v-if="record.severity === 1" color="error">紧急</a-tag>
            <a-tag v-else-if="record.severity === 2" color="warning">重要</a-tag>
            <a-tag v-else-if="record.severity === 3" color="processing">一般</a-tag>
            <a-tag v-else color="default">未知</a-tag>
          </template>
        </template>
      </a-table>

      <div style="padding:10px;text-align: center">
        <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                      @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                      :page-size-options="['10','20','30','40','50']" show-size-changer>
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>

      <!--    工单申请弹窗组件     -->
      <CreateWorkOrder ref="createWorkOrder" @setCallback="callback_CreateWorkOrder" ></CreateWorkOrder>

    </div>
  </div>
</template>

<script>
import {onMounted, ref, reactive,defineAsyncComponent } from "vue";
import api_alarmCenter from "@/api/alarmCenter";
import {message} from "ant-design-vue";
import clone from "clone";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {
  SearchOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  FileWordOutlined,
    EyeOutlined,
    LoadingOutlined
} from "@ant-design/icons-vue";

dayjs.locale('zh-cn');

/** 异步加载组件 **/
const CreateWorkOrder = defineAsyncComponent(() =>{
    return import('@/components/_createWorkOrder')
})

export default {
  components: {
    SearchOutlined,
    CloseOutlined,
    // eslint-disable-next-line vue/no-unused-components
    ExclamationCircleOutlined,
    FileWordOutlined,
    EyeOutlined,
    LoadingOutlined,
    CreateWorkOrder
  },
  setup(){
    const loading = ref(false);
    const createWorkOrder = ref(null);
    const formSearchRef = ref();
    const searchDrawerVisible = ref(false);
    const startTime = ref();
    const endTime = ref();
    const formSearchState = reactive({
      alarmSource: "",
      severity: ""
    });
    let dataSource = ref([]);
    let columns= [
      {
        title: '序号',
        dataIndex: 'index',
        width:100,
        customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
      },{
        title: '告警源',
        dataIndex: 'alarmSource',
        ellipsis: true,
      },{
        title: '告警内容',
        dataIndex: 'alarmContent',
        ellipsis: true,
      },{
        title: '级别',
        dataIndex: 'severity',
        width: 150,
      },{
        title: '告警时间',
        dataIndex: 'createTime',
        width: 180,
      },{
        title: '恢复时间',
        dataIndex: 'recoveryTime',
        width: 180,
      },{
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width:300
      }
    ];

    let pagination = ref({
      current: 1,     //当前页
      pageSize: 10,  //每页条数
      total: 1,     //数据总数
      pageSizeOptions:['2','4','6'],
      showSizeChanger:true
    });

    /**
     * 查询所有用户
     * @param page 页数
     */
    const findAllItem = function (page){
      loading.value = true;
      let params = clone(formSearchState);  //克隆新的数据以免干扰formSearchState
      params.page = page;
      params.rows = pagination.value.pageSize;
      if (startTime.value){
        params.startTime = startTime.value.format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
      }
      if (endTime.value){
        params.endTime = endTime.value.format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
      }
      //console.log("findAllItem searchData----",formSearchState);
      api_alarmCenter.findAllAlarm(params).then(res=>{
        //console.log("res----",res);
        if(res?.data?.code === 0){
          dataSource.value = [];
          const dataList = res.data.data.list;
          const _total = res.data.data.total;
          pagination.value.total = _total;

          dataList.forEach((item)=>{
            //console.log("user-->",user);
            dataSource.value.push({id:item.id,alarmSource:item.alarmSource,alarmContent:item.alarmContent,
              severity:item.severity,createTime:item.createTime,recoveryTime:item.recoveryTime,flowType:item.flowType,workOrderId:item.workOrderId})
          })
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }
    /**
     * 分页响应事件
     * @param page {@link pagination}
     */
    let onPageChange = function (page){
      findAllItem(page);
    }
    /**
     * 改变pageSize分页条数
     * @param current 当前页
     * @param size  分页条数
     */
    const onShowSizeChanger = function (current, size){
      pagination.value.current = current;
      pagination.value.pageSize = size;
    }
    /**
     * 跳到第一页
     */
    const toHomePage = ()=>{
      findAllItem(1);
      pagination.value.current = 1;
    }
    /**
     * 跳到最后页
     */
    const toEndPage = ()=>{
      findAllItem(getTotalPage());
      pagination.value.current = getTotalPage();
    }
    /**
     * 刷新当前页
     */
    /*const toCurrentPage = ()=>{
      findAllItem(pagination.value.current);
    }*/

    /**
     * 获取当前总页数
     */
    const getTotalPage =()=>{
      if (pagination.value.total === 0){
        return 1;
      }
      return pagination.value.total%pagination.value.pageSize === 0
          ? pagination.value.total/pagination.value.pageSize
          : parseInt(pagination.value.total/pagination.value.pageSize)+1;
    }

    onMounted(()=>{
      findAllItem(1);

    })



    /**
     * 搜索
     */
    const onSearch = ()=>{
      formSearchRef.value.validateFields().then((values)=>{
        searchDrawerVisible.value = false;
        console.log("onSearch  values ---",values);

        toHomePage();
      }).catch(()=>{
        //console.log("onSearch  info ---",info);
      });
    }

    /**
     * 显示创建工单弹窗
     */
    const showCreateWorkOrder = (record)=>{
      createWorkOrder.value.clearFormAddState();
      createWorkOrder.value.showAddItemDialog(record.alarmContent);  //父调子方法
    }
    /**
     * 创建工单组件子调父回调
     */
    const callback_CreateWorkOrder = (flg)=>{
      if (flg){
        console.log("callback_CreateWorkOrder--->",flg);
      }

    }

    return{
      columns,
      dataSource,
      pagination,
      onPageChange,
      onShowSizeChanger,
      formSearchRef,
      formSearchState,
      searchDrawerVisible,
      showCreateWorkOrder,
      onSearch,
      toEndPage,
      startTime,
      endTime,
      createWorkOrder,
      callback_CreateWorkOrder,
      locale,
      loading,
    }
  }

}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.tableHeaderContainer{
  display: flex;
  box-sizing: border-box;
  height: 40px;
   border: 1px solid #fafafa;
  background-color: #ffffff;
}


</style>