import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_inspectRecord = {
    createInspectRecord:function (data){
        return axios({
            url:"/inspectRecord/createInspectRecord",
            method:"post",
            data:data
        })
    },
    inspectCenter:function (data){
        return axios({
            url:"/inspectRecord/inspectCenter",
            method:"post",
            data:qs.stringify(data)
        })
    },
    myInspectRecord:function (data){
        return axios({
            url:"/inspectRecord/myInspectRecord",
            method:"post",
            data:qs.stringify(data)
        })
    },
    getInspectRecord:(id)=>{
        return axios({
            url:"/inspectRecord/getInspectRecord",
            method:"get",
            params:{
                id:id
            }
        })
    },
    deleteInspectRecord:(data)=>{
        return axios({
            url:"/inspectRecord/deleteInspectRecord",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
    exportToExcelOfInspect:function (data){
        return axios({
            url:"/inspectRecord/exportToExcelOfInspect",
            method:"post",
            responseType:'blob',
            data:qs.stringify(data)
        })
    },
}
export default api_inspectRecord;
