<template>
  <div style="position: relative;overflow: hidden;">
    <a-table
        class="ant-table-striped"
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :bordered="true"
        size="small"
        :loading="loading"
        :scroll="{ y: 'calc(100vh - 40px)' }"
    >
      <template #bodyCell="{ column,record }">
        <template v-if="column.dataIndex === 'faultOverview'">
          <a-tooltip :title="record.faultOverview" placement="topLeft">{{record.faultOverview}}</a-tooltip>
        </template>
        <template v-else-if="column.dataIndex === 'reportUser'">
          {{record.reportUser?.name}}
        </template>
        <template v-else-if="column.dataIndex === 'statusMap'">
          <a-badge status="success" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_FINISHED" text='已完成' />
          <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_REPORT" :text='flowTypeObj[record.statusMap.code]' />
          <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_DISPATCH" :text='flowTypeObj[record.statusMap.code]'/>
          <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_ASSIGN" :text='flowTypeObj[record.statusMap.code]'/>
          <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_REJECT" :text='flowTypeObj[record.statusMap.code]'/>
          <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_RECEIVE" :text='flowTypeObj[record.statusMap.code]'/>
          <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_TRANSFER" :text='flowTypeObj[record.statusMap.code]'/>
          <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_DELAY" :text='flowTypeObj[record.statusMap.code]'/>
          <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_COOPERATE" :text='flowTypeObj[record.statusMap.code]'/>
        </template>
        <template v-else-if="column.dataIndex === 'commentMap'">
          {{record.commentMap.value}}
        </template>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {Constant} from "@/js/common";
import clone from "clone";
import api_workOrder from "@/api/workOrder";
import {message} from "ant-design-vue";

const type = Constant.WORK_ORDER_ALL_FINISHED;
const loading = ref(false);
const searchTimeRange = ref();
const formSearchState = reactive({
  orderNo: '',
  faultOverview: '',
  type: type,
  createTimeStart: '',
  finishTimeEnd: ''
});

let dataSource = ref([]);
let columns= [
  {
    title: '责任人',
    dataIndex: 'reportUser',
  },{
    title: '故障描述',
    dataIndex: 'faultOverview',
    ellipsis: true,
  },{
    title: '状态',
    dataIndex: 'statusMap',
  },{
    title: '创建时间',
    dataIndex: 'createTime',
  },
];
let pagination = ref({
  current: 1,     //当前页
  pageSize: 20,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});

onMounted(()=>{
  findAllItem(1);

})
/**
 * 查询所有
 * @param page 页数
 */
const findAllItem = function (page) {
  loading.value = true;
  let params = clone(formSearchState);  //克隆新的数据以免干扰formSearchState
  params.page = page;
  params.rows = pagination.value.pageSize;
  if (searchTimeRange.value) {
    params.createTimeStart = searchTimeRange.value[0].format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
    params.finishTimeEnd = searchTimeRange.value[1].format("YYYY-MM-DD HH:mm:ss");
  }
  //console.log("findAllItem searchData----",formSearchState);
  api_workOrder.findAllMyWorkOrder(params).then(res => {
    //console.log("res----",res);
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const dataList = res.data.data.list;
      const _total = res.data.data.total;
      pagination.value.total = _total;
      //console.log("%%-->",1%5);
      dataList.forEach((item) => {
        //console.log("dataList-->",setFlowType(item.flowType));

        dataSource.value.push(item);
      })
    } else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading.value = false;
  });
}

</script>

<style scoped>

</style>