/**
 * 数据指引
 */
import {defineStore} from "pinia";

export const useDirectStore = defineStore("directStore",{
    state:()=>{
        return{
            dataList:[], //存储历史的记录
            isDirect:true
        }
    },
    getters:{

    },
    actions:{
        setDataList(dataArray){
            this.dataList = dataArray;
            this.isDirect = true;
        },
        getDataList(){
            if (this.isDirect){
                this.isDirect = false;
                return this.dataList;
            }else{
                return [];
            }
        }
    },
    persist:{
        enabled:false,   //关闭持久化
        /**
         * strategies: 指定存储位置以及存储的变量都有哪些,该属性可以不写，不写的话默认是存储到sessionStorage里边，默认存储state里边的所有数据
         * strategies->storage:Storage : storage存储到哪里,可选sessionStorage/localStorage
         * strategies->paths:String[] :  paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
         **/
        strategies:[{
            storage: sessionStorage,    // storage 存储到哪里 sessionStorage/localStorage
            //paths:['count']     // paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
        }]
    }
});