import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_asset = {
    showAllAsset:function (data){
        return axios({
            url:"/asset/showAllAsset",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteAsset:(data)=>{
        return axios({
            url:"/asset/deleteAsset",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
    deleteAssetChange:(data)=>{
        return axios({
            url:"/asset/deleteAssetChange",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
    deleteAssetRepaired:(data)=>{
        return axios({
            url:"/asset/deleteAssetRepaired",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
    viewAsset:(id)=>{
        return axios({
            url:"/asset/viewAsset",
            method:"get",
            params:{
                id:id
            }
        })
    },
    addAsset:function (data){
        return axios({
            url:"/asset/addAsset",
            method:"post",
            data:qs.stringify(data)
        })
    },
    changeAsset:(data)=>{
        return axios({
            url:"/asset/changeAsset",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
    cancelAsset:(data)=>{
        return axios({
            url:"/asset/cancelAsset",
            method:"post",
            data:qs.stringify(data, {indices: false})
        })
    },
    showAllAssetChange:(data)=>{
        return axios({
            url:"/asset/showAllAssetChange",
            method:"post",
            data:qs.stringify(data)
        })
    },
    showAllAssetRepaired:(data)=>{
        return axios({
            url:"/asset/showAllAssetRepaired",
            method:"post",
            data:qs.stringify(data)
        })
    }
}
export default api_asset;
