<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">

      <div class="block" style="" >
        <div style="display: flex;box-sizing:border-box;height: 60px;" >
          <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item name="assetName">
                <a-input v-model:value="formSearchState.assetName" size="default" allowClear placeholder="资产名称" style="width: 150px;"/>
              </a-form-item>
              <a-form-item name="faultOverview">
                <a-input v-model:value="formSearchState.faultOverview" size="default" allowClear placeholder="故障描述" style="width: 200px;"/>
              </a-form-item>
              <a-form-item name="reportUserId">
                <CustomInputDeptUser size="default" placeholder="报修人" @callbackSelVal="callback_selReportUserDeptUser" style="width: 150px;"/>
              </a-form-item>
              <a-form-item name="solverUserId">
                <CustomInputDeptUser size="default" placeholder="维修人" @callbackSelVal="callback_selSolverUserDeptUser" style="width: 150px;"/>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 0px;">
            <a-button v-permission="'assetRepairedLog_delete'" :disabled="selectedRowKeys.length <= 0" style="margin-left: 5px;" type="primary" danger size="default" @click="deleteAssetRepaired(null)"><template #icon><DeleteOutlined /></template>删除</a-button>
          </div>
        </div>
      </div>

      <div class="block" style="" >
        <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - search 60 - 表头高度40 - 分页高度52 -->
        <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :bordered="true"
            size="small"
            :loading="loading_list"
            :row-selection="rowSelection"
            :scroll="{ x: 1200, y: 'calc(100vh - 247px)' }"
        >
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'operation'">
              <div style="display: flex;justify-content: left;">
                <a-button v-permission="'assetRepairedLog_viewAsset'" v-if="record.assetId" style="margin: 0px 4px;" type="link" size="small" @click="showViewAsset(record.assetId)"><template #icon><gold-outlined /></template><span style="margin-left:3px;">查看资产</span></a-button>
                <a-button v-permission="'assetRepairedLog_viewAssetRepaired'" v-if="record.workOrderId" style="margin: 0px 4px;" type="link" size="small" @click="showViewWorkOrder(record.workOrderId)"><template #icon><ContainerOutlined /></template><span style="margin-left:3px;">查看工单</span></a-button>
              </div>
            </template>
            <template v-if="column.dataIndex === 'faultType'">
              <span>{{record?.faultType?.name}}</span>
            </template>
            <template v-if="column.dataIndex === 'reportUser'">
              <span>{{record?.reportUser?.name}}</span>
            </template>
            <template v-if="column.dataIndex === 'solverUser'">
              <span>{{record?.solverUser?.name}}</span>
            </template>
            <template v-if="column.dataIndex === 'faultOverview'">
              <a-tooltip :title="record.faultOverview" placement="topLeft">{{record.faultOverview}}</a-tooltip>
            </template>
          </template>
        </a-table>
        <div style="padding:10px;text-align: center">
          <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                        @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                        :page-size-options="['10','20','30','40','50']" show-size-changer>
            <template #buildOptionText="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>

      <!--    资产查看     -->
      <ViewAsset ref="viewAssetRef" :assetId="viewaAsetId" @callback="callback_viewAsset" v-if="isShowViewAsset"/>
      <!--    工单查看     -->
      <ViewWorkOrder ref="viewWorkOrderRef" :workOrderId="viewWorkOderId" @callbackViewWorkOrder="callbackViewWorkOrder" v-if="isShowViewWorkOrder"></ViewWorkOrder>

    </div>
  </div>
</template>

<script setup>
import {computed, createVNode, defineAsyncComponent, onMounted, ref, unref} from "vue";
import clone from "clone";
import {message, Modal} from "ant-design-vue";
import {SearchOutlined, DeleteOutlined, ExclamationCircleOutlined,GoldOutlined,ContainerOutlined} from "@ant-design/icons-vue";
import api_asset from "@/api/asset";

const CustomInputDeptUser = defineAsyncComponent(() =>{
  return import('@/components/_customInputDeptUser.vue')
})
const ViewAsset = defineAsyncComponent(() =>{
  return import('@/components/_viewAsset.vue')
})
const ViewWorkOrder = defineAsyncComponent(() =>{
  return import('@/components/_viewWorkOrder')
})

let selectedRowKeys = ref([]);
const formSearchRef = ref();
const formSearchState = ref({
  reportUserId: '',
  solverUserId: '',
  faultOverview: null,
  assetName: null,
})
const loading_list = ref(false);
let dataSource = ref([]);
const viewAssetRef = ref();
const viewWorkOrderRef = ref();
const viewaAsetId = ref(null);          //要查看的资产id
const viewWorkOderId = ref(null);       //要查看工单的id
const isShowViewAsset = ref(false);
const isShowViewWorkOrder = ref(false);

let columns= [{
    title: '资产名称',
    dataIndex: 'assetName',
  },{
    title: '报修人',
    dataIndex: 'reportUser',
    ellipsis: true,
  },{
    title: '报修时间',
    dataIndex: 'reportDate',
    width:150,
  },{
    title: '故障描述',
    dataIndex: 'faultOverview',
    ellipsis: true,
  },{
    title: '维修人',
    dataIndex: 'solverUser',
  },{
    title: '故障类型',
    dataIndex: 'faultType',
  },{
    title: '解决时间',
    dataIndex: 'finishDate',
    width:150,
  },{
    title: '操作',
    dataIndex: 'operation',
    fixed: 'right',
    width:210
  }
];

let pagination = ref({
  current: 1,     //当前页
  pageSize: 15,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});
/**
 * 分页响应事件
 * @param page {@link pagination}
 */
let onPageChange = function (page){
  findAllItem(page);
}
/**
 * 改变pageSize分页条数
 * @param current 当前页
 * @param size  分页条数
 */
const onShowSizeChanger = function (current, size){
  pagination.value.current = current;
  pagination.value.pageSize = size;
}
/**
 * 跳到第一页
 */
const toHomePage = ()=>{
  findAllItem(1);
  pagination.value.current = 1;
}
/**
 * 刷新当前页
 */
const toCurrentPage = ()=>{
  findAllItem(pagination.value.current);
}

onMounted(()=>{
  findAllItem(1);


})

const findAllItem = (page)=>{
  loading_list.value = true;
  let params = clone(formSearchState.value);  //克隆新的数据以免干扰formSearchState
  params.page = page;
  params.rows = pagination.value.pageSize;

  api_asset.showAllAssetRepaired(params).then(res=>{
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const list = res.data.data.list;
      if (list){
        const _total = res.data.data.total;
        pagination.value.total = _total;

        list.forEach((item)=>{
          item['key'] = item.id;  //加key用于列表前的复选框可以多选
          //console.log(item)
          const ii = {key:item.id,id:item.id,reportDate:item.reportDate,finishDate:item.finishDate,reportUser:item.reportUser,faultType:item.faultType,
            faultOverview:item.faultOverview,solverUser:item.solverUser,assetId:item.assetId,assetName:item.assetName,workOrderId:item.workOrderId}
          dataSource.value.push(ii);
        });
      }
    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading_list.value = false;
  });
}
/**
 * 批量删除message
 */
const deleteAssetRepaired = (id)=> {
  Modal.confirm({
    title: '系统提示',
    icon: createVNode(ExclamationCircleOutlined),
    content: '确定要删除吗?',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      return new Promise((resolve, reject) => {  //使用异步处理同时按钮显示加载动画
        const params = {ids: id ? id : selectedRowKeys.value};
        api_asset.deleteAssetRepaired(params).then(res => {
          if (res?.data?.code === 0) {
            message.success("操作成功", 3);
            resolve();
            selectedRowKeys.value = [];
            toCurrentPage();
          } else {
            if (res?.data?.msg) {
              message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
            reject();
          }
        });
      });
    },
    onCancel() {
    }
  });
}

/**
 * 报修人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selReportUserDeptUser=(val)=>{
  formSearchState.value.reportUserId = val;
}
/**
 * 维修人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selSolverUserDeptUser=(val)=>{
  formSearchState.value.solverUserId = val;
}

const onSearch = ()=>{
  formSearchRef.value.validateFields().then(()=>{
    toHomePage();
  }).catch(()=>{
    //console.log("onSearch  info ---",info);
  });
}
/**
 * 查看工单
 * @param id 工单id
 */
const showViewWorkOrder = (id)=>{
  viewWorkOderId.value = id;
  isShowViewWorkOrder.value = true;
}
/**
 * 查看工单窗口关闭回调
 */
const callbackViewWorkOrder = ()=>{
  viewWorkOderId.value = null;
  isShowViewWorkOrder.value = false;
  toCurrentPage();
}
/**
 * 显示资产详情(调用ViewMessage子组件)
 * @param id
 */
const showViewAsset = (id)=>{
  viewaAsetId.value = id;
  isShowViewAsset.value = true;
}
/**
 * 查看资产窗口关闭回调
 */
const callback_viewAsset = ()=>{
  viewaAsetId.value = null;
  isShowViewAsset.value = false;
  toCurrentPage();
}

/**
 * table 复选框操作
 * @type {ComputedRef<{onChange: function(*): void, selectedRowKeys: Array<UnwrapRefSimple<*>>}>}
 */
const rowSelection = computed(() => {
  return {
    selectedRowKeys: unref(selectedRowKeys),
    onChange: changableRowKeys => {
      selectedRowKeys.value = changableRowKeys;
    }
  };
});

</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
