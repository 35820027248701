<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div  class="block" style="display: flex;padding: 15px 10px;" >
        <div style="flex: 1;">
          <a-space>
            统计时间段：
            <a-radio-group v-model:value="statementState.type" button-style="solid">
              <a-radio-button value="0">年报</a-radio-button>
              <a-radio-button value="1">月报</a-radio-button>
              <a-radio-button value="2">日报</a-radio-button>
            </a-radio-group>

            <template v-if="statementState.type === Constant.STATEMENT_TYPE_YEAR">
              <a-date-picker v-model:value="statementState.startTime" placeholder="开始年份" picker="year" format="YYYY" :locale="locale" :disabled-date="startDisabledDate" size="default"/>
              <a-date-picker v-model:value="statementState.endTime" placeholder="结束年份" picker="year" format="YYYY" :locale="locale" :disabled-date="endDisabledDate" size="default"/>
            </template>
            <template v-if="statementState.type === Constant.STATEMENT_TYPE_MONTH">
              <a-date-picker v-model:value="statementState.startTime" placeholder="开始月份" picker="month" format="YYYY-MM" :locale="locale" :disabled-date="startDisabledDate" size="default"/>
              <a-date-picker v-model:value="statementState.endTime" placeholder="结束月份" picker="month" format="YYYY-MM" :locale="locale" :disabled-date="endDisabledDate" size="default"/>
            </template>
            <template v-if="statementState.type === Constant.STATEMENT_TYPE_DAY">
              <a-date-picker v-model:value="statementState.startTime" placeholder="开始日期" format="YYYY-MM-DD" :locale="locale" :disabled-date="startDisabledDate" size="default"/>
              <a-date-picker v-model:value="statementState.endTime" placeholder="结束日期" format="YYYY-MM-DD" :locale="locale" :disabled-date="endDisabledDate" size="default"/>
            </template>
            <CustomInputProject @callbackSelVal="callback_selProject"/>
            <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>查询</a-button>
          </a-space>
        </div>
        <div style="background-color: #ffe4b2">

        </div>
      </div>

      <a-divider style="height: 10px;margin: 0px 0;"/>

      <div class="block" style="display: flex;justify-content:center;padding: 10px 10px;" >
        <div class="statement_count_box">
          <div style="flex: 1;display:flex;flex-direction:column;align-items: center;justify-content: space-around;width: 150px;">
            <div style="">巡检总数</div>
            <div style="font-weight: bold;font-size: 18px;">{{woStatement.total}}</div>
          </div>
        </div>
        <div style="width: 15px;"></div>
        <div class="statement_count_box">
          <div style="flex: 1;display:flex;flex-direction:column;align-items: center;justify-content: space-around;width: 150px;">
            <div style="color: #87d068">正常数</div>
            <div style="font-weight: bold;font-size: 18px;color: #87d068">{{woStatement.normal}}</div>
          </div>
        </div>
        <div style="width: 15px;"></div>
        <div class="statement_count_box">
          <div style="flex: 1;display:flex;flex-direction:column;align-items: center;justify-content: space-around;width: 150px;">
            <div style="color: #f50">异常数</div>
            <div style="font-weight: bold;font-size: 18px;color: #f50">{{ woStatement.unNormal }}</div>
          </div>
        </div>
      </div>

      <div class="block" style="padding: 10px 10px;" >
        <div class="statement_chart_box">
          <div><a-typography-title :level="5"><fund-two-tone /><span style="margin-left: 5px;">巡检趋势图表</span></a-typography-title></div>
          <div id="woChart_id" style="width: 100%;height: 500px;"></div>
        </div>
      </div>

      <div class="block" style="padding: 10px 10px;" >
        <div class="statement_chart_box">
          <div style="display: flex;">
            <div style="flex: 2;"><a-typography-title :level="5"><profile-two-tone /><span style="margin-left: 5px;">巡检趋势列表</span></a-typography-title></div>
            <div style="flex: 1;text-align: end"><a-button type="link" @click="exportToExcelOfWorkOrder"><file-excel-outlined />导出到Excel</a-button></div>
          </div>
          <div style="width: 100%;">
            <a-table
                class="ant-table-striped"
                :columns="columns"
                :data-source="dataSource"
                :pagination="false"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :bordered="true"
                size="small"
                :loading="loading_list"
                :scroll="{ x: 1200, y: 'calc(100vh - 227px)' }"
            >
            </a-table>
            <div style="padding:10px;text-align: center">
              <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                            @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                            :page-size-options="['10','20','30','40','50']" show-size-changer>
                <template #buildOptionText="props">
                  <span>{{ props.value }}条/页</span>
                </template>
              </a-pagination>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</template>

<script setup>

import {defineAsyncComponent, onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
import api_statement from "@/api/statement";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import {SearchOutlined,FundTwoTone,ProfileTwoTone,FileExcelOutlined} from "@ant-design/icons-vue";
import {Constant} from "@/js/common";
import * as echarts from 'echarts';
import {message} from "ant-design-vue";
import clone from "clone";

const CustomInputProject = defineAsyncComponent(() =>{
  return import('@/components/_customInputProject.vue')
})

const loading_list = ref(false);
const woChart_id = "woChart_id";
let woChart;
const statementData = ref([])
const woStatement = ref({
  total: 0,
  normal: 0,
  unNormal: 0,
});

const statementState = ref({
  type: Constant.STATEMENT_TYPE_DAY,
  startTime: '',
  endTime: '',
  projectIds:[]
});

let dataSource = ref([]);
let columns= [
  {
    title: '序号',
    dataIndex: 'index',
    width:80,
    customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
  },{
    title: '时间',
    dataIndex: 'createDateTime',

  },{
    title: '巡检总数',
    dataIndex: 'total',

  },{
    title: '正常的',
    dataIndex: 'normal',

  },{
    title: '异常的',
    dataIndex: 'unNormal',

  }
];
let pagination = ref({
  current: 1,     //当前页
  pageSize: 20,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});


onMounted(()=>{
  loadWoChart();

  window.addEventListener("resize",setResize);
})

onUnmounted(()=>{
  window.removeEventListener("resize",setResize)
})

const setResize = ()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    chartDom.resize();
  }
}

const clearWoStatement = ()=>{
  woStatement.value.total = 0;
  woStatement.value.normal = 0;
  woStatement.value.unNormal = 0;
}

/**
 * 搜索
 */
const onSearch = ()=>{
  statementData.value = [];
  clearWoStatement();
  doAllStatementOfWo();
}
/**
 * 导出到Excel
 */
const exportToExcelOfWorkOrder = ()=>{
  const etew = message.loading('正在导出到Excel...', 0);
  let params = doSearchFormData();
  api_statement.exportToExcelOfInspect(params).then(res=>{
    if (res?.data.size) {
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(res.data); // 创建下载的链接
      downloadElement.href = href;
      downloadElement.download = decodeURI("巡检报表统计.xls"); // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放掉blob对象
      setTimeout(etew,100); //隐藏提示
    }else {
      setTimeout(etew,100); //隐藏提示
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
/**
 * 查询工单统计
 */
const doAllStatementOfWo = ()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    chartDom.showLoading({
      text: '正在努力的读取数据...',
      effect :'spin'
    });
  }

  queryWoStatementChart();
  queryWoStatementList(1);

}
/**
 * 查询工单报表统计列表图表
 */
const queryWoStatementChart = ()=>{
  let params = doSearchFormData();
  api_statement.findAllStatementOfInspect(params).then(res=>{
    if (res?.data?.code === 0) {
      let i = 0;
      for (let item of res.data.data){
        i++;
        const woItem = {total:item.total,normal:item.normal,unNormal:item.unNormal,createDateTime:item.createDateTime};
        statementData.value.push(woItem);

        woStatement.value.total += woItem.total;
        woStatement.value.normal += woItem.normal;
        woStatement.value.unNormal += woItem.unNormal;
      }
      pagination.value.total = i; //用于列表查询的分页

      setWoChartData();

    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
/**
 * 查询工单报表统计列表数据
 * @param page 当前页
 */
const queryWoStatementList = (_page)=>{
  loading_list.value = true;
  let params = doSearchFormData();
  params.page = _page;
  params.rows = pagination.value.pageSize;
  api_statement.findAllStatementOfInspect(params).then(res=>{
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const list = res.data.data;
      if (list){
        for (let item of list){
          const woItem = {total:item.total,normal:item.normal,unNormal:item.unNormal,createDateTime:item.createDateTime};
          dataSource.value.push(woItem);
        }
      }

    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading_list.value = false;
  });
}

const doSearchFormData = ()=>{
  let params = clone(statementState.value);
  let formatStyle = "";
  if (params.type === Constant.STATEMENT_TYPE_YEAR){
    formatStyle = "YYYY";
  }else if (params.type === Constant.STATEMENT_TYPE_MONTH){
    formatStyle = "YYYY-MM";
  }else if (params.type === Constant.STATEMENT_TYPE_DAY){
    formatStyle = "YYYY-MM-DD";
  }
  if (statementState.value.startTime){
    params.startTime = statementState.value.startTime.format(formatStyle);  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
  }
  if (statementState.value.endTime){
    params.endTime = statementState.value.endTime.format(formatStyle);
  }
  return params;
}


/**
 * 加载工单报表
 */
const loadWoChart = ()=>{
  woChart = echarts.getInstanceByDom(document.getElementById(woChart_id))
  if (!woChart){
    woChart = echarts.init(document.getElementById(woChart_id));
  }

  // 绘制图表
  woChart.setOption({
    tooltip: {
      trigger: 'axis'
    },
    legend: {

    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        start: 0,
        end: 100,
      },
      {
        type: 'slider',
      }
    ],
    grid: {
      left: '2%',
      right: '2%',
      bottom: '11%',
      containLabel: true
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        dataView: { readOnly: false },
        magicType: { type: ['line', 'bar'] },
        saveAsImage: {name:'巡检报表'}
      }
    },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: '巡检总数',
        type: 'bar',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: []
      },
      {
        name: '正常数',
        type: 'bar',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: []
      },
      {
        name: '异常数',
        type: 'bar',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: []
      }
    ]
  });
}

const setWoChartData = ()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    const xAxisData = [];
    const seriesDataTotal = [];
    const seriesDataNormal= [];
    const seriesDataUnNormal = [];

    /*for (let i=0;i<1000;i++){
      xAxisData.push(i);
      seriesDataTotal.push(Math.floor(Math.random()*1000));
      seriesDataFinished.push(Math.floor(Math.random()*1000));
      seriesDataUnFinished.push(Math.floor(Math.random()*1000));
    }*/

    for (let item of statementData.value){
      xAxisData.push(item.createDateTime);
      seriesDataTotal.push(item.total);
      seriesDataNormal.push(item.normal);
      seriesDataUnNormal.push(item.unNormal);
    }

    chartDom.hideLoading();
    chartDom.setOption({
      xAxis: {
        data: xAxisData
      },
      series:[
        {
          data: seriesDataTotal
        },
        {
          data: seriesDataNormal
        },
        {
          data: seriesDataUnNormal
        }
      ]
    })
  }
}
/**
 * 开始时间不能选择晚于的结束时间
 * @param current
 * @returns {boolean}
 */
const startDisabledDate = current => {
  return current && current > dayjs(statementState.value.endTime,'YYYY-MM-DD HH:mm:ss');
};
/**
 * 结束时间不能选择早于的开始时间
 * @param current
 * @returns {boolean}
 */
const endDisabledDate = current => {
  return current && current < dayjs(statementState.value.startTime,'YYYY-MM-DD HH:mm:ss');
};
/**
 * 分页响应事件
 * @param page {@link pagination}
 */
let onPageChange = function (page){
  console.log(page)
  //queryWoStatementList(page);
}
/**
 * 改变pageSize分页条数
 * @param current 当前页
 * @param size  分页条数
 */
const onShowSizeChanger = function (current, size){
  pagination.value.current = current;
  pagination.value.pageSize = size;
}
/**
 * 选择的项目回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selProject = (val)=>{
  statementState.value.projectIds = val;
}
/** 使用onBeforeUnmount避免chart_id在移除之前销毁 **/
onBeforeUnmount(()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    chartDom.clear();
    chartDom.dispose();
  }
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.statement_count_box{
  display: flex;
  height: 80px;
  box-sizing:border-box;
  border: 1px solid #ececec;
  padding: 10px;
  border-radius:5px;
  box-shadow: 0px 0px 8px #e3eeff inset;
  background-color: #f7fafd;
}
.statement_chart_box{
  width: 100%;
  box-sizing:border-box;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius:5px;
}
.block{
  background-color: #fff;
  box-sizing:border-box;
  border: 1px solid #ffffff;

}
:deep(.ant-badge-status-text){
  font-size: 10px!important;
}
</style>
