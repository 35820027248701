import {createRouter, createWebHistory} from 'vue-router'
import {useUserStore} from "@/framework/store/UserStore";
import {useNavTagsStore} from "@/framework/store/NavTagsStore";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css';

import LoginPage from "@/views/LoginPage";
import FrameMain from "@/framework/views/FrameMain";
import SystemUser from "@/views/SystemUser";
import SystemVisitor from "@/views/SystemVisitor.vue";
import SystemOperation from "@/views/SystemOperation";
import SystemRole from "@/views/SystemRole";
import HomePage from "@/views/HomePage";
import TenantManage from "@/views/TenantManage";
import TenantRequest from "@/views/TenantRequest";
import ProjectManage from "@/views/ProjectManage";
import AlarmCenter from "@/views/AlarmCenter";
import WorkOrderUnfinished from "@/views/WorkOrderUnfinished";
import WorkOrderFinished from "@/views/WorkOrderFinished";
import MyInfo from "@/views/MyInfo";
import AssetManage from "@/views/AssetManage.vue";
import DeptManage from "@/views/DeptManage.vue";
import StatementWo from "@/views/StatementWo.vue"
import StatementDept from "@/views/StatementDept.vue";
import StatementUser from "@/views/StatementUser.vue"
import StatementType from "@/views/StatementType.vue";
import StatementInspect from "@/views/StatementInspect.vue";
import InformMessage from "@/views/InformMessage.vue";
import AssetBase from "@/views/AssetBase.vue";
import AssetChangeLog from "@/views/AssetChangeLog.vue";
import AssetRepairedLog from "@/views/AssetRepairedLog.vue";
import WorkOrderBase from "@/views/WorkOrderBase.vue";
import StatementAsset from "@/views/StatementAsset.vue";
import MyWorkOrderPending from "@/views/MyWorkOrderPending.vue";
import MyWorkOrderFinished from "@/views/MyWorkOrderFinished.vue";
import MyWorkOrderReport from "@/views/MyWorkOrderReport.vue";
import MyWorkOrderCreated from "@/views/MyWorkOrderCreated.vue";
import MyWorkOrderUnComment from "@/views/MyWorkOrderUnComment.vue";
import SystemSet from "@/views/SystemSet.vue";
import MobileViewWorker from "@/mobilePage/ViewWorkOrder.vue"
import InspectBase from "@/views/InspectBase.vue";
import InspectRecord from "@/views/InspectRecord.vue";
import MonitorPage from "@/views/MonitorPage.vue";
import KbsBase from "@/views/KbsBase.vue";
import KbsMyPending from "@/views/KbsMyPending.vue";
import KbsMyCreated from "@/views/KbsMyCreated.vue";
import KbsCenter from "@/views/KbsCenter.vue";
import TestPage from "@/views/TestPage.vue";
import {message} from "ant-design-vue";

// 进度条配置项
NProgress.configure({
    showSpinner: false  //不显示圆圈加载图表
});

const routes = [
    {
        path:"/",
        component:FrameMain,
        redirect:"/home",
        meta:{menuKey:'0',title:"首页",authorize:true},
        children: [
            {
                path: "/home",
                component:HomePage,
                meta:{menuKey:'1',title:"工作台",authorize:true},
            },
            {
                path: "/alarmCenter",
                component:AlarmCenter,
                meta:{menuKey:'3',title:"告警中心",authorize:true},
            },
            {
                path:"/personal",
                meta:{menuKey:'sub1',title:"个人中心",authorize:true},
                children:[
                    /*{path:"myWorkOrder/:type?/:id?",component:MyWorkOrder,meta:{menuKey:'11',title:"我的工单",authorize:true}},*/
                    {path:"myInfo",component:MyInfo,meta:{menuKey:'12',title:"个人信息",authorize:true}},
                ]
            },
            {
                path:"/myWorkOrder",
                meta:{menuKey:'sub7',title:"我的工单",authorize:true},
                children:[
                    {path:"pending",component:MyWorkOrderPending,meta:{menuKey:'71',title:"待处理工单",authorize:true}},
                    {path:"finished",component:MyWorkOrderFinished,meta:{menuKey:'72',title:"已完成工单",authorize:true}},
                    {path:"report",component:MyWorkOrderReport,meta:{menuKey:'73',title:"已报障工单",authorize:true}},
                    {path:"created",component:MyWorkOrderCreated,meta:{menuKey:'74',title:"已创建工单",authorize:true}},
                    {path:"uncomment",component:MyWorkOrderUnComment,meta:{menuKey:'75',title:"待评价工单",authorize:true}},
                ]
            },
            {
                path:"/myKbs",
                meta:{menuKey:'sub_mykbs',title:"我的知识库",authorize:true},
                children:[
                    {path:"pending",component:KbsMyPending,meta:{menuKey:'mykbs01',title:"待审批知识库",authorize:true}},
                    {path:"edited",component:KbsMyCreated,meta:{menuKey:'mykbs02',title:"已编辑知识库",authorize:true}},
                ]
            },
            {
                path:"/inform",
                meta:{menuKey:'sub9',title:"通知中心",authorize:true},
                children:[
                    {path:"message",component:InformMessage,meta:{menuKey:'91',title:"消息通知",authorize:true}},
                ]
            },
            {
                path:"/workOrder",
                meta:{menuKey:'sub2',title:"工单中心",authorize:true},
                children:[
                    {path:"workOrderUnfinished",component:WorkOrderUnfinished,meta:{menuKey:'21',title:"未完成工单",authorize:true}},
                    {path:"workOrderFinished",component:WorkOrderFinished,meta:{menuKey:'22',title:"已完成工单",authorize:true}},
                ]
            },
            {
                path:"/assetCenter",
                meta:{menuKey:'sub5',title:"资产中心",authorize:true},
                children:[
                    {path:"assetManage",component:AssetManage,meta:{menuKey:'51',title:"资产管理",authorize:true}},
                    {path:"assetChangeLog",component:AssetChangeLog,meta:{menuKey:'52',title:"资产变更记录",authorize:true}},
                    {path:"assetRepairedLog",component:AssetRepairedLog,meta:{menuKey:'53',title:"资产维修记录",authorize:true}},
                    {path:"inspectRecord",component:InspectRecord,meta:{menuKey:'54',title:"巡检记录",authorize:true}}
                ]
            },
            {
                path:"/statementCenter",
                meta:{menuKey:'sub8',title:"报表中心",authorize:true},
                children:[
                    {path:"statementWo",component:StatementWo,meta:{menuKey:'81',title:"工单报表",authorize:true}},
                    {path:"statementDept",component:StatementDept,meta:{menuKey:'82',title:"部门报表",authorize:true}},
                    {path:"statementUser",component:StatementUser,meta:{menuKey:'83',title:"员工报表",authorize:true}},
                    {path:"statementAsset",component:StatementAsset,meta:{menuKey:'84',title:"资产报表",authorize:true}},
                    {path:"statementType",component:StatementType,meta:{menuKey:'85',title:"类型报表",authorize:true}},
                    {path:"statementInspect",component:StatementInspect,meta:{menuKey:'86',title:"巡检报表",authorize:true}},
                ]
            },
            {
                path:"/base",
                meta:{menuKey:'sub10',title:"基础数据",authorize:true},
                children:[
                    {path:"assetBase",component:AssetBase,meta:{menuKey:'101',title:"资产数据",authorize:true}},
                    {path:"workOrderBase",component:WorkOrderBase,meta:{menuKey:'102',title:"工单数据",authorize:true}},
                    {path:"inspectBase",component:InspectBase,meta:{menuKey:'103',title:"巡检数据",authorize:true}},
                    {path:"kbsBase",component:KbsBase,meta:{menuKey:'104',title:"知识库数据",authorize:true}},
                ]
            },
            {
                path: "/kbs",
                meta:{menuKey:'sub11',title:"知识库中心",authorize:true},
                children:[
                    {path:"kbsCenter",component:KbsCenter,meta:{menuKey:'111',title:"知识库查询",authorize:true}},
                ]
            },
            {
                path:"/project",
                meta:{menuKey:'sub6',title:"项目中心",authorize:true},
                children: [
                    {path:"projectManage",component: ProjectManage,meta: {menuKey: '60',title:"项目管理",authorize:true}},
                ]
            },
            {
                path:"/system",
                meta:{menuKey:'sub3',title:"系统管理",authorize:true},
                children:[
                    {path:"dept",component:DeptManage,meta:{menuKey:'30',title:"部门管理",authorize:true}},
                    {path:"user",component:SystemUser,meta:{menuKey:'31',title:"用户管理",authorize:true}},
                    {path:"visitor",component:SystemVisitor,meta:{menuKey:'32',title:"访客管理",authorize:true}},
                    {path:"role",component:SystemRole,meta:{menuKey:'33',title:"角色管理",authorize:true}},
                    {path:"operation",component:SystemOperation,meta:{menuKey:'34',title:"权限管理",authorize:true}},
                    {path:"systemSet",component:SystemSet,meta:{menuKey:'35',title:"系统配置",authorize:true}}
                ]
            },
            {
                path:"/backstage",
                meta:{menuKey: 'sub4',title: "后台管理",authorize: true},
                children: [
                    {path:"tenantRequest",component: TenantRequest,meta: {menuKey: '41',title:"租户申请",authorize:true}},
                    {path:"tenant",component: TenantManage,meta: {menuKey: '42',title:"租户管理",authorize:true}},
                ]
            }
        ]
    },

    {
        path:"/monitorPage",
        component: MonitorPage,
        meta:{menuKey: 'monitor',title: "监控页",authorize: true},
    },
    {
        path:"/testPage",
        component: TestPage,
        meta:{menuKey: 'monitor',title: "监控页",authorize: true},
    },

    {
        path:"/login",
        component: LoginPage,
        meta: {authorize: false},
        beforeEnter:(to,from)=>{
            /** 已经登录的再次请求则返回之前的路径 **/
            const userStore = useUserStore();
            if(userStore && userStore.token){
                return {path:from.path};
            }else{
                return true;
            }
        },
    },

    {
        path:"/mobile/viewWorkOrder",
        component:MobileViewWorker,
        meta:{type:1,title:"工单查看",authorize:true}
    },


];

const router = createRouter({
    history:createWebHistory(),
    routes:routes
});

/**
 * 接收url可能传递的token，直接认证
 */
router.beforeEach((to)=>{
    //console.log("router.beforeEach1  to ---",to.query);
    //console.log("router.beforeEach  from ---",from);
    if (to.query.token){
        const userStore = useUserStore();
        userStore.setToken(to.query.token).then(()=>{
        }).catch(err=>{
            message.error(err.message,5);
        });
    }

});
router.beforeEach((to)=>{
    //console.log("router.beforeEach  to ---",to);
    //console.log("router.beforeEach  from ---",from);

    NProgress.start();
    /** 放行不需要认证的路径 **/
    if (!to.meta.authorize){
        return true;
    }else{
        const userStore = useUserStore();
        if(userStore && userStore.token){
            return true;
        }else {
            return {path:"/login"};
        }
    }
});

router.afterEach((to)=>{
    //console.log("router.afterEach  to ---",to);
    //console.log("router.afterEach  from ---",from);
    //对于需要认证的路由添加navTab标签
    if (to.meta.authorize){
        const navTagsStore = useNavTagsStore();
        navTagsStore.setNavTagsParms(to.meta.menuKey,to.meta.title,to.path);
    }
    NProgress.done();
})

export  {router,routes};
