/**
 * 用于展示框架的已打开的导航tab标签数据信息
 */
import {defineStore} from "pinia";

export const useNavTagsStore = defineStore("navTagsStore",{
   state:()=>{
       return{
           key:"",
           title:"",
           path:"",
           panes:[] //存储历史的记录
       }
   },
    getters:{

    },
    actions:{
        setNavTagsParms(key,title,path){
            this.key = key;
            this.title = title;
            this.path = path;
        },
        clearAllDate(){
            return new Promise((resolve,reject)=>{
                try {
                    this.key = "";
                    this.title = "";
                    this.path = "";
                    this.panes = [];
                    resolve();
                }catch (err){
                    reject(new Error("[NavTagsStore] clearAllDate - error"))
                }
            });
        }
    },
    persist:{
        enabled:false,   //开启持久化
        /**
         * strategies: 指定存储位置以及存储的变量都有哪些,该属性可以不写，不写的话默认是存储到sessionStorage里边，默认存储state里边的所有数据
         * strategies->storage:Storage : storage存储到哪里,可选sessionStorage/localStorage
         * strategies->paths:String[] :  paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
         **/
        strategies:[{
            storage: sessionStorage,    // storage 存储到哪里 sessionStorage/localStorage
            //paths:['count']     // paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
        }]
    }
});