const dateUtil = {
    /**
     * 计算两个时间段时间差，精确到秒
     * @param startDateStr e.g: 2019-04-10 17:16:11
     * @param endDateStr e.g: 2019-04-10 17:28:17
     * @return day + "天" + hour + "小时" + min + "分钟" + sec + "秒"
     */
    parseTwoDateToDay:(startDateStr,endDateStr)=>{
        if (!startDateStr || !endDateStr){
            return null;
        }
        let startDate = new Date(startDateStr);
        let endDate = new Date(endDateStr);
        let diff = endDate.getTime() - startDate.getTime();

        let seconds = parseInt((diff / 1000) % 60);
        let minutes = parseInt(((diff / (1000 * 60)) % 60));
        let hours = parseInt(((diff / (1000 * 60 * 60)) % 24));
        let days = parseInt((diff / (1000 * 60 * 60 * 24)));

        return days + "天" + hours + "小时" + minutes + "分钟" + seconds + "秒";
    }
}
export {dateUtil}