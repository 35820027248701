<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: hidden;">

      <div class="main" style="" >

        <div class="left">
          <div style="display:flex;flex-direction:column;justify-content:center;align-items:center;height: 110px;background-color: #fff;box-sizing: border-box;border: 1px solid #eaeaea;">
            <a-button style="width:90%;" type="primary" size="default" @click="showCreateKbs()"><template #icon><plus-circle-outlined  /></template>新增知识库</a-button>
            <div style="margin: 10px 0;color: #00000073;">已有<span style="color: #f68349;font-weight: bold;padding: 0 3px;">{{kbsRecoredCount}}</span>份知识库资料</div>
          </div>

          <a-card size="small" style="margin-top: 10px;box-sizing: border-box;background-color: #ffffff;border: 1px solid #eaeaea;">
            <template #title>
              <div style="font-weight: bold;font-size: 14px;font-family: 黑体,serif"><bars-outlined style="margin-right: 3px;"/>知识库分类</div>
            </template>
            <div style="height:calc(100vh - 290px);overflow-y: auto;">
              <a-tree
                  v-model:expandedKeys="expandedTypeKeys"
                  v-model:selectedKeys="selectedTypeKeys"
                  show-line
                  :show-icon="true"
                  :tree-data="kbsTypeTreeData"
                  @select="onSelectTreeKbsType"

              >
                <template #icon="{dataRef}">
                  <tag-filled style="color: #1890ff;" v-if="!dataRef.children"/>
                </template>
                <template #title="{dataRef}">
                  <template v-if="curSelKbsTypeNode?.id === dataRef.id">
                    <a-tooltip placement="right" title="再次点击恢复到全部分类">{{dataRef.title}}</a-tooltip>
                  </template>
                  <template v-else>
                    {{dataRef.title}}
                  </template>

                </template>

              </a-tree>
            </div>
          </a-card>
        </div>

        <div class="right">
          <div style="padding: 10px 0;display: flex;">
            <a-select
                v-model:value="formSearchState.keywords"
                mode="tags"
                :token-separators="[' ']"
                style="width: 100%"
                placeholder="输入关键词，多个关键词以空格分隔"
                :default-open="false"
                size="large"
                max-tag-count="responsive"
                show-arrow
            >
              <template #suffixIcon>
                <template v-if="formSearchState.kwIsAndCondition === true">
                  <a-tooltip title="当前关键词组条件为并且关系"><link-outlined style="color:#1890ff;font-size: 15px;" @click="setSearchCondition"/></a-tooltip>
                </template>
                <template v-if="formSearchState.kwIsAndCondition === false">
                  <a-tooltip title="当前关键词组条件为或者关系"><disconnect-outlined style="color:#1890ff;font-size: 15px;" @click="setSearchCondition"/></a-tooltip>
                </template>
              </template>
              <template #tagRender="{ label, closable, onClose }">
                <a-tag :closable="closable" color="#2db7f5" style="margin-right:5px;padding: 3px 5px;font-size: 14px;" @close="onClose">
                  {{ label }}&nbsp;&nbsp;
                </a-tag>
              </template>

            </a-select>
            <a-button type="primary" size="large" @click="onSearch"><template #icon><SearchOutlined /></template>搜 索</a-button>
          </div>
          <div style="box-sizing: border-box;background-color: #ffffff;border: 1px solid #eaeaea;padding-top: 10px;">
            <a-spin :spinning="loading_item" style="">
            <div style="padding: 0 8px;height:calc(100vh - 230px);overflow-y: auto;">
              <div style="color: #00000073;text-align: center;">
                {{ curSelKbsTypeNode ? '【'+curSelKbsTypeNode.title+'】' : '全部' }}分类下查询到 <span style="font-weight: bold;">{{pagination.total}}</span> 条记录！
              </div>
              <div v-if="!dataSource || dataSource.length <= 0" style="height:calc(100vh - 260px);display: flex;justify-content: center;align-items: center;">
                <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;color: #f0f2f5;">
                  <div><frown-outlined style="font-size: 100px;"/></div>
                  <div style="padding: 10px 0;font-family: 幼圆,serif;font-size: 30px;font-weight: bold;">还没有任何数据!</div>
                </div>

              </div>
              <template v-for="item in dataSource" :key="item.id">
                <div class="item">
                  <div class="title"><a @click="showViewKbsItem(item.kbsRecordId)"><my-highlight-text :keywords="formSearchState.keywords" :source-text="item.title"/></a>
                    <span class="status_passed" v-if="item.status === Constant.KBS_APPROVAL_STATUS_PASSED"><safety-certificate-filled style="margin-right: 3px;"/>已审核</span>
                    <span class="status_pending" v-if="item.status === Constant.KBS_APPROVAL_STATUS_PENDING"><clock-circle-filled style="margin-right: 3px;"/>待审核</span>
                    <span class="status_rejected" v-if="item.status === Constant.KBS_APPROVAL_STATUS_REJECTED"><close-circle-filled style="margin-right: 3px;"/>未通过</span>
                  </div>
                  <div style="display: flex;">
                    <div v-if="item.attachFileList && item.attachFileList.length > 0" class="faultImg" @click="showPreviewImg(item.attachFileList[0]?.path.replace('\\','/'),item.attachFileList[0]?.name)" >
                      <img :src="config.file_url_path+item.attachFileList[0]?.path.replace('\\','/')+'/'+item.attachFileList[0]?.name" />
                    </div>

                    <div style="flex: 1;color: #000000A8;font-size: 14px;">
                      <div class="text-collapse">
                        <my-highlight-text :keywords="formSearchState.keywords" :source-text="item.fault"/>
                      </div>
                    </div>
                  </div>
                  <div class="tools">
                    <div class="keyInfo">
                      <div><a-tooltip title="编辑时间"><clock-circle-outlined class="ico"/>{{item.createDate}}</a-tooltip></div>
                      <div><a-tooltip title="编辑用户"><user-outlined class="ico"/>{{item.createUser}}</a-tooltip></div>
                      <div><a-tooltip title="所属分类"><tag-outlined class="ico"/>{{item.kbsTypeName}}</a-tooltip></div>
                    </div>
                    <div class="operate">
                      <span v-if="item.status !== Constant.KBS_APPROVAL_STATUS_PENDING"><a-button type="text" @click="showEditKbs(item.kbsRecordId)" style="color: #1890ff;">编 辑</a-button></span>
                      <span><a-button type="text" danger @click="deleteKbsItem(item.kbsRecordId)">删 除</a-button></span>
                    </div>
                  </div>
                  <div class="separator"></div>
                </div>
              </template>

            </div>

            <div style="padding:10px 10px;text-align: center">
              <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                            @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                            :page-size-options="['10','20','30','40','50']" show-size-changer>
                <template #buildOptionText="props">
                  <span>{{ props.value }}条/页</span>
                </template>
              </a-pagination>
            </div>
            </a-spin>
          </div>

        </div>

      </div>

    <ImgPreview ref="imgPreviewRef"></ImgPreview>
    <CreateKbs ref="createKbsRef" @callback="callbackKbsItem"></CreateKbs>
    <!--    查看     -->
    <ViewKbsItem v-if="isShowViewKbsItemDialog" :kbsRecordId="viewKbsRecordId"></ViewKbsItem>
    <EditKbs ref="editKbsRef" @callback="callbackKbsItem"></EditKbs>

  </div>
</template>

<script setup>
import {BarsOutlined, PlusCircleOutlined,ClockCircleFilled,ClockCircleOutlined,UserOutlined,
  TagFilled,TagOutlined,SafetyCertificateFilled,CloseCircleFilled,SearchOutlined,LinkOutlined,DisconnectOutlined,FrownOutlined} from "@ant-design/icons-vue";
import {defineAsyncComponent, nextTick, onMounted, reactive, ref} from "vue";
import api_kbsType from "@/api/kbsType";
import {message, Modal} from "ant-design-vue";
import config from "@/js/config";
import api_kbsItem from "@/api/kbsItem";
import clone from "clone";
import {Constant} from "@/js/common";
import MyHighlightText from "@/framework/myUi/my-highlight-text.vue";


const ImgPreview = defineAsyncComponent(() =>{
  return import('@/components/_imgPreview.vue');
})
const CreateKbs = defineAsyncComponent(() =>{
  return import('@/components/kbs/_createKbs.vue')
})
const ViewKbsItem = defineAsyncComponent(() =>{
  return import('@/components/kbs/_viewKbsItemDialog.vue')
})
const EditKbs = defineAsyncComponent(() =>{
  return import('@/components/kbs/_editKbs.vue')
})
const createKbsRef = ref();
const imgPreviewRef = ref();
const loading_kbsType = ref(false);
const loading_item = ref(false);
let kbsTypeTreeData = ref([]);
const expandedTypeKeys = ref([]);
const selectedTypeKeys = ref([]);
const curSelKbsTypeNode = ref();
let dataSource = ref([]);
const isInitPage = ref(false);
const kbsRecoredCount = ref(0);
const isShowViewKbsItemDialog = ref(false);
const viewKbsRecordId = ref();
const editKbsRef = ref();
const formSearchState = reactive({
  title: '',
  fault: '',
  kbsTypeIds: [],
  keywords: [],
  kwIsAndCondition: true
});

onMounted(()=>{
  findAllKbsType();
  findAllItem(1);




});

let pagination = ref({
  current: 1,     //当前页
  pageSize: 15,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});

/**
 * 清空searchData 数据
 */
const clearSearchData = ()=>{
  formSearchState.title = "";
  formSearchState.fault = "";
  formSearchState.kbsTypeIds = [];
  formSearchState.keywords = [];
  formSearchState.kwIsAndCondition = true;
}
/**
 * 分页响应事件
 * @param page {@link pagination}
 */
let onPageChange = function (page){
  findAllItem(page);
}
/**
 * 改变pageSize分页条数
 * @param current 当前页
 * @param size  分页条数
 */
/**
 * 跳到第一页
 */
const toHomePage = ()=>{
  findAllItem(1);
  pagination.value.current = 1;
}
/**
 * 刷新当前页
 */
const toCurrentPage = ()=>{
  findAllItem(pagination.value.current);
}
const onShowSizeChanger = function (current, size){
  pagination.value.current = current;
  pagination.value.pageSize = size;
}
/**
 * 查询所有
 */
const findAllKbsType= function (){
  loading_kbsType.value = true;
  api_kbsType.findAllKbsType({}).then(res=>{
    //console.log("res----",res);
    if(res?.data?.code === 0){
      const list = res.data.data.list;
      if (list){
        const newList = convert(list);
        kbsTypeTreeData.value = newList;

        //展开所有行
        for (let e of list){
          expandedTypeKeys.value.push(e.id);
        }
      }
    }else {
      if (res?.data?.msg){
        message.error(res?.data?.msg+" "+res?.data?.data,5);
      }
    }
    loading_kbsType.value = false;
  });

}

/**
 * 显示新增知识库弹窗
 */
const showCreateKbs = ()=>{
  createKbsRef.value.show();
}
/**
 * 创建或编辑知识库后回调
 * @param flg true or false 成功or失败
 */
const callbackKbsItem = (flg)=>{
  if (flg){
    selectedTypeKeys.value = [];
    curSelKbsTypeNode.value = null;
    clearSearchData();
    toHomePage();
  }
}
const onSearch = ()=>{
  toHomePage();
}
const findAllItem = (page)=>{
  loading_item.value = true;
  let params = clone(formSearchState);  //克隆新的数据以免干扰formSearchState
  params.page = page;
  params.rows = pagination.value.pageSize;

  api_kbsItem.listKbsItem(params).then(res=>{
    //console.log("res----",res);
    if(res?.data?.code === 0){
      dataSource.value = [];

      const dataList = res.data.data.list;
      const _total = res.data.data.total;
      pagination.value.total = _total;
      if (dataList){
        dataSource.value = dataList;
      }
      //页面第一次打开时获取总记录
      if (!isInitPage.value){
        isInitPage.value = true;
        kbsRecoredCount.value = _total;
      }

    }else {
      if (res?.data?.msg){
        message.error(res?.data?.msg+" "+res?.data?.data,5);
      }
    }
    loading_item.value = false;
  });
}

/**
 * 设置搜索关键词关联条件
 */
const setSearchCondition = ()=>{
  const kwc = formSearchState.kwIsAndCondition;
  if (kwc === true){
    formSearchState.kwIsAndCondition = false;
  }else {
    formSearchState.kwIsAndCondition = true;
  }
}
/**
 * 查看知识库内容
 * @param id 工单id
 */
const showViewKbsItem = (id)=> {
  viewKbsRecordId.value = id;
  isShowViewKbsItemDialog.value = false;
  nextTick(() => {
    isShowViewKbsItemDialog.value = true;
  })
}
/**
 * 显示编辑知识库弹窗
 * @param kbsRecordId
 */
const showEditKbs = (kbsRecordId)=>{
  editKbsRef.value.show(kbsRecordId);
}
/**
 * 知识库分类树选择事件
 * @param key e.g: ['id']
 */
const onSelectTreeKbsType = (key,e)=>{//console.log(e)
  let ktIds = [];
  curSelKbsTypeNode.value = e.selectedNodes[0];
  if (curSelKbsTypeNode.value){
    ktIds = getAllSelKbTypeIds(curSelKbsTypeNode.value);
  }
  clearSearchData();
  if (ktIds && ktIds.length > 0){
    formSearchState.kbsTypeIds = ktIds;
  }
  toHomePage();
}
function getAllSelKbTypeIds(data) {
  const ids = [];
  function traverse(obj) {
    if (obj.id) {
      ids.push(obj.id);
    }
    if (obj.children && Array.isArray(obj.children)) {
      obj.children.forEach(child => traverse(child));
    }
  }
  traverse(data);
  return ids;
}

/**
 * 删除知识库
 * @param kbsRecordId kbsRecordId
 * @returns {Promise<unknown>}
 */
const deleteKbsItem = (kbsRecordId)=>{
  Modal.confirm({
    title: '系统提示！',
    content: "您确定要删除吗？",
    okText: '确定',
    cancelText: '取消',
    onOk() {
      return new Promise((resolve, reject) => {
        api_kbsItem.deleteKbsItem(kbsRecordId).then(res=>{
          if (res?.data?.code === 0) {
            resolve();
            toCurrentPage();
          }else {
            reject();
            if (res?.data?.msg) {
              message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
          }

        });
      }).catch(() => {});
    },
    onCancel() {
    }
  });
}

/**
 * 图片预览
 * @param fileFullName 完整文件名，e.g:"aaa.jpg"
 * @param path e.g: 租户id/kbs
 */
const showPreviewImg = (path,fileFullName) => {
  const imgSrc = config.file_url_path+path+"/"+fileFullName;
  imgPreviewRef.value.show(imgSrc);
};

/**
 * 转换成<a-tree>树型控件所需的data格式
 * @param rows 对象数组
 * @returns {*[]}
 */
const convert = function(rows){
  function exists(rows, parentId){
    for(let i=0; i<rows.length; i++){
      if (rows[i].id === parentId) return true;
    }
    return false;
  }

  let nodes = [];
  // get the top level nodes
  for(let i=0; i<rows.length; i++){
    let row1 = rows[i];
    if (!exists(rows, row1.parentId)){
      nodes.push({
        key:row1.id,      //<a-tree>树型字段
        id:row1.id,
        title:row1.name,  //<a-tree>树型字段，输入框控件所需的data格式所需的属性，使用在添加和编辑中选择所属节点下拉树
        value:row1.id     //<a-tree-select>树型字段
      });

    }
  }

  let toDo = [];
  for(let i=0; i<nodes.length; i++){
    toDo.push(nodes[i]);
  }

  while(toDo.length){
    let node = toDo.shift();    // the parent node
    // get the children nodes
    for(let i=0; i<rows.length; i++){
      let row = rows[i];
      if (row.parentId == node.key){
        let child = {key:row.id,id:row.id,title:row.name,value:row.id};
        if (node.children){
          node.children.push(child);
        } else {
          node.children = [child];
        }

        toDo.push(child);

      }
    }
  }
  return nodes;
}

</script>

<style scoped>
  .main{
    display: flex;
  }
  .left{
    width: 300px;
    margin-right: 10px;
  }
  .right{
    flex: 1;
  }
  .item{
    padding: 0 15px;
    transition: background-color 0.2s ease-in-out;
  }
  .item:hover{
    background-color: #efefef;
  }
  .item .title{
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .item a{
    color: #000000;
    font-family: 幼圆,serif;
    font-size: 16px;
    font-weight: bold;
  }
  .item a:hover{
    color: #1890ff;
    text-decoration: underline;
  }
  .item .status_passed{
    font-size: 13px;
    margin-left: 20px;
    color: #6ec24a;
  }
  .item .status_pending{
    font-size: 13px;
    margin-left: 20px;
    color: #1890ff;
  }
  .item .status_rejected{
    font-size: 13px;
    margin-left: 20px;
    color: #f50;
  }
  .item .tools{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  .item .tools .keyInfo{
    display: flex;
    justify-content: flex-start;
  }
  .item .tools .keyInfo div{
    margin-right: 30px;
    color: #00000073;
    font-size: 14px;
  }
  .item .tools .keyInfo .ico{
    font-size: 14px;
    margin-right: 3px;
    color: #1890ff;
  }
  .item .separator{
    width: 100%;
    height: 1px;
    border-top: #eaeaea 1px solid;
  }
  .item .operate span{

  }
  .faultImg{
    margin-right: 12px;
    cursor: pointer;
    width: 90px;
    height: 65px;
    position: relative;
  }
  .faultImg img{
    width: 100%;
    height: 100%;
    object-fit: cover; /* 设置图片的缩放模式 */
    //transition: filter 0.5s ease-in-out;
  }
  /* 问题图片缩略图鼠标经过 */
  .faultImg:hover img {
    //filter: brightness(50%);
  }
  .faultImg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 13px;
    font-family: 幼圆,serif;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
  }
  .faultImg:hover:before {
    content: '预览';
    background-color: rgba(0, 0, 0, 0.6);
  }
  /* 问题图片缩略图鼠标移除 */
  .faultImg:not(:hover) img {
    //filter: brightness(100%);
  }
  .faultImg:not(:hover):before {
    background-color: rgba(0, 0, 0, 0);
  }


  /* 问题描述超出5行显示... */
  .text-collapse {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }


</style>