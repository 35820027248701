<template>
  <div>
    <a-tabs v-model:activeKey="activeKey" hide-add type="editable-card" @edit="onEdit" @tabClick="onTabClick">
      <a-tab-pane v-for="pane in navTagsStore.panes" :key="pane.key" :tab="pane.title" :closable="pane.closable">

      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import {defineComponent, onMounted, ref, watch} from 'vue';
//import {storeToRefs} from 'pinia';
import {router} from "@/router";
import {useNavTagsStore} from "@/framework/store/NavTagsStore";

export default defineComponent({
  setup() {

    let navTagsStore = useNavTagsStore();
    //const panes = ref([]);
    //let panes = navTagsStore.panes;
    const activeKey = ref(0);
    //let {tests} = storeToRefs(navTagsStore);



    const remove = targetKey => {
      let lastIndex = 0;
      navTagsStore.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });

      if (navTagsStore.panes.length > 1){  //remove时保留一个tab显示
        navTagsStore.panes = navTagsStore.panes.filter(pane => pane.key !== targetKey);
      }

      if (navTagsStore.panes.length && activeKey.value === targetKey) {
        if (lastIndex >= 0) {
          activeKey.value = navTagsStore.panes[lastIndex].key;
          router.push( navTagsStore.panes[lastIndex].path);
        } else {
          activeKey.value = navTagsStore.panes[0].key;
          router.push( navTagsStore.panes[0].path);
        }
      }
    };

    const onEdit = targetKey => {
      remove(targetKey);

    };

    const onTabClick = function (key){
      navTagsStore.panes.forEach((pane) => {
        if (pane.key === key) {
          router.push(pane.path);
          return false;  //return false -> break；return true -> continue
        }
      });
    }

    onMounted(()=>{
      setNavTagsStore();

    })

    /**
     *  监听路由点击后的数据处理
     */
    watch(()=>{return navTagsStore.path},()=>{
      setNavTagsStore();
    });

    let setNavTagsStore = function (){
      let isExist = false;
      navTagsStore.panes.forEach((pane) => {
        if (pane.key === navTagsStore.key) {
          isExist = true;
          return false;  //return false -> break；return true -> continue
        }
      });
      if (!isExist && navTagsStore.key !== ''){
        navTagsStore.panes.push({
          title: navTagsStore.title,
          key: navTagsStore.key,
          path:navTagsStore.path,
        });
      }
      activeKey.value = navTagsStore.key;
    }

    return {
      navTagsStore,
      activeKey,
      onEdit,
      onTabClick
    };
  },

});
</script>
<style scoped>
:deep(.ant-tabs-nav) {
  margin: 0 0 10px 0!important;
}
:deep(.ant-tabs-nav::before){
  border-bottom: 0px solid #0cbd09!important;
}
:deep(.ant-tabs-tab){
  font-size: 12px!important;
  padding: 2px 10px!important;
  border: 1px solid #D0CECEFF !important;
  margin-left: 5px!important;
  border-radius: 3px!important;
  background: #ffffff!important;
}
:deep(.ant-tabs-tab:hover){
  border: 1px solid #1890ff !important;
}
:deep(.ant-tabs-tab-active){
  border-bottom-color: #D0CECEFF!important;
  background: #1890ff!important;
  border: 1px solid #1890ff !important;
  border-radius: 3px!important;
}
:deep(.ant-tabs-tab-btn){
  transition: all 0s!important;
}
:deep(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn){
  color: #ffffff!important;
}
:deep(.ant-tabs-tab-remove) {
  margin-right: -12px!important;
  margin-left: 4px!important;
}
:deep(.ant-tabs-nav-more){
  padding: 2px 10px!important;
}

</style>
