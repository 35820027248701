<!--
 高亮替换字符，默认忽略大小写
 e.g :
 <my-highlight-text
  :keywords="['打印','文字',5,'A']"
  sourceText="财务打印机打印出来的文字显示不全，文字不清晰5Aa"
  fontColor="#ed6a0c"
  backGroundColor=""
  >
  </my-highlight-text>
 -->

<template>
  <span v-html="val" ></span>
</template>

<script setup>
import {onBeforeUpdate, onMounted, ref} from "vue";

const props = defineProps({ //defineProps声明的props的值是只读readonly的，不能修改
  keywords:Array,
  sourceText:{
    type:String,
    default:""
  },
  ignoreCase:{
    type:Boolean,
    default:true  //默认忽略大小写
  },
  fontColor:{
    type:String,
    default:"#ed6a0c"
  },
  backGroundColor:{
    type:String,
    default:''
  }
});

const val = ref(null);

onBeforeUpdate(()=>{
  //console.log("onBeforeUpdate",props.keywords)
  setStyle();
})
onMounted(()=>{
  //console.log("onMounted")
  val.value = ""; //初始化时随便变更值让其触发onBeforeUpdate
})
const setStyle = ()=>{
  val.value = props.sourceText;
  if (props.keywords && props.keywords.length > 0 && props.sourceText){
    let modifiers = "gi"; //默认全局匹配并忽略大小写
    if (props.ignoreCase === false){
      modifiers = "g";  //全局匹配并不忽略大小写
    }

    for (let kw of props.keywords){
      if (!kw){
        continue;
      }
      /**
       * (?!<[^>]+): 负向预查,确保匹配的 a 字符不在 HTML 标签内部
       * <br/>(?![^<]+>): 负向预查,确保匹配的 a 字符不在 HTML 标签的结尾部分
       * <br/> gi : 全局查找并忽略大小写
       */
      const regex = new RegExp(`(?!<[^>]+)${kw}(?![^<]+>)`, modifiers);
      val.value = val.value.replace(regex,`<span style="color:${props.fontColor};background-color: ${props.backGroundColor};">${kw}</span>`)
    }

  }
}
</script>

<style scoped>

</style>