<template>
  <a-layout style="height: 100vh;overflow: hidden">
    <FrameMenu ref="menuCollapsedRef"></FrameMenu>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0;height: auto;line-height: 44px;box-shadow: 3px 3px 8px #dee0e2;">
        <a-row>
          <a-col :span="24">
            <div style="display: flex">
              <div>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="onMenuCollapse"/>
                <menu-fold-outlined v-else class="trigger" @click="onMenuCollapse" />
              </div>
              <div style="flex: 1">
                <FrameNav></FrameNav>
              </div>
            </div>


          </a-col>
        </a-row>

        <a-divider style="height: 10px;margin: 0px 0;"/>

        <a-row>
          <a-col :span="24"><FrameTabs></FrameTabs></a-col>
        </a-row>
      </a-layout-header>

      <a-layout-content :style="{ margin: '5px 10px', padding: '0px', background: '' }">
        <!-- overflow:auto 可实现此div内容滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px -->
        <div style="height:calc(100vh - 95px);overflow:auto">
          <!--<keep-alive>
            <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeIn">
              <router-view></router-view>
            </transition>
          </keep-alive>-->
          <router-view v-slot="{Component}">
            <keep-alive>
              <transition mode="out-in" leave-active-class="cmp-out" enter-active-class="cmp-in" >
                <Component :is="Component" v-if="isRouterActive"/>
              </transition>
            </keep-alive>
          </router-view>

        </div>
      </a-layout-content>
    </a-layout>

    <InitOneLoginSet v-if="isShowInitOneLoginSet"></InitOneLoginSet>

  </a-layout>
</template>
<script>
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
import {
  defineComponent,
  onMounted,
  ref,
  provide,
  nextTick,
  onUnmounted,
  defineAsyncComponent,
} from 'vue';
import FrameMenu from "@/framework/components/FrameMenu";
import FrameTabs from "@/framework/components/FrameTabs";
import FrameNav from "@/framework/components/FrameNav";
import 'animate.css/animate.css';
import {useUserStore} from "@/framework/store/UserStore";
import MyWebSocket from "@/js/myWebSocket";
import config from "@/js/config";

const InitOneLoginSet = defineAsyncComponent(() =>{
  return import('@/components/_initOneLoginSet.vue')
})

export default defineComponent({
  components: {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    FrameNav,
    FrameMenu,
    FrameTabs,
    InitOneLoginSet
  },

  setup() {
    const userStore = useUserStore();
    const collapsed = ref(false);
    const menuCollapsedRef = ref(null);
    const isRouterActive = ref(true);
    const isShowInitOneLoginSet = ref(false);
    let ws;

    const dataState=ref({
        id:"",
        code:null,
        msg:null,
        data:null,
    });
    provide("ws-data",dataState)

    /**
     * 全局定义要监听数据池
     */
    const dataPool = ref({
      /** 通知项目选择去获取活动项目，_projectSelect.vue **/
      project_active: '',
      /** 通知HomePage.vue 页面 watch 监听器数据有变化 **/
      wake_homePage_tag:'',

      data:null
    })
    provide("watchDataPool",dataPool);

    /**
     * ProjectManange.vue 子组件调用通知
     */
    provide("notify_project_active",()=>{
      dataPool.value.project_active = Math.random().toString();
    });
    /**
     * _viewWorkOrder.vue 子组件调用的此方法，页面关闭了
     * @param isDataChanged true:子页面中数据有操作
     */
    provide("notify_homePage_data",(isDataChanged)=>{
      dataPool.value.wake_homePage_tag = Math.random().toString();
      dataPool.value.data = isDataChanged;
    });

    /** 此父组件调用子组件(FrameMenu.vue)的方法menuCollapsed，展开或收缩菜单 **/
    let onMenuCollapse = function (){
      menuCollapsedRef.value.menuCollapsed();
      collapsed.value = !collapsed.value;
    }

    /**
     * 父组件传递给子组件方法,更新当前活动的组件
     * <br/>FramNav.vue中<ProjectSelect>组件中const reload = inject("reload");调用reload()来更新
     */
    provide("reload",()=>{
      isRouterActive.value = false;
      //dom显示完成后回调的此方法，避免dom还未创建去获取数据
      nextTick(()=>{
        isRouterActive.value = true;
      });
    });

    onMounted(()=>{
      checkOneLoginSet();
      startWebSocketService();

    })

    /**
     * webSocket 服务
     */
    const startWebSocketService = ()=>{
      const token = userStore.token;
      if (token){
        ws = new MyWebSocket(config.websocket_url+"?token="+token)
        ws.start();
        ws.onConnected = ()=>{
          console.log("ws---------onConnected");
        };
        ws.onExpired = ()=>{
          console.log("ws---------onExpired");
        };
        ws.onRelink = function () {
          console.log("ws---------onRelink");
        };
        ws.onReceive = function (data) {
          //console.log("ws---------onReceive ",data)

          if (data){
              dataState.value.id = Math.random().toString();
              dataState.value.code = data.code;
              dataState.value.msg = data.msg;
              dataState.value.data = data.data;
          }
        };
      }
    }
    /**
     * 首次登录检查是否要修改密码
     */
    const checkOneLoginSet = ()=>{
      if (userStore && !userStore.lastLoginTime){
        nextTick(()=>{
          isShowInitOneLoginSet.value = true;
        })
      }
    }

    onUnmounted(()=>{
      /** 关闭websocket **/
      if (ws){
        ws.close();
      }
    })

    return {
      isShowInitOneLoginSet,
      collapsed,
      menuCollapsedRef,
      onMenuCollapse,
      isRouterActive
    };
  },

});
</script>
<style>
.trigger {
  font-size: 18px;
  line-height: 18px;
  padding: 0 10px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}

.fade-enter-active {
  transition: opacity 2s;
}
.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
/*主内容路由切换动画，参考：https://animate.style*/
.cmp-in {
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.2s; /* don't forget to set a duration! */
}
.cmp-out{
  animation: fadeOut;
  animation-duration: 0.2s;
}
.ant-descriptions-row th {
  width: 110px;
}
</style>
