import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_faultType = {
    findAllFaultType:function (data){
        return axios({
            url:"/base/findAllFaultType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    findTenantAllFaultType:function (){
        return axios({
            url:"/base/findTenantAllFaultType",
            method:"get",
        })
    },
    addFaultType:(data)=>{
        return axios({
            url:"/base/addFaultType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteFaultType:(data)=>{
        return axios({
            url:"/base/deleteFaultType",
            method:"post",
            data:qs.stringify(data, {indices: false})   //indices: false 传递数组
        })
    },
    getFaultType:(id)=>{
        return axios({
            url:"/base/getFaultType",
            method:"get",
            params:{
                id:id
            }
        })
    },
    updateFaultType:(data)=>{
        return axios({
            url:"/base/updateFaultType",
            method:"post",
            data:qs.stringify(data)
        })
    },
}
export default api_faultType;
