<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div  class="block" style="display: flex;padding: 15px 10px;" >
        <div style="flex: 1;">
          <a-space>
            统计范围：
            <a-date-picker v-model:value="statementState.startTime" placeholder="开始日期" format="YYYY-MM-DD HH:mm:ss" show-time :locale="locale" :disabled-date="startDisabledDate" size="default"/>
            <a-date-picker v-model:value="statementState.endTime" placeholder="结束日期" format="YYYY-MM-DD HH:mm:ss" show-time :locale="locale" :disabled-date="endDisabledDate" size="default"/>
            <a-tree-select
                v-model:value="statementState.deptIds"
                tree-data-simple-mode
                allow-clear
                tree-checkable
                show-checked-strategy="SHOW_ALL"
                style="width: 300px"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                placeholder="请选择部门"
                :tree-data="treeAllDeptData"
                :tree-line="false"
                :listHeight="500"
                :tree-default-expand-all="true"
                @focus="onFocusDept"
            >
            </a-tree-select>
            <CustomInputProject @callbackSelVal="callback_selProject"/>
            <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>查询</a-button>
          </a-space>
        </div>
        <div style="background-color: #ffe4b2">

        </div>
      </div>

      <a-divider style="height: 10px;margin: 0px 0;"/>

      <div class="block" style="display: flex;justify-content:center;padding: 10px 10px;" >

      </div>

      <div class="block" style="padding: 10px 10px;" >
        <div class="statement_chart_box">
          <div><a-typography-title :level="5"><fund-two-tone /><span style="margin-left: 5px;">部门报障趋势图表</span></a-typography-title></div>
          <div id="woChart_id" style="width: 100%;height: 500px;"></div>
        </div>
      </div>



      <div class="block" style="padding: 10px 10px;" >
        <div class="statement_chart_box">
          <div style="display: flex;">
            <div style="flex: 2;"><a-typography-title :level="5"><profile-two-tone /><span style="margin-left: 5px;">部门报障趋势列表</span></a-typography-title></div>
            <div style="flex: 1;text-align: end"><a-button type="link" @click="exportToExcelOfWorkOrder"><file-excel-outlined />导出到Excel</a-button></div>
          </div>
          <div style="width: 100%;">
            <a-table
                class="ant-table-striped"
                :columns="columns"
                :data-source="dataSource"
                :pagination="false"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :bordered="true"
                size="small"
                :loading="loading_list"
                :scroll="{ x: 1200, y: 'calc(100vh - 227px)' }"
            >
              <template #bodyCell="{ column,record }">
                <template v-if="column.dataIndex === 'faultTypes'">
                  <template v-for="item in record.faultTypes" :key="item">
                    <a-tooltip :title="item.name +' ('+item.count+'次)'" placement="topLeft"><a-badge status="default" />{{item.name}} ({{item.count}}次)</a-tooltip> <br/>
                  </template>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script setup>

import {defineAsyncComponent, onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
import api_statement from "@/api/statement";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import {SearchOutlined,FundTwoTone,ProfileTwoTone,FileExcelOutlined} from "@ant-design/icons-vue";

import * as echarts from 'echarts';
import {message} from "ant-design-vue";
import clone from "clone";
import api_dept from "@/api/dept";

const CustomInputProject = defineAsyncComponent(() =>{
  return import('@/components/_customInputProject.vue')
})

const loading_list = ref(false);
const woChart_id = "woChart_id";
let woChart;
let treeAllDeptData = ref([]);
const statementData = ref([]);
const statementState = ref({
  startTime: '',
  endTime: '',
  deptIds: [],
  projectIds: []
});

let dataSource = ref([]);
let columns= [
  {
    title: '序号',
    dataIndex: 'index',
    width:80,
    customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
  },{
    title: '部门',
    dataIndex: 'name',

  },{
    title: '报障工单数',
    dataIndex: 'woCount',

  },{
    title: '已完成工单数',
    dataIndex: 'finished',

  },{
    title: '未完成数',
    dataIndex: 'unFinished',

  },{
    title: '报障故障类型',
    dataIndex: 'faultTypes',
    ellipsis: true,
    width:300
  },{
    title: '满意数',
    dataIndex: 'commentGood',
  },{
    title: '不满意数',
    dataIndex: 'commentBad',
  },{
    title: '好评率(%)',
    dataIndex: 'goodRate'
  }
];
let pagination = ref({
  current: 1,     //当前页
  pageSize: 10,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});


onMounted(()=>{
  loadWoChart();

  window.addEventListener("resize",setResize);
})

onUnmounted(()=>{
  window.removeEventListener("resize",setResize)
})

const setResize = ()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    chartDom.resize();
  }
}

/**
 * 搜索
 */
const onSearch = ()=>{
  statementData.value = [];
  doAllStatementOfWo();
}
/**
 * 导出到Excel
 */
const exportToExcelOfWorkOrder = ()=>{
  const etew = message.loading('正在导出到Excel...', 0);
  let params = doSearchFormData();
  api_statement.exportToExcelOfDept(params).then(res=>{
    if (res?.data.size) {
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(res.data); // 创建下载的链接
      downloadElement.href = href;
      downloadElement.download = decodeURI("部门报表统计.xls"); // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放掉blob对象
      setTimeout(etew,100); //隐藏提示
    }else {
      setTimeout(etew,100); //隐藏提示
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
/**
 * 查询工单统计
 */
const doAllStatementOfWo = ()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    chartDom.showLoading({
      text: '正在努力的读取数据...',
      effect :'spin'
    });
  }

  queryWoStatementList();

}
/**
 * 查询部门报表统计列表数据
 */
const queryWoStatementList = ()=>{
  loading_list.value = true;
  let params = doSearchFormData();

  api_statement.findAllStatementOfDept(params).then(res=>{
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const object = res.data.data;

      if (object){
        statementData.value = object;
        setWoChartData();
        setListData();
      }

    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading_list.value = false;
  });
}

const doSearchFormData = ()=>{
  let params = clone(statementState.value);
  if (statementState.value.startTime){
    params.startTime = statementState.value.startTime.format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
  }
  if (statementState.value.endTime){
    params.endTime = statementState.value.endTime.format("YYYY-MM-DD HH:mm:ss");
  }
  return params;
}


/**
 * 加载工单报表
 */
const loadWoChart = ()=>{
  woChart = echarts.getInstanceByDom(document.getElementById(woChart_id))
  if (!woChart){
    woChart = echarts.init(document.getElementById(woChart_id));
  }

  // 绘制图表
  woChart.setOption({
    tooltip: {
      trigger: 'axis'
    },
    legend: {

    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        start: 0,
        end: 100,
      },
      {
        type: 'slider',
      }
    ],
    grid: {
      left: '2%',
      right: '2%',
      bottom: '11%',
      containLabel: true
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        dataView: { readOnly: false },
        magicType: { type: ['line', 'bar'] },
        saveAsImage: {name:'部门报表'}
      }
    },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: '报障工单数',
        type: 'bar',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: []
      },
      {
        name: '已完成工单数',
        type: 'bar',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: []
      },
      {
        name: '未完成数',
        type: 'bar',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: []
      },
      {
        name: '好评率(%)',
        type: 'bar',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: []
      }
    ]
  });
}

const setWoChartData = ()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    const xAxisData = [];
    const seriesWoCount = [];
    const seriesDataFinished = [];
    const seriesDataUnFinished = [];
    const seriesDataGoodRate = [];

    for (let item in statementData.value){
      let deptObj = statementData.value[item];
      xAxisData.push(deptObj.name);
      seriesWoCount.push(deptObj.woCount);
      seriesDataFinished.push(deptObj.finished);
      seriesDataUnFinished.push(deptObj.unFinished);
      seriesDataGoodRate.push(deptObj.goodRate);
    }

    chartDom.hideLoading();
    chartDom.setOption({
      xAxis: {
        data: xAxisData
      },
      series:[
        {
          data: seriesWoCount
        },
        {
          data: seriesDataFinished
        },
        {
          data: seriesDataUnFinished
        },
        {
          data: seriesDataGoodRate
        }
      ]
    })
  }
}
const setListData = ()=>{
  for (let item in statementData.value){
    let deptObj = statementData.value[item];
    let faultTypes = [];
    for (let ft in deptObj.faultTypes){
      let ftObj = deptObj.faultTypes[ft];
      faultTypes.push(ftObj);
    }
    const userItem = {name:deptObj.name,woCount:deptObj.woCount,
      finished:deptObj.finished,unFinished:deptObj.unFinished,faultTypes:faultTypes,commentGood:deptObj.comment?.good,commentBad:deptObj.comment?.bad,goodRate:deptObj?.goodRate};
    dataSource.value.push(userItem);
  }
}
/**
 * 开始时间不能选择晚于的结束时间
 * @param current
 * @returns {boolean}
 */
const startDisabledDate = current => {
  return current && current >= dayjs(statementState.value.endTime,'YYYY-MM-DD HH:mm:ss');
};
/**
 * 结束时间不能选择早于的开始时间
 * @param current
 * @returns {boolean}
 */
const endDisabledDate = current => {
  return current && current <= dayjs(statementState.value.startTime,'YYYY-MM-DD HH:mm:ss');
};
/**
 * 选择部门触发事件
 */
const onFocusDept=()=>{
  /** 查询所有部门 **/
  api_dept.findAllDept({}).then(res=>{
    treeAllDeptData.value = [];
    if(res?.data?.code === 0){
      const list = res.data.data.list;
      if (list && list.length > 0){
        for (let item of list){
          treeAllDeptData.value.push({id:item.id,pId:item.parentId,value:item.id,title:item.name,})
        }
      }
    }else {
      if (res?.data?.msg){
        message.error(res?.data?.msg+" "+res?.data?.data,5);
      }
    }

  });
}
/**
 * 选择的项目回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selProject = (val)=>{
  statementState.value.projectIds = val;
}
/** 使用onBeforeUnmount避免chart_id在移除之前销毁 **/
onBeforeUnmount(()=>{
  let chartDom = echarts.getInstanceByDom(document.getElementById(woChart_id));
  if (chartDom){
    chartDom.clear();
    chartDom.dispose();
  }
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.statement_count_box{
  display: flex;
  height: 80px;
  box-sizing:border-box;
  border: 1px solid #ececec;
  padding: 10px;
  border-radius:5px;
  box-shadow: 0px 0px 8px #e3eeff inset;
  background-color: #f7fafd;
}
.statement_chart_box{
  width: 100%;
  box-sizing:border-box;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius:5px;
}
.block{
  background-color: #fff;
  box-sizing:border-box;
  border: 1px solid #ffffff;

}
:deep(.ant-badge-status-text){
  font-size: 10px!important;
}
</style>
