import { createApp } from 'vue'
import Antd from "ant-design-vue"							//ui,					npm install ant-design-vue --save
import 'ant-design-vue/dist/antd.css';
//import 'ant-design-vue/dist/antd.dark.css';
import "@/assets/style/scrollbar.css";                  	//自定义滚动条样式
import App from './App.vue'
import {router} from './router'								//vue router,			npm install vue-router@4
import axios from "axios";									//vue axios,			npm install --save axios vue-axios
import VueAxios from "vue-axios";
import {createPinia} from "pinia";  						//pinia 状态存储库,   	npm install pinia
import piniaPluginPersist from "pinia-plugin-persist";  	//pinia的数据持久化插件,	npm i pinia-plugin-persist --save
import permission from "@/framework/directive/permisson";   //自定义权限指令
															//qs编码,				npm install qs
															//css 动画特效			npm install animate.css --save


const pinia = createPinia();
pinia.use(piniaPluginPersist);

const app = createApp(App);
app.use(router);
app.use(Antd);
app.use(VueAxios, axios);
app.use(pinia);
app.use(permission);


app.mount('#app');
