import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_dept = {
    findAllDept:function (data){
        return axios({
            url:"/dept/findAllDept",
            method:"post",
            data:qs.stringify(data)
        })
    },
    saveDept:(data)=>{
        return axios({
            url:"/dept/saveDept",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteDept:(id)=>{
        return axios({
            url:"/dept/deleteDept",
            method:"get",
            params:{
                id:id
            }
        })
    },
    updateDept:(data)=>{
        return axios({
            url:"/dept/updateDept",
            method:"post",
            data:qs.stringify(data)
        })
    },
    getDept:(id)=>{
        return axios({
            url:"/dept/getDept",
            method:"get",
            params:{
                id:id
            }
        })
    },
}
export default api_dept;
