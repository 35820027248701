/**
 * 全局配置
 * @type {string}
 */

/**
 * 1.aqnu.ahhwsoft.cn:443
 * 2.60.173.242.177:8090(hwoms.ahhwsoft.cn:8443)
 * 3.zz.tlpt.net.cn:9880
 * @type {string}
 */
const server_ip = "aqnu.ahhwsoft.cn";
const server_port = 443;

const config = {
    server_url : "https://"+server_ip+":"+server_port+"/hwoms/",
    websocket_url : "wss://"+server_ip+":"+server_port+"/hwoms/ws",
    file_url_path : "https://"+server_ip+":"+server_port+"/hwoms/file/",
}

export default config;
