<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">

      <div class="block" style="" >
        <div style="display: flex;box-sizing:border-box;height: 60px;" >
          <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item name="title">
                <a-input v-model:value="formSearchState.title" size="default" allowClear placeholder="标题名称" style="width: 150px;"/>
              </a-form-item>
              <a-form-item name="createUserId">
                <CustomInputDeptUser size="default" placeholder="巡检人" @callbackSelVal="callback_selReportUserDeptUser" style="width: 150px;"/>
              </a-form-item>
              <a-form-item name="searchCreateTimeRange" >
                <a-range-picker show-time v-model:value="searchCreateTimeRange" format="YYYY-MM-DD HH:mm:ss" :locale="locale" size="default"/>
              </a-form-item>
              <a-form-item>
                <CustomInputProject @callbackSelVal="callback_selProject"/>
              </a-form-item>
              <a-form-item name="status">
                <a-select v-model:value="formSearchState.status" style="width: 100px"  size="default">
                  <a-select-option value="">全部状态</a-select-option>
                  <a-select-option :value="Constant.INSPECT_STATUS_NORMAL">正常</a-select-option>
                  <a-select-option :value="Constant.INSPECT_STATUS_UNNORMAL">异常</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 0px;">
            <a-button v-permission="'inspectRecordDelete'" :disabled="selectedRowKeys.length <= 0" style="margin-left: 5px;" type="primary" danger size="default" @click="deleteAssetRepaired(null)"><template #icon><DeleteOutlined /></template>删除</a-button>
            <a-button   style="margin-left: 5px;" type="primary"  size="default" @click="exportToExcelOfInspect"><template #icon><file-excel-outlined /></template>导出到Excel</a-button>
          </div>
        </div>
      </div>

      <div class="block" style="" >
        <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - search 60 - 表头高度40 - 分页高度52 -->
        <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :bordered="true"
            size="small"
            :loading="loading_list"
            :row-selection="rowSelection"
            :scroll="{ x: 1200, y: 'calc(100vh - 247px)' }"
        >
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'operation'">
              <div style="display: flex;justify-content: left;">
                <a-button  v-permission="'inspectRecordView'" style="margin: 0px 4px;" type="link" size="small" @click="showViewAsset(record.id)"><template #icon><eye-outlined /></template><span style="margin-left:3px;">查看</span></a-button>
              </div>
            </template>
            <template v-if="column.dataIndex === 'createUser'">
              <span>{{record?.createUser?.name}}</span>
            </template>
            <template v-if="column.dataIndex === 'status'">
              <a-tag color="error" v-if="record.status === Constant.INSPECT_STATUS_UNNORMAL">异常</a-tag>
              <a-tag color="green" v-else-if="record.status === Constant.INSPECT_STATUS_NORMAL">正常</a-tag>
            </template>
          </template>
        </a-table>
        <div style="padding:10px;text-align: center">
          <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                        @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                        :page-size-options="['10','20','30','40','50']" show-size-changer>
            <template #buildOptionText="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>

      <!--    查看     -->
      <ViewInspectRecord ref="viewItemRef" :id="viewItemId" @callback="callback_viewAsset" v-if="isShowViewItem"/>


    </div>
  </div>
</template>

<script setup>
import {computed, createVNode, defineAsyncComponent, onMounted, ref, unref} from "vue";
import clone from "clone";
import {message, Modal} from "ant-design-vue";
import {
  SearchOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileExcelOutlined
} from "@ant-design/icons-vue";
import api_inspectRecord from "@/api/inspectRecord";
import {Constant} from "@/js/common";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import 'dayjs/locale/zh-cn';

const CustomInputDeptUser = defineAsyncComponent(() =>{
  return import('@/components/_customInputDeptUser.vue')
})
const ViewInspectRecord = defineAsyncComponent(() =>{
  return import('@/components/_viewInspectRecord.vue')
})
const CustomInputProject = defineAsyncComponent(() =>{
  return import('@/components/_customInputProject.vue')
})

let selectedRowKeys = ref([]);
const searchCreateTimeRange = ref();
const formSearchRef = ref();
const formSearchState = ref({
  title: '',
  createUserId: '',
  projectIds: null,
  status:''
})
const loading_list = ref(false);
let dataSource = ref([]);
const viewItemRef = ref();
const viewItemId = ref(null);          //要查看的item id
const isShowViewItem = ref(false);

let columns= [{
    title: '编号',
    dataIndex: 'recordNo',
    width:220,
  },{
    title: '标题',
    dataIndex: 'title',
    ellipsis: true,
  },{
    title: '巡检时间',
    dataIndex: 'createTime',
    width:150,
  },{
    title: '巡检人',
    dataIndex: 'createUser',
    width:150,
  },{
    title: '状态',
    dataIndex: 'status',
    width:80,
  },{
    title: '操作',
    dataIndex: 'operation',
    fixed: 'right',
    width:210
  }
];

let pagination = ref({
  current: 1,     //当前页
  pageSize: 15,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});
/**
 * 分页响应事件
 * @param page {@link pagination}
 */
let onPageChange = function (page){
  findAllItem(page);
}
/**
 * 改变pageSize分页条数
 * @param current 当前页
 * @param size  分页条数
 */
const onShowSizeChanger = function (current, size){
  pagination.value.current = current;
  pagination.value.pageSize = size;
}
/**
 * 跳到第一页
 */
const toHomePage = ()=>{
  findAllItem(1);
  pagination.value.current = 1;
}
/**
 * 刷新当前页
 */
const toCurrentPage = ()=>{
  findAllItem(pagination.value.current);
}

onMounted(()=>{
  findAllItem(1);


})

const findAllItem = (page)=>{
  loading_list.value = true;
  let params = clone(formSearchState.value);  //克隆新的数据以免干扰formSearchState
  params.page = page;
  params.rows = pagination.value.pageSize;
  if (searchCreateTimeRange.value) {
    params.createTimeStart = searchCreateTimeRange.value[0].format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
    params.createTimeEnd = searchCreateTimeRange.value[1].format("YYYY-MM-DD HH:mm:ss");
  }

  api_inspectRecord.inspectCenter(params).then(res=>{
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const list = res.data.data.list;
      if (list){
        const _total = res.data.data.total;
        pagination.value.total = _total;

        list.forEach((item)=>{
          item['key'] = item.id;  //加key用于列表前的复选框可以多选
          //console.log(item)
          const ii = {key:item.id,id:item.id,recordNo:item.recordNo,title:item.title,createTime:item.createTime,createUser:item.createUser,
            status:item.status}
          dataSource.value.push(ii);
        });
      }
    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading_list.value = false;
  });
}
/**
 * 批量删除message
 */
const deleteAssetRepaired = (id)=> {
  Modal.confirm({
    title: '系统提示',
    icon: createVNode(ExclamationCircleOutlined),
    content: '确定要删除吗?',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      return new Promise((resolve, reject) => {  //使用异步处理同时按钮显示加载动画
        const params = {ids: id ? id : selectedRowKeys.value};
        api_inspectRecord.deleteInspectRecord(params).then(res => {
          if (res?.data?.code === 0) {
            message.success("操作成功", 3);
            resolve();
            selectedRowKeys.value = [];
            toCurrentPage();
          } else {
            if (res?.data?.msg) {
              message.error(res?.data?.msg + " " + res?.data?.data, 5);
            }
            reject();
          }
        });
      });
    },
    onCancel() {
    }
  });
}

/**
 * 报修人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selReportUserDeptUser=(val)=>{
  formSearchState.value.createUserId = val;
}
/**
 * 选择的项目回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selProject = (val)=>{
  formSearchState.value.projectIds = val;
}

const onSearch = ()=>{
  formSearchRef.value.validateFields().then(()=>{
    toHomePage();
  }).catch(()=>{
    //console.log("onSearch  info ---",info);
  });
}

/**
 * 显示资产详情(调用ViewMessage子组件)
 * @param id
 */
const showViewAsset = (id)=>{
  viewItemId.value = id;
  isShowViewItem.value = true;
}
/**
 * 查看资产窗口关闭回调
 */
const callback_viewAsset = ()=>{
  viewItemId.value = null;
  isShowViewItem.value = false;
  toCurrentPage();
}

/**
 * table 复选框操作
 * @type {ComputedRef<{onChange: function(*): void, selectedRowKeys: Array<UnwrapRefSimple<*>>}>}
 */
const rowSelection = computed(() => {
  return {
    selectedRowKeys: unref(selectedRowKeys),
    onChange: changableRowKeys => {
      selectedRowKeys.value = changableRowKeys;
    }
  };
});
/**
 * 导出到Excel
 */
const exportToExcelOfInspect = ()=>{
  const etew = message.loading('正在导出到Excel...', 0);
  let params = clone(formSearchState.value);  //克隆新的数据以免干扰formSearchState
  if (searchCreateTimeRange.value) {
    params.createTimeStart = searchCreateTimeRange.value[0].format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
    params.createTimeEnd = searchCreateTimeRange.value[1].format("YYYY-MM-DD HH:mm:ss");
  }
  api_inspectRecord.exportToExcelOfInspect(params).then(res=>{
    if (res?.data.size) {
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(res.data); // 创建下载的链接
      downloadElement.href = href;
      downloadElement.download = decodeURI("巡检记录统计.xls"); // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放掉blob对象
      setTimeout(etew,100); //隐藏提示
    }else {
      setTimeout(etew,100); //隐藏提示
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
