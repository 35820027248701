<template>
 <div class="loginDiv">
   <div style="color:#000000D9;font-size: 25px;font-weight: bold;margin-bottom: 25px;text-align: center;">平台申请</div>
   <a-form
       :model="formState"
       name="normal_login"
       class="login-form"
       @finish="onFinish"
   >
     <a-form-item name="verifyCodeId" no-style><a-input type="hidden" v-model:value="formState.verifyCodeId"/></a-form-item>

     <a-form-item
         name="orgName"
         :rules="[{ required: true, message: '请输入平台名称' },{pattern:/^[\u4e00-\u9fa5\w]{1,50}$/,message:'平台名称只能包含中文、英文、数字及下划线最大长度50',trigger:'blur'}]"
     >
       <a-input v-model:value="formState.orgName" size="large" placeholder="单位/企业/公司名称" ref="focusDom">
         <template #prefix>
           <BankOutlined class="site-form-item-icon" />
         </template>
       </a-input>
     </a-form-item>

     <a-form-item
         name="loginName"
         :rules="[{ required: true, message: '请输入手机号',trigger:'blur' },{pattern:/^(1[0-9]{10}|[root]{4})$/,message:'手机号不符合规则',trigger:'blur'}]"
     >
       <a-input v-model:value="formState.loginName" size="large"  placeholder="手机号">
         <template #prefix>
           <UserOutlined class="site-form-item-icon" />
         </template>
       </a-input>
     </a-form-item>

     <a-form-item
         name="validateCode"
         :rules="[{ required: true, message: '请输入验证码',trigger:'blur' },{pattern:/^[a-zA-Z0-9]{4}$/,message:'验证码不符合规则',trigger:'blur'}]"
     >
       <a-input v-model:value="formState.validateCode" size="large"  placeholder="验证码" addon-before="验证码" style="width: 60%" :maxlength="4"/>
       <span style="width: 40%;text-align: right;display:inline-block;">
         <a-button v-if="!verifyCodeIsLoadOk" @click="getVerifyCodeInfo" :loading="loadVerifyCode" style="margin-top: 4px;">
          <template #icon><reload-outlined /></template>
            {{loadVerifyCode ? '正在加载' : '点击重试'}}
         </a-button>
         <a-tooltip v-if="verifyCodeIsLoadOk" title="看不清？点击换一个">
           <img :src="verifyCodeBase64Img"  height="35" style="cursor:pointer;" @click="getVerifyCodeInfo" />
         </a-tooltip>
       </span>
     </a-form-item>

     <a-form-item>
       <a-button type="primary" html-type="submit" class="login-form-button" size="large" :loading="loadApply" block>申请</a-button>
     </a-form-item>

     <!--          <a-alert type="error" v-if="resErr!=''" :message="resErr" banner style="color: #ff4d4f" />-->
   </a-form>
   <div><a href="javascript:void(0)" @click="changeCmp()">账户登录</a></div>
 </div>
</template>

<script>
import {reactive, ref, onMounted, h} from 'vue';
import {UserOutlined,BankOutlined,ReloadOutlined} from "@ant-design/icons-vue";
import {message,Modal } from "ant-design-vue";
import api_tenant from "@/api/tenant";

export default {
  name: "TenantRegister",
  components: {
    UserOutlined,
    BankOutlined,
    ReloadOutlined
  },
  emits:['changeCmp'],
  setup(props,{emit}){
    const focusDom = ref(null);     //ant input 获取焦点
    const verifyCodeBase64Img = ref("");
    //const verifyCodeId = "";
    const verifyCodeIsLoadOk = ref(false);
    const loadApply = ref(false);
    const loadVerifyCode = ref(false);
    const formState = reactive({
      orgName: '',
      loginName: '',
      validateCode: '',
      verifyCodeId:''
    });

    /** 提交表单且数据验证成功后回调事件 **/
    const onFinish = values => {
      loadApply.value = true;
      api_tenant.applyTenant(values).then(res=>{
        if(res?.data?.code === 0){
          Modal.success({
            title: '系统提示',
            okText: '确认',
            content: h('div', {}, [h('p', '申请平台成功，请等待管理员审核。')]),
            onOk(){
              changeCmp();
            }
          });
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loadApply.value = false;
      });
    };
    /**
     * 获取验证码信息
     * return img: base64 图片
     *        id: 存储到redis中key
     */
    const getVerifyCodeInfo = ()=>{
      verifyCodeIsLoadOk.value = false;
      loadVerifyCode.value = true;
      api_tenant.getVerifyCode().then(res=>{
        if(res?.data?.code === 0){
          verifyCodeBase64Img.value = res.data.data.img;
          formState.verifyCodeId = res.data.data.id;
          verifyCodeIsLoadOk.value = true;
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loadVerifyCode.value = false;
      });
    }

    onMounted(()=>{
      focusDom.value.focus();
      getVerifyCodeInfo();
    })

    const changeCmp = ()=>{
      emit("changeCmp","_login"); //调用父组件方法@changeCmp="setChangeCmp"
    }

    return{
      formState,
      focusDom,
      onFinish,
      changeCmp,
      verifyCodeBase64Img,
      getVerifyCodeInfo,
      verifyCodeIsLoadOk,
      loadApply,
      loadVerifyCode
    }
  },
}
</script>

<style scoped>
  .loginDiv{
    width: 100%;
    min-width: 300px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 15px #d0cece;
    padding: 30px;
    z-index: 1;
    margin: auto;
  }
</style>
