<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div  class="block" style="padding-top: 0px;padding-bottom: 0px;" >
        <a-tabs v-model:activeKey="tab_activeKey" @change="changeTabPage">
          <a-tab-pane v-permission="'inspectRegion'" key="inspectRegion" tab="巡检区域"></a-tab-pane>
        </a-tabs>
      </div>

      <div class="block" style="" >
        <transition mode="out-in" leave-active-class="cmp-out" enter-active-class="cmp-in">
          <Component :is="cmpObj"/>
        </transition>
      </div>


    </div>
  </div>
</template>

<script setup>
import {defineAsyncComponent, ref, shallowRef} from "vue";
/** 异步加载组件 **/
const InspectRegion = defineAsyncComponent(() =>{
  return import('@/components/_inspectRegion.vue')
})


const tab_activeKey = ref("inspectRegion");
const cmpObj = shallowRef(InspectRegion);

const changeTabPage = ()=>{
  if (tab_activeKey.value === "inspectRegion"){
    cmpObj.value = InspectRegion;
  }

}

</script>

<style scoped>
.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
.cmp-in {
  animation: fadeIn;
  animation-duration: 0.2s; /* don't forget to set a duration! */
}
.cmp-out{
  animation: fadeOut;
  animation-duration: 0.2s;
}
</style>
