<template>
  <div ref="container" style="height:100%;position: relative;overflow: hidden">
    <div style="background-color: #fff;">
      <div class="tableHeaderContainer" >
        <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
          功能操作
          <!-- 搜索抽屉框(局部弹窗需要外层div Css position: relative;overflow: hidden ) start-->
          <a-drawer
              title="条件筛选"
              :mask="true"
              placement="top"
              v-model:visible="searchDrawerVisible"
              :get-container="()=>{return $refs.container}"
              :style="{ position: 'absolute' }"
              :height="200"
          >
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item label="名称" name="name">
                <a-input v-model:value="formSearchState.name" size="default" />
              </a-form-item>
            </a-form>
            <template #footer>
              <div style="text-align: right">
                <a-button style="margin-right: 8px" @click="()=>{searchDrawerVisible = false;}"><template #icon><CloseOutlined /></template>关闭</a-button>
                <a-button type="primary" @click="onSearch()"><template #icon><SearchOutlined /></template>搜索</a-button>
              </div>
            </template>
          </a-drawer>
          <!-- 搜索抽屉框 end -->
        </div>
        <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
          <a-button style="margin-left: 5px;" type="primary" size="small" @click="()=>{searchDrawerVisible = true;}"><template #icon><SearchOutlined /></template>条件筛选</a-button>
          <a-button v-permission="'auth_addRole'" style="margin-left: 5px;" type="primary" size="small" @click="showAddItemDialog()"><template #icon><PlusOutlined /></template>添加角色</a-button>
        </div>
      </div>
      <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - tableHeaderContainer 40 - 表头高度40 - 分页高度52 -->
      <a-table
          class="ant-table-striped"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :bordered="true"
          size="small"
          :loading="loading"
          :scroll="{ y: 'calc(100vh - 227px)' }"
      >
        <template #bodyCell="{ column,record }">
          <template v-if="column.dataIndex === 'operation'">
            <div style="display: flex;justify-content: left">
              <a-button v-permission="'auth_updateRole'" style="margin: 0px 4px;" type="link" size="small" @click="showEditItemDialog(record)"><template #icon><EditOutlined /></template><span style="margin-left:3px;">编辑</span></a-button>
              <a-button v-permission="'auth_deleteRole'" style="margin: 0px 4px;" type="link" size="small" danger @click="deleteItem(record)"><template #icon><DeleteOutlined /></template><span style="margin-left:3px;">删除</span></a-button>
              <a-button v-permission="'auth_allotRoleOperation'" style="margin: 0px 4px;" type="link" size="small" @click="showOperationDialog(record)"><template #icon><ProfileOutlined /></template><span style="margin-left:3px;">分配权限</span></a-button>
            </div>
          </template>
        </template>
      </a-table>

      <div style="padding:10px;text-align: center">
        <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                      @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                      :page-size-options="['10','20','30','40','50']" show-size-changer>
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>



      <!--    编辑用户弹窗     -->
      <a-modal
          v-model:visible="editItem_dialog_visible"
          title="编辑角色"
          ok-text="确认"
          cancel-text="取消"
          @ok="onUpdateItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formEditRef" :model="formEditState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" name="edit">
            <a-form-item
                label="名称"
                name="name"
                :rules="[{ required: true, message: '请输入角色名称',trigger:'blur' }]"
            >
              <a-input v-model:value="formEditState.name" size="default" />
            </a-form-item>
            <a-form-item
                label="描述"
                name="description"
                :rules="[{ max:100, message: '角色描述长度不能超过100个字符',trigger:'blur' }]"
            >
              <a-textarea v-model:value="formEditState.description" size="default" show-count :maxlength="100" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    添加角色弹窗     -->
      <a-modal
          v-model:visible="addItem_dialog_visible"
          title="添加角色"
          ok-text="确认"
          cancel-text="取消"
          @ok="onSaveItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formAddRef" :model="formAddState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" name="add">
            <a-form-item
                label="名称"
                name="name"
                :rules="[{ required: true, message: '请输入角色名称',trigger:'blur' }]"
            >
              <a-input v-model:value="formAddState.name" size="default" />
            </a-form-item>
            <a-form-item
                label="描述"
                name="description"
                :rules="[{ max:100, message: '角色描述长度不能超过100个字符',trigger:'blur' }]"
            >
              <a-textarea v-model:value="formAddState.description" size="default" show-count :maxlength="100" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    添加菜单权限弹窗     -->
      <a-modal
          v-model:visible="addOperation_dialog_visible"
          ok-text="确认"
          cancel-text="取消"
          @ok="onCheckedItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <template #title>
          <div>分配权限<a-tooltip color="orange" title="注意：变更操作权限选择将会影响关联用户的操作权限功能"><ExclamationCircleOutlined style="color: orange;margin-left: 5px"/></a-tooltip></div>
          <div v-if="!isShowNullDataMsg" style="color: #b6b6b6;font-size: 12px;margin-top: 5px;">注意：变更操作权限选择将会影响关联用户的操作权限功能</div>
        </template>

        <a-spin :spinning="loading">
          <a-alert v-if="isShowNullDataMsg" message="没有可选的权限操作。" banner />
          <a-tree
              v-model:expandedKeys="expandedKeys"
              v-model:selectedKeys="selectedKeys"
              v-model:checkedKeys="checkedKeys"
              checkable
              :tree-data="roleOperationTreeData"
              :height="500"
              :auto-expand-parent="true"
              @check="onCheck"
          >
          </a-tree>
        </a-spin>
      </a-modal>

    </div>
  </div>
</template>

<script>
import {onMounted, ref, createVNode, reactive} from "vue";
import api_auth from "@/api/auth";
//import utils from "@/framework/utils/utils";
import {useDirectStore} from "@/store/directStore";
import {message,Modal} from "ant-design-vue";
import {router} from "@/router";
import {ExclamationCircleOutlined,SearchOutlined,PlusOutlined,EditOutlined,DeleteOutlined,CloseOutlined,ProfileOutlined} from "@ant-design/icons-vue";

export default {
  components: {
    ExclamationCircleOutlined,
    SearchOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
    ProfileOutlined,
  },
  setup(){
    const loading = ref(false);
    const formAddRef = ref();
    const formEditRef = ref();
    const formSearchRef = ref();
    const searchDrawerVisible = ref(false);
    const editItem_dialog_visible = ref(false);
    const addItem_dialog_visible = ref(false);
    const addOperation_dialog_visible = ref(false);
    const expandedKeys = ref([]);
    const selectedKeys = ref([]);
    const checkedKeys = ref([]);
    let newCheckedKeys = [];   //带有父节点id keys
    let roleOperationTreeData = ref([]);
    const isShowNullDataMsg = ref(false);
    let curSelRoleId = "";
    const directStore = useDirectStore();

    const formAddState = reactive({
      name: '',
      description: ''
    });
    const formEditState = reactive({
      id: '',
      name: '',
      description: ''
    });
    const formSearchState = reactive({
      name: ""
    });
    let dataSource = ref([]);
    let columns= [
      {
        title: '序号',
        dataIndex: 'index',
        width:100,
        customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
      },{
        title: '名称',
        dataIndex: 'name',
      },{
        title: '描述',
        dataIndex: 'description',
      },{
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width:300
      }
    ];

    let pagination = ref({
      current: 1,     //当前页
      pageSize: 10,  //每页条数
      total: 1,     //数据总数
      pageSizeOptions:['2','4','6'],
      showSizeChanger:true
    });
    /**
     * 清空searchData 数据
     */
    const clearSearchData = ()=>{
      formSearchState.name = "";
    }
    /**
     * 查询所有role
     * @param page 页数
     */
    const findAllItem = function (page){
      loading.value = true;
      formSearchState.page = page;
      formSearchState.rows = pagination.value.pageSize;
      //console.log("findAllItem searchData----",formSearchState);
      api_auth.findAllRole(formSearchState).then(res=>{
        //console.log("findAllRole res----",res);
        if(res?.data?.code === 0){
          dataSource.value = [];
          const list = res.data.data.list;
          const _total = res.data.data.total;
          pagination.value.total = _total;

          list.forEach((role)=>{
            //console.log("user-->",user);
            dataSource.value.push({id:role.id,name:role.name,description:role.description});
          })
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }
    /**
     * 分页响应事件
     * @param page {@link pagination}
     */
    let onPageChange = function (page){
      findAllItem(page);
    }
    /**
     * 改变pageSize分页条数
     * @param current 当前页
     * @param size  分页条数
     */
    const onShowSizeChanger = function (current, size){
      pagination.value.current = current;
      pagination.value.pageSize = size;
    }
    /**
     * 跳到第一页
     */
    const toHomePage = ()=>{
      findAllItem(1);
      pagination.value.current = 1;
    }
    /**
     * 跳到最后页
     */
    const toEndPage = ()=>{
      findAllItem(getTotalPage());
      pagination.value.current = getTotalPage();
    }
    /**
     * 刷新当前页
     */
    const toCurrentPage = ()=>{
      findAllItem(pagination.value.current);
    }

    /**
     * 获取当前总页数
     */
    const getTotalPage =()=>{
      if (pagination.value.total === 0){
        return 1;
      }
      return pagination.value.total%pagination.value.pageSize == 0
          ? pagination.value.total/pagination.value.pageSize
          : parseInt(pagination.value.total/pagination.value.pageSize)+1;
    }

    onMounted(()=>{
      findAllItem(1);

    })

    /**
     * 显示修改记录弹窗
     * @param record 实体数据
     */
    const showEditItemDialog = record =>{
      editItem_dialog_visible.value = true;
      loading.value = true;
      api_auth.getRole(record.id).then(res=>{
        if(res?.data?.code === 0){
          formEditState.id = res.data.data.id;
          formEditState.name = res.data.data.name;
          formEditState.description = res.data.data.description;
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });
    }
    /**
     * 点击修改记录弹窗->确认按钮
     */
    const onUpdateItemOK = function (){
      formEditRef.value.validateFields().then(values=>{
        loading.value = true;
        values.id = formEditState.id;
        //console.log("onUpdateItemOK  values ---",values);
        api_auth.updateRole(values).then(res=>{
          if(res?.data?.code === 0){
            message.success("操作成功",3);
            editItem_dialog_visible.value = false;
            toCurrentPage();
          }else{
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }
          }
          loading.value = false;
        })
      }).catch(()=>{
        //console.log("onUpdateItemOK  info ---",info);
      });
    }
    /**
     * 显示添加用户弹窗
     */
    const showAddItemDialog = () =>{
      addItem_dialog_visible.value = true;
    }
    /**
     * 添加用户弹窗->确认
     */
    const onSaveItemOK = () =>{
      formAddRef.value.validateFields().then(values=>{
        loading.value = true;
        //console.log("onSaveItemOK  values ---",values);
        api_auth.saveRole(values).then(res=>{
          if(res?.data?.code === 0){
            message.success("操作成功",3);
            addItem_dialog_visible.value = false;
            clearSearchData();  //清空searchData,避免当前列表处于搜索后的数据显示，添加新的数据后不显示
            toEndPage();
          }else{
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }
          }
          loading.value = false;
        })
      }).catch(()=>{
        //console.log("onSaveItemOK  info ---",info);
      });
    }
    /**
     * 删除一条记录
     * @param record 实体数据
     */
    const deleteItem = function (record){
      let modal_title = ref("系统提示");
      //console.log(record);
      Modal.confirm({
        title: ()=>modal_title.value,
        icon: createVNode(ExclamationCircleOutlined),
        content: '是否要删除此条记录?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
            api_auth.deleteRole(record.id).then(res=>{
              if(res?.data?.code === 0){
                message.success("操作成功",3);
                resolve();
                toCurrentPage();
              }else{
                let desc="";
                let data;
                const setDataList = [];
                if (res?.data?.code === 70003){
                  data = res?.data?.data;
                  if (data && data.length > 0){
                    desc = "，有"+data.length+"个用户分配此角色";
                    data.forEach((ru)=>{
                      setDataList.push(ru.userId);
                    });
                    Modal.confirm({
                      title: '系统提示！',
                      content: res?.data?.msg+" "+desc,
                      okText: '去指引',
                      cancelText: '取消',
                      onOk() {
                        directStore.setDataList(setDataList);
                        router.push("/system/user");
                      },
                      onCancel() {}
                    });
                  }
                }
                //utils.showErrorModal(res?.data?.msg+" "+res?.data?.data);
                reject();
              }
            });
          }).catch(() => {});
        },
        onCancel() {
        }
      });
    }
    /**
     * 搜索
     */
    const onSearch = ()=>{
      formSearchRef.value.validateFields().then(()=>{
        searchDrawerVisible.value = false;
        //console.log("onSearch  values ---",values);

        toHomePage();
      }).catch(()=>{
        //console.log("onSearch  info ---",info);
      });
    }
    /**
     * 显示菜单权限操作弹窗
     * @param record
     */
    const showOperationDialog = record=>{
      loading.value = true;
      //console.log(record);
      curSelRoleId = record.id;
      addOperation_dialog_visible.value = true;
      isShowNullDataMsg.value = false;
      expandedKeys.value = [];
      checkedKeys.value = [];
      selectedKeys.value = [];
      newCheckedKeys = [];
      roleOperationTreeData.value = [];

      api_auth.showAllotOperation(record.id).then(res=>{
        if(res?.data?.code === 0){
          //console.log(res);
          const dataList = res.data.data;
          if (dataList.length > 0){
            const nodes = convert(res.data.data);
            roleOperationTreeData.value = nodes;
            /** 选择的角色checked的节点同时将id保存到newCheckedKeys中（包含了父节点id） **/
            dataList.forEach((op)=>{
              if (op.checked === true){
                newCheckedKeys.push(op.id);
              }
            })
            setCheckedKey(nodes);
          }else {
            isShowNullDataMsg.value = true;
          }
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }
    /**
     * 处理选择操作权限的操作
     */
    const onCheckedItemOK = ()=>{
      loading.value = true;
      let data = {roleId:curSelRoleId,operationIds:newCheckedKeys};
      api_auth.doAllotRoleOperation(data).then(res=>{
        if(res?.data?.code === 0){
          message.success("操作成功",3);
          addOperation_dialog_visible.value = false;
          //console.log(res);
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      })
    }
    /**
     * 操作权限check事件
     * <br/>
     * @param ck 选择的checkedKey
     * @param e 对象，其中e.halfCheckedKeys可以获取到没有选择的父节点key
     */
    const onCheck = (ck,e)=>{
      newCheckedKeys = checkedKeys.value.concat(e.halfCheckedKeys);
    }
    /**
     * 转换成树型控件所需的data格式
     * @param rows 对象数组
     * @returns {*[]}
     */
    const convert = function(rows){
      function exists(rows, parentId){
        for(let i=0; i<rows.length; i++){
          if (rows[i].id == parentId) return true;
        }
        return false;
      }

      let nodes = [];
      // get the top level nodes
      for(let i=0; i<rows.length; i++){
        let row1 = rows[i];
        if (!exists(rows, row1.parentId)){
          nodes.push({
            key:row1.id,
            title:row1.name
          });

        }
      }

      let toDo = [];
      for(let i=0; i<nodes.length; i++){
        toDo.push(nodes[i]);
      }

      while(toDo.length){
        let node = toDo.shift();    // the parent node
        // get the children nodes
        for(let i=0; i<rows.length; i++){
          let row = rows[i];
          if (row.parentId == node.key){
            let child = {key:row.id,title:row.name,checked:row.checked};
            if (node.children){
              node.children.push(child);
            } else {
              node.children = [child];
            }
            toDo.push(child);
          }
        }
      }
      return nodes;
    }
    /**
     * 设置roleOperationTreeData树数据选中
     * <br/> 遍历找出没有父节点的checked的实体数据将此选中，不能选中父节点否则其下面所有节点都会选中
     * @param nodes nodes
     */
    const setCheckedKey = function (nodes){
      nodes.forEach((node)=>{
        if (node.children){
          setCheckedKey(node.children);
        }else{
          if (node.checked === true){
            /** 选择的角色如果已经有了operation操作则check此节点 **/
              checkedKeys.value.push(node.key);
              /** 展开所有节点 **/
              expandedKeys.value.push(node.key);
          }
        }
      })
    }

    return{
      columns,
      dataSource,
      pagination,
      onPageChange,
      onShowSizeChanger,
      formAddRef,
      formEditRef,
      formSearchRef,
      formAddState,
      formEditState,
      formSearchState,
      searchDrawerVisible,
      editItem_dialog_visible,
      addItem_dialog_visible,
      addOperation_dialog_visible,
      showEditItemDialog,
      showAddItemDialog,
      onUpdateItemOK,
      onSaveItemOK,
      deleteItem,
      onSearch,
      toEndPage,
      showOperationDialog,
      roleOperationTreeData,
      expandedKeys,
      selectedKeys,
      checkedKeys,
      onCheckedItemOK,
      onCheck,
      isShowNullDataMsg,
      loading
    }
  }

}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.tableHeaderContainer{
  display: flex;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #fafafa;
  background-color: #ffffff;
}

</style>
