import {axios} from "@/framework/utils/request";
import qs from "qs";    //npm install qs

const api_project = {
    findAllProject:function (data){
        return axios({
            url:"/project/findAllProject",
            method:"post",
            data:qs.stringify(data)
        })
    },
    getActiveProject:function (){
        return axios({
            url:"/project/getActiveProject",
            method:"get",
            params:{
            }
        })
    },
    addProject:function (data){
        return axios({
            url:"/project/addProject",
            method:"post",
            data:qs.stringify(data)
        })
    },
    updateProject:function (data){
        return axios({
            url:"/project/updateProject",
            method:"post",
            data:qs.stringify(data)
        })
    },
    getProject:function (id){
        return axios({
            url:"/project/getProject",
            method:"get",
            params:{
                id:id
            }
        });
    },
    deleteProject:function (id){
        return axios({
            url:"/project/deleteProject",
            method:"post",
            params:{
                id:id
            }
        });
    },
    active:function (id){
        return axios({
            url:"/project/active",
            method:"post",
            params:{
                id:id
            }
        });
    },


}

export default api_project;
