import {axios} from "@/framework/utils/request";
import qs from "qs";

const api_assetBase = {
    showAllAssetType:function (data){
        return axios({
            url:"/assetBase/showAllAssetType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    addAssetType:(data)=>{
        return axios({
        url:"/assetBase/addAssetType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    viewAssetType:(id)=>{
        return axios({
            url:"/assetBase/viewAssetType",
            method:"get",
            params:{
                id:id
            }
        })
    },
    editAssetType:function (data){
        return axios({
            url:"/assetBase/editAssetType",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteAssetType:function (data){
        return axios({
            url:"/assetBase/deleteAssetType",
            method:"post",
            data:qs.stringify(data, {indices: false})   //indices: false 传递数组
        })
    },

    showAllAssetBrand:function (data){
        return axios({
            url:"/assetBase/showAllAssetBrand",
            method:"post",
            data:qs.stringify(data)
        })
    },
    addAssetBrand:(data)=>{
        return axios({
            url:"/assetBase/addAssetBrand",
            method:"post",
            data:qs.stringify(data)
        })
    },
    viewAssetBrand:(id)=>{
        return axios({
            url:"/assetBase/viewAssetBrand",
            method:"get",
            params:{
                id:id
            }
        })
    },
    editAssetBrand:function (data){
        return axios({
            url:"/assetBase/editAssetBrand",
            method:"post",
            data:qs.stringify(data)
        })
    },
    deleteAssetBrand:function (data){
        return axios({
            url:"/assetBase/deleteAssetBrand",
            method:"post",
            data:qs.stringify(data, {indices: false})   //indices: false 传递数组
        })
    },
}
export default api_assetBase;
