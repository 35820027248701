import {axios} from "@/framework/utils/request";

const api_sse = {
    closeSSE:(type,id)=>{
        return axios({
            url:"/sse/closeSSE",
            method:"get",
            params:{
                type:type,
                id:id
            }
        })
    },
    heart:(type,id)=>{
        return axios({
            url:"/sse/heart",
            method:"get",
            params:{
                type:type,
                id:id
            }
        })
    },


}
export default api_sse;
