/**
 * 用户相关的存储信息
 */

import {defineStore} from "pinia";

export const useUserStore = defineStore("userStore",{
    state:()=>{
        return{
            id:"",      //用户id
            name:"",    //用户名称
            phone:"",   //用户phone
            tenantId:"",//所属租户ID
            type:'',    //用户类型，0:root，1：租户管理员，2：租户普通用户,3：访客
            isReceiver:'',//是否是工单接收人， 0：否，1：是
            isAssign:'',    //是否拥有工单指派功能，0：否，1：是
            lastLoginTime: '',  //上一次登录时间
            dept:null,
            token:"",   //token
            roleList:[], //用户角色列表包括其权限资源
            isPC:true,   //是否是pc登录的
        }
    },
    getters:{

    },
    actions:{
        saveUser(data){
            return new Promise((resolve,reject)=>{
                try {
                    if(!data || !data.user){
                        reject(new Error("[UserStore] saveUser - 获取不到用户信息-res.data.user"));
                    }else if(!data.user.roleList){
                        reject(new Error("[UserStore] saveUser - 获取不到用户角色信息-res.data.user.roleList"));
                    }else{
                        this.id = data.user.id;
                        this.name = data.user.name;
                        this.phone = data.user.phone;
                        this.tenantId = data.user.tenantId;
                        this.type = data.user.type;
                        this.isReceiver = data.user.isReceiver;
                        this.isAssign = data.user.isAssign;
                        this.lastLoginTime = data.user.lastLoginTime;
                        this.dept = data.user.dept;
                        this.token = data.token;
                        this.roleList = data.user.roleList;
                        this.isPC = true;
                        resolve();
                    }
                }catch (err){
                    reject(new Error("[UserStore] saveUser - error"));
                }
            });
        },
        editUserBaseInfo(user){
            return new Promise((resolve,reject)=>{
                try {
                    if(!user){
                        reject(new Error("[UserStore] editUserBaseInfo - 获取不到用户信息-user"));
                    } else{
                        this.name = user.name;
                        this.phone = user.phone;
                        this.tenantId = user.tenantId;
                        this.type = user.type;
                        this.dept = user.dept;
                        resolve();
                    }
                }catch (err){
                    reject(new Error("[UserStore] editUserBaseInfo - error"));
                }
            });
        },
        setToken(token){
            return new Promise((resolve,reject)=>{
                if(!token){
                    reject(new Error("[UserStore] setToken - token is null"));
                }else{
                    this.token = token;
                    this.isPC = false;
                    resolve();
                }
            });
        },
        logout(){
            return new Promise((resolve,reject)=>{
                try {
                    this.id = "";
                    this.name = "";
                    this.phone = "";
                    this.tenantId = "";
                    this.type = "";
                    this.isReceiver = "";
                    this.isAssign = "";
                    this.lastLoginTime = "";
                    this.dept = null;
                    this.token = "";
                    this.roleList = [];
                    resolve();
                }catch (err){
                    reject(new Error("[UserStore] logout - error"))
                }
            });
        }
    },
    persist:{
        enabled:true,   //开启持久化
            /**
             * strategies: 指定存储位置以及存储的变量都有哪些,该属性可以不写，不写的话默认是存储到sessionStorage里边，默认存储state里边的所有数据
             * strategies->storage:Storage : storage存储到哪里,可选sessionStorage/localStorage
             * strategies->paths:String[] :  paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
             **/
            strategies:[{
            storage: sessionStorage,    // storage 存储到哪里 sessionStorage/localStorage
            //paths:['count']     // paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
        }]
    }
});
